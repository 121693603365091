export const state = () => {
    return {
        isRisky: false,
        isLocal: false,
    };
};

export const mutations = {
    setIsRisky(state, value) {
        state.isRisky = value.isRisky;
        state.isLocal = value.isKorsit;
    },
};

export const actions = {
    init({ commit }, value) {
        commit('setIsRisky', value);
    },
};

export const getters = {
    isRisky(state) {
        return state.isRisky;
    },
    isLocal(state) {
        return state.isLocal;
    },
};
