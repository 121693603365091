function callBreadcrumb(pageOptions, context, data) {
    if ('breadcrumb' in pageOptions) {
        context.store.dispatch('breadcrumb/setItems', pageOptions.breadcrumb.call(undefined, context, data))
    } else {
        context.store.dispatch('breadcrumb/setItems', [])
    }
}

export default context => {
    if (process.server) {
        // console.log(context)
        const { beforeNuxtRender } = context

        beforeNuxtRender(({ Components, nuxtState }) => {
            // Do not apply this on any error:
            if (nuxtState.error) {
                return
            }

            const pageOptions = Components[0].options

            callBreadcrumb(pageOptions, context, nuxtState.data[0])
        })
    } else {
        // Clear items:
        // context.store.dispatch('breadcrumb/setItems', []);
        // const pageOptions = context.route.matched[0].components.default.options;
        // callBreadcrumb(pageOptions, context, context.nuxtState.data[0])
        // console.log(context)
    }
}
