import { getJSON } from "../utils/json";

export default ({ cdnURL }) => {
    return {
        async getAll() {
            return (await getJSON(`${cdnURL}/currency.json`));
        },

    };
};
