/**
 * These products are 'sub-products' of Neosurf
 * to which special rules apply.
 *
 * IMPORTANT: These are not the actual brand slugs,
 * but rather the slugs used in the product slug.
 * The productIds have reversed slugs to avoid
 * the neosurf brand from regex-matching to these products.
 */
// prettier-ignore
export const NEOSURFX_PRODUCTS = [
    'bayton-neosurf',
    'betsafe-neosurf',
    'betsson-neosurf',
    'betway-neosurf',
    'casinoeuro-neosurf',
    'casumo-neosurf',
    'clearpay-neosurf',
    'clearpay2-neosurf',
    'comeongroup-neosurf',
    'coolbet-neosurf',
    'digimedia-neosurf',
    'esport-entertainment-group-neosurf',
    'guts-neosurf',
    'kaboo-neosurf',
    'kindred-neosurf',
    'leovegas-neosurf',
    'mrGreen-neosurf',
    'nordicbet-neosurf',
    'norgesautomaten-neosurf',
    'pokerstars-neosurf',
    'rizk-neosurf',
    'together-gaming-neosurf',
    'videoslots-neosurf',
];

/**
 * These are the actual brand slugs for the sub-brands of Neosurf
 */
// prettier-ignore
export const NEOSURFX_BRANDS = [
    'neosurf-bayton',
    'neosurf-betsafe',
    'neosurf-betsson',
    'neosurf-betway',
    'neosurf-casinoeuro',
    'neosurf-casumo',
    'neosurf-clearpay',
    'neosurf-clearpay2',
    'neosurf-comeon-group',
    'neosurf-coolbet',
    'neosurf-digimedia',
    'neosurf-esport-entertainment-group',
    'neosurf-guts',
    'neosurf-kaboo',
    'neosurf-kindred',
    'neosurf-leo-vegas',
    'neosurf-mr-green',
    'neosurf-nordicbet',
    'neosurf-norgesautomaten',
    'neosurf-pokerstars',
    'neosurf-rizk',
    'neosurf-together-gaming',
    'neosurf-videoslots',
];

/**
 * How many gift cards of a NeosurfX brand
 * can a user buy at once?
 */
export const NEOSURFX_MAX_QUANTITY = 6;

export const BUNDLE_SKUS = [
    {
        productId: 'steam-code-100-euro-bundle',
        bundleSize: 5,
        faceValuePerItem: 20,
    },
    {
        productId: 'steam-code-80-euro-bundle',
        bundleSize: 4,
        faceValuePerItem: 20,
    },
    {
        productId: 'steam-code-60-euro-bundle',
        bundleSize: 3,
        faceValuePerItem: 20,
    },
    {
        productId: 'steam-code-40-euro-bundle',
        bundleSize: 2,
        faceValuePerItem: 20,
    },
];
