import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';
import { DEFAULT_AZURE_BASE_URL } from '../config/constants';

export default definePlugin(({ app, store }, inject) => {
    /**
     * This is a workaround for @nuxtjs/auth, since we cannot dynamically
     * load the configuration from process.env, we'll need to patch the default/production
     * URLs at runtime.
     */
    if (app.$config.azure.baseUrl !== DEFAULT_AZURE_BASE_URL) {
        patchAuthEndpoints(app);
    }

    const user = new Vue({
        data() {
            return {
                onLoadedlisteners: [],
                isLoaded: app.$auth.loggedIn,
            };
        },

        watch: {
            isLoaded() {
                this.onLoadedlisteners.forEach(listener => listener());
            },
        },

        methods: {
            requestLoadedCallback(func) {
                this.onLoadedlisteners.push(func);
            },

            async login(strategy, email, otp = null) {
                const user = await app.$auth.loginWith(strategy, {
                    data: {
                        email,
                        currency: app.$locale.currency,
                        language: app.$locale.language,
                        domain: app.$locale.domain,
                        region: app.$locale.region,
                        otp,
                    },
                });
                await new Promise(resolve => setTimeout(resolve, 200));

                app.$auth.setUser(user.data.user);

                return user;
            },

            async logout() {
                await app.$auth.logout();
                store.state.auth.strategy = 'guest';
                localStorage.setItem('ig_strategy', 'guest');
                await app.$auth.fetchUser();
                app.router.push(app.$locale.route('index'));
            },

            async requestVerificationEmail(email) {
                await app.$api.auth.requestVerificationEmail({
                    email,
                    currency: app.$locale.currency,
                    language: app.$locale.language,
                    domain: app.$locale.domain,
                    region: app.$locale.region,
                });
            },
        },
    });

    inject('user', user);

    if (process.client) {
        // Watch log in:
        app.$auth.$storage.watchState('loggedIn', loggedIn => {
            if (loggedIn) {
                user.isLoaded = true;
                // const user = app.$auth.user;

                // app.$livechat.setUser(user);
            }
        });
    }
});

/**
 * This method patches the @nuxt/auth Azure API URLs
 * with the URL configured in app.$config.azure.baseUrl
 */
const patchAuthEndpoints = app => {
    // Wrap the whole thing in a try case so we don't accidentally break authenticaiton.
    try {
        const endpoints = Object.keys(app.$auth.strategies.guest.options.endpoints);

        for (const key of endpoints) {
            const newUrl = app.$auth.strategies.guest.options.endpoints[key].url.replace(
                DEFAULT_AZURE_BASE_URL,
                app.$config.azure.baseUrl
            );
            app.$auth.strategies.guest.options.endpoints[key].url = newUrl;
        }
    } catch (error) {
        console.error('Something went wrong trying to patch the auth URLs', error);
    }
};
