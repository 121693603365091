import axios from 'axios';
import { getJSON, postJSON } from '../utils/json';

export default ({ app, baseURL, providers }) => {
    return {
        async getById(orderId) {
            return await getJSON(`${baseURL}/user/order/${orderId}?language=${providers.language()}`, {
                Authorization: providers.userToken(),
            });
        },
        async getByTransactionId(transactionId) {
            return await getJSON(
                `${baseURL}/user/order/byTransactionId/${transactionId}?language=${providers.language()}`,
                {
                    Authorization: providers.userToken(),
                }
            );
        },

        async checkout(data) {
            const checkoutDTO = {
                domain: providers.domain(),
                cid: providers.getClientId(),
                language: providers.language(),
                region: providers.region().toUpperCase(),
                ipCountry: providers.userRegion(),
                currency: providers.userCurrency(),
                session: {
                    id: providers.sessionId(),
                    utm: providers.sessionUTM(),
                },
                ...data,
            };

            return await postJSON(`${baseURL}/user/order/checkout`, checkoutDTO, {
                Authorization: providers.userToken(),
                'Api-Version': 2,
            });
        },

        async history(token, continuationToken) {
            const historyDTO = {
                token,
                resultsPerPage: 10,
            };
            if (continuationToken) historyDTO.continuationToken = continuationToken;

            return await postJSON(`${baseURL}/user/order/history`, historyDTO, {
                Authorization: providers.userToken(),
            });
        },

        async orderHistoryMail(recaptchaToken) {
            return await axios.get(
                `${baseURL}/user/email/order-history?region=${providers.region()}&language=${providers.language()}`,
                {
                    headers: {
                        Authorization: providers.userToken(),
                        'ReCAPTCHA-Token': recaptchaToken,
                    },
                }
            );
        },
    };
};
