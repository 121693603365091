export const state = () => ({
    scroll: {
        active: true,
        paddingRight: 0,
    },

    fullScreenLoading: false,

    overlay: {
        active: false,
        menu: false,
        element: null,
    },

    menu: {
        active: false,
        panning: false,
        pan: 0,
        name: null,
    },

    search: {
        active: false,
    },

    popup: {
        name: null,
        position: null,
    },

    modal: [],

    submenu: {
        active: false,
        title: '',
    },

    redirectionHeader: {
        active: false,
        prevent: false,
    },
});

// fix for scrollLock on iPhone
export const mutations = {
    lockScroll(state) {
        state.scroll.paddingRight = window.innerWidth - document.body.clientWidth;
        state.scroll.active = false;
    },

    unlockScroll(state) {
        state.scroll.active = true;
        state.scroll.paddingRight = 0;
    },

    showFullscreenLoading(state) {
        state.fullScreenLoading = true;
    },

    hideFullscreenLoading(state) {
        state.fullScreenLoading = false;
    },

    showOverlay(state, element) {
        state.overlay.active = true;
        state.overlay.element = element;
    },

    showMenuOverlay(state) {
        state.overlay.menu = true;
        state.overlay.active = true;
    },

    hideOverlay(state) {
        state.overlay.active = false;
        state.overlay.menu = false;
    },

    showSearch(state) {
        state.search.active = true;
    },

    hideSearch(state) {
        state.search.active = false;
    },

    showMenu(state) {
        state.menu.active = true;
    },

    hideMenu(state) {
        state.menu.active = false;
    },

    setMenuName(state, menuName) {
        state.menu.name = menuName;
    },

    setMenuPan(state, pan) {
        state.menu.pan = pan;
    },

    startMenuPanning(state) {
        state.menu.panning = true;
    },

    stopMenuPanning(state) {
        state.menu.panning = false;
    },

    showPopup(state, popup) {
        state.popup.name = popup.name;
        state.popup.position = popup.position;
    },

    hidePopup(state, popup) {
        state.popup.name = '';
    },

    showModal(state, modal) {
        modal.name === 'settings' ? state.modal.unshift(modal) : state.modal.push(modal);
    },

    hideModal(state) {
        state.modal.shift();
    },

    preventRedirection(state) {
        state.redirectionHeader.prevent = true;
    },

    showRedirectionHeader(state) {
        state.redirectionHeader.active = true;
    },

    hideRedirectionHeader(state) {
        state.redirectionHeader.active = false;
    },

    showSubmenu(state) {
        state.submenu = true;
    },

    hideSubmenu(state) {
        state.submenu = false;
    },
};

export const actions = {
    lockScroll({ commit }) {
        commit('lockScroll');
    },

    unlockScroll({ commit }) {
        commit('unlockScroll');
    },

    showFullscreenLoading({ commit }) {
        commit('showFullscreenLoading');
    },

    hideFullscreenLoading({ commit }) {
        commit('hideFullscreenLoading');
    },

    showOverlay({ commit }, element) {
        commit('showOverlay', element);
    },

    showMenuOverlay({ commit }, element) {
        commit('showMenuOverlay', element);
    },

    hideOverlay({ commit }) {
        commit('hideOverlay');
    },

    showMenu({ commit }) {
        commit('showMenu');
        commit('lockScroll');
    },

    hideMenu({ commit }) {
        commit('hideMenu');
        commit('unlockScroll');
    },

    toggleMenu({ commit }) {
        commit('toggleMenu');
    },

    setMenuName({ commit }, name = null) {
        commit('setMenuName', name);
    },

    setMenuPan({ commit }, pan) {
        commit('setMenuPan', pan);
    },

    startMenuPanning({ commit }) {
        commit('startMenuPanning');
    },

    stopMenuPanning({ commit }) {
        commit('stopMenuPanning');
    },

    showSearch({ commit }, pan) {
        commit('showSearch', pan);
    },

    hideSearch({ commit }, pan) {
        commit('hideSearch', pan);
    },

    showPopup({ commit }, popup) {
        commit('showPopup', popup);
    },

    hidePopup({ commit }, popup) {
        commit('hidePopup', popup);
    },

    showModal({ commit, dispatch }, modalName) {
        const modal =
            typeof modalName === 'string'
                ? { name: modalName, canClickAway: true, isLargeModal: false, showMenu: false }
                : modalName;

        modal.canClickAway = modal.canClickAway !== undefined ? modal.canClickAway : true;
        modal.isLargeModal = modal.isLargeModal !== undefined ? modal.isLargeModal : false;
        modal.transition = modal.transition !== undefined ? modal.transition : 'zoom';
        modal.showMenu = modal.showMenu !== undefined ? modal.showMenu : false;
        modal.data = modal.data !== undefined ? modal.data : null;
        modal.product = modal.product !== undefined ? modal.product : null;

        commit('showModal', modal);
        dispatch('showMenuOverlay');
        commit('lockScroll');
    },

    hideModal({ commit, dispatch, state }) {
        commit('hideModal');
        if (state.modal.length === 0) {
            dispatch('hideOverlay');
            commit('unlockScroll');
        }
    },

    preventRedirection({ commit }) {
        commit('preventRedirection');
    },

    showRedirectionHeader({ commit }) {
        commit('showRedirectionHeader');
    },

    hideRedirectionHeader({ commit }) {
        commit('hideRedirectionHeader');
    },

    showSubmenu({ commit, dispatch }) {
        commit('showSubmenu');
        dispatch('showOverlay');
    },

    hideSubmenu({ commit, dispatch }) {
        commit('hideSubmenu');
        dispatch('hideOverlay');
    },
};

export const getters = {
    scrollIsActive(state) {
        return state.scroll.active;
    },

    fullScreenLoadingIsActive(state) {
        return state.fullScreenLoading;
    },

    scrollPaddingRight(state) {
        return state.scroll.paddingRight;
    },

    overlayIsActive(state) {
        return state.overlay.active;
    },

    overlayMenuIsActive(state) {
        return state.overlay.menu;
    },

    overlayElement(state) {
        return state.overlay.element;
    },

    menuIsActive(state) {
        return state.menu.active;
    },

    menuName(state) {
        return state.menu.name;
    },

    menuPan(state) {
        return state.menu.pan;
    },

    menuIsPanning(state) {
        return state.menu.panning;
    },

    searchIsActive(state) {
        return state.search.active;
    },
    activePopup(state) {
        return state.popup;
    },

    activeModal(state) {
        return state.modal[0] || null;
    },

    activeRedirectionHeader(state) {
        return state.redirectionHeader.active;
    },

    preventRedirectionHeader(state) {
        return state.redirectionHeader.prevent;
    },

    activeSubmenu(state) {
        return state.submenu;
    },
};
