export function createStore({ store }, moduleName, defaultLocale = 'en-US') {
    const symbols = { EUR: '€', USD: '$', GBP: '£', CAD: 'CA$', JPY: '¥', HKD: 'HK$', INR: '₹', CHF: 'fr', DKK: 'kr', SEK: 'kr', BRL: 'R$', AUD: '$A', TRY: '₺', NOK: 'kr', AED: 'د.إ', HRK: 'kn', RON: 'L', BGN: 'лв', CZK: 'Kč', HUF: 'Ft', PLN: '‎zł', ISK: 'kr', SGD: 'S$', MYR: 'RM', NZD: 'NZ$' };

    store.registerModule(
        moduleName,
        {
            namespaced: true,

            state: () => ({
                host: '',
                currentLocale: null,
                locales: [],
                currencies: [],
                detectedRegion: null,
                // these params are used in the translations
                globalParams: {
                    website: 'dundle.com',
                    loyaltyName: 'Dundle World',
                },
                rootAliases: null,
            }),

            mutations: {
                initialize(state, { locales, currencies, rootAliases }) {
                    state.locales = locales;
                    state.currencies = currencies.map(currency => ({ code: currency, symbol: symbols[currency] }));
                    state.currentLocale = locales.find(locale => locale.iso === defaultLocale);
                    state.rootAliases = rootAliases;
                },

                setHost(state, host) {
                    state.host = host;
                },

                setLocale(state, newLocale) {
                    if (state.currentLocale.iso.toLowerCase() === newLocale.toLowerCase()) {
                    } else {
                        state.currentLocale = state.locales.find(locale => locale.iso.toLowerCase() === newLocale.toLowerCase()) || state.locales.find(locale => locale.iso === defaultLocale);
                    }
                },

                setDetectedRegion(state, region) {
                    state.detectedRegion = region;
                },

                setGlobalParam(state, { name, value }) {
                    state.globalParams[name] = value;
                },
            },

            actions: {
                initialize({ commit }, { locales, currencies, rootAliases }) {
                    commit('initialize', { locales, currencies, rootAliases });
                },

                setHost({ commit }, host) {
                    commit('setHost', host);
                },

                setLocale({ commit }, locale) {
                    commit('setLocale', locale);
                    this.$locale.applyWebsiteName();
                },

                setDetectedRegion({ commit }, region) {
                    commit('setDetectedRegion', region);
                },

                setGlobalParam({ commit }, { name, value }) {
                    commit('setGlobalParam', { name, value });
                },
            },

            getters: {
                currentLocale(state) {
                    return state.currentLocale;
                },

                locales(state) {
                    return state.locales;
                },

                currencies(state) {
                    return state.currencies;
                },

                rootAliases(state) {
                    return state.rootAliases;
                },

                domain(state) {
                    return state.host;
                },

                detectedRegion(state) {
                    return state.detectedRegion;
                },

                getGlobalParam(state) {
                    return name => {
                        return state.globalParams[name];
                    };
                },

                getGlobalParams(state) {
                    return state.globalParams;
                },
            },
        },
        { preserveState: !!store.state[moduleName] }
    );
}
