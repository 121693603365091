import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }, inject) => {
    app.router.afterEach(to => {
        try {
            if (!('breadcrumb' in to.matched[0].components.default.options)) {
                store.dispatch('breadcrumb/setItems', []);
            }
        } catch (e) {
            // Istanbul ignore
        }
    });
});
