/**
 * IP
 *
 * static API
 */

import { getJSON } from '../utils/json';

export default ({ staticBaseURL, providers }) => ({
    async check() {
        const result = await getJSON(`${staticBaseURL}/ip`);

        return {
            region: result.iplocation,
            isRisky: result.isRisky,
            isKorsit: result.isKorsitIp,
        };
    },
});
