import { parseUTM } from '~/utils/utm';

export const state = () => ({
    id: null,
    referrer: '',
    isInitialized: false,
    isLoaded: false,
    isReturning: false,
    acceptsCookies: false,
    language: '',
    region: '',
    currency: '',
    createdAt: null,
    utm: {
        source: null,
        referrer: null,
    },
    cart: {},
    gclid: null,
});

export const mutations = {
    initialize(state) {
        state.isInitialized = true;
    },

    setIsLoaded(state) {
        state.isLoaded = true;
    },

    setId(state, id) {
        state.id = id;
    },

    acceptCookies(state) {
        state.acceptsCookies = true;
    },

    setReturning(state, value) {
        state.isReturning = value;
    },

    setData(state, { id, language, region, currency, cart, createdAt }) {
        state.id = id;
        state.language = language;
        state.region = region;
        state.currency = currency;
        state.cart = cart;
        state.createdAt = createdAt;
    },

    setUTM(state, utm) {
        state.utm = utm;
    },

    setGclid(state, gclid) {
        state.gclid = gclid;
    },
};

export const actions = {
    setId({ commit }, id) {
        commit('setId', id);
    },

    acceptCookies({ commit }) {
        commit('acceptCookies');

        setTimeout(() => {
            /**
             * @author Tim
             *
             * Explainer!
             *
             * Setting the cookie in the same frame will cause a delay on removing the cookie banner.
             * Therefor, setting the cookie in the NEXT frame will solve this.
             *
             * Todo: Consider requestIdleCallback (on hold: is this being polyfilled?)
             */
            this.$cookies.set('dundle.session.acceptCookies', 'true', {
                maxAge: 60 * 60 * 24 * 7 * 52,
                path: '/',
            });
        }, 100);
    },

    async initialize({ commit, dispatch }, context) {
        // Handle referrer:
        dispatch('handleReferrer', context);

        // Handle cookies:
        dispatch('handleCookies');

        // Fetch session:
        await dispatch('fetch');

        commit('initialize');
    },

    async fetch({ commit, dispatch, getters }) {
        let id = this.$session.idFromCookie;
        let session;

        // id is string 'null' when indirect cookies are blocked, this results in a bad request
        if (id === 'null') id = undefined;

        try {
            session = await this.$api.session.getOrCreateNoUpdate(id, {
                utm: getters.utm,
            });

            const isReturning = session.id === id;

            commit('setReturning', isReturning);

            if (!isReturning) {
                // console.log('Save session.id');
                this.$session.syncCookie(session.id);
            }

            commit('setData', session);
        } catch (err) {
            console.warn(err);
            commit('setReturning', false);
        }

        commit('setIsLoaded');
    },

    async setLocale({ commit, getters }, { region, language, currency }) {
        await this.$api.session.update(getters.id, {
            region,
            language,
            currency,
        });
    },

    handleReferrer({ commit, dispatch }, { route }) {
        // Parse and process UTM:
        let utmCookieValue;
        const utmCookieName = 'dundle.session.utm';

        const utmCookie = this.$cookies.get(utmCookieName);

        if (utmCookie) {
            try {
                utmCookieValue = JSON.parse(utmCookie);
            } catch {
                // Ignore
            }
        }

        // Give preference to cookie value, this is set by next.
        const utm = utmCookieValue || parseUTM(route.query, utmCookieValue);
        commit('setUTM', utm);

        if (typeof route.query.gclid !== 'undefined') {
            commit('setGclid', route.query.gclid);
        }

        // Link affiliate conversion tracking:
        if (typeof route.query.ref_id !== 'undefined') {
            requestIdleCallback(() => {
                this.$api.actions.addReferrer(route.query.ref_id);
            });
        }
    },

    handleCookies({ commit, dispatch }) {
        // Migration fase:
        let oldCookie;
        if ((oldCookie = this.$cookies.get('session.acceptCookies'))) {
            this.$cookies.set('dundle.session.acceptCookies', oldCookie, {
                maxAge: 60 * 60 * 24 * 7 * 52,
                path: '/',
            });
        }

        const acceptCookies = this.$cookies.get('dundle.session.acceptCookies');

        if (acceptCookies) {
            commit('acceptCookies');
        } else {
            let isAccepted = false;
            this.$router.afterEach((to, from) => {
                if (!isAccepted) {
                    isAccepted = true;

                    dispatch('acceptCookies');
                }
            });
        }
    },

    setDevice({ commit }, device) {
        commit('setDevice', device);
    },
};

export const getters = {
    isInitialized(state) {
        return state.isInitialized;
    },

    isLoaded(state) {
        return state.isLoaded;
    },

    isReturning(state) {
        return state.isReturning;
    },

    acceptsCookies(state) {
        return state.acceptsCookies;
    },

    id(state) {
        return state.id;
    },

    region(state) {
        return state.region;
    },

    language(state) {
        return state.language;
    },

    currency(state) {
        return state.currency;
    },

    utm(state) {
        return state.utm;
    },

    cart(state) {
        return state.cart;
    },

    gclid(state) {
        return state.gclid;
    },

    createdAt(state) {
        return state.createdAt;
    },
};
