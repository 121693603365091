// https://dev.frontapp.com/chat.html#introduction
// https://dev.frontapp.com/#teams
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(function ({ app, env }, inject) {
    let loadPromise;

    (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            const d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
        }
    })();

    function loadScript() {
        if (!loadPromise) {
            loadPromise = new Promise((resolve, reject) => {
                const w = window;
                const ic = w.Intercom;
                const d = document;

                // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/s5sqmonn'
                (function () {
                    const s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/s5sqmonn';
                    s.onload = () => {
                        window.Intercom('boot', {
                            app_id: 's5sqmonn',
                            hide_default_launcher: true,
                            // name: "<%= current_user.name %>", // Full name
                            // email: "<%= current_user.email %>", // Email address
                            // created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp
                        });

                        setTimeout(() => resolve(), 1000);
                    };

                    const x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                })();
            });
        }

        return loadPromise;
    }

    let firstPageload = true;

    app.router.afterEach(() => {
        if (firstPageload) {
            firstPageload = false;
        } else {
            window.Intercom('update');
        }
    });

    inject('livechat', {
        async load() {
            await loadScript();
        },

        async open() {
            await loadScript();
            // FrontChat('show');
            await window.Intercom('show');
        },

        close() {
            // Chatra('hide')
            // FrontChat('hide')
            window.Intercom('hide');
        },

        setUser(user) {
            /* Chatra('setIntegrationData', {
                // main properties
                name: user.name,
                email: user.email,
                //notes: 'Looking for courage...',
            }); */
        },
    });
});
