

export function sleep(duration) {
    return new Promise(resolve => setTimeout(resolve, duration));
}


export function resolveOnEvent(eventName) {
    return new Promise(resolve => window.addEventListener(eventName, resolve, { once: true }));
}

const minimalTimeRemaining = 30;
const minimalConsecutiveCalls = 5;

let consecutiveCalls = 0;


function idleCallback(idleDeadline) {

    const timeRemaining = idleDeadline.timeRemaining();

    // Increment number of consecutive calls or reset:
    if (idleDeadline.timeRemaining() > minimalTimeRemaining) {
        consecutiveCalls += 1;
    } else {
        consecutiveCalls = 0;
    }

    // Resolve next in queue when consecutive calls meet requirement:
    if (consecutiveCalls >= minimalConsecutiveCalls) {

        consecutiveCalls = 0;

        const { resolve } = queue.shift();

        resolve();
    }

    if (queue.length > 0) {
        window.requestIdleCallback(idleCallback);
    }
}

let queue = [];

const defaultOptions = {
    priority: 0,
    maxTimeout: 8000
}

export async function resolveWhenIdle(options) {

    if (typeof options === 'number') {
        return new Promise(resolve => window.requestIdleCallback(resolve, { timeout: options }));
    }

    return new Promise(resolve => {

        const task = {
            resolve,
            priority: options.priority,
        };

        queue.push(task);

        if (options.maxTimeout) {
            setTimeout(() => {

                if (queue.includes(task)) {

                    console.warn('Queue task timed out');
                    if (options.name && options.priority && options.maxTimeout) {
                        console.warn(options)
                    }

                    // Remove task from queue:
                    queue = queue.filter(t => t !== task);

                    // Resolve task:
                    task.resolve();

                    // Reset consecutive calls:
                    consecutiveCalls = 0;
                }

            }, options.maxTimeout);
        }

        if (queue.length === 1) {
            window.requestIdleCallback(idleCallback);
        } else {
            queue.sort((a, b) => a.priority - b.priority);
        }
    });
}
