export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'fromEntries' in Object;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "object.fromentries"
        ], function (require) {
            const polyfill = require("object.fromentries");

            const install = (fromEntries) => fromEntries.shim();

            install(polyfill);

            resolve();
        });
    });
}