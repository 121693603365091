import { postJSON } from '../utils/json';

export default ({ dundleNextAPI }) => {
    return {
        getSource(href) {
            const isProduction = href?.includes('.com');
            const localhost = href?.includes('localhost');

            switch (true) {
                case localhost:
                    return 'localhost';
                case isProduction:
                    return 'dundle.com';
                default:
                    return 'dundle.dev';
            }
        },
        async sendEvent(event) {
            const source = this.getSource(window?.location?.href);
            return await postJSON(
                `${dundleNextAPI}/firehose/`,
                { ...event, source },
                {
                    'Content-Type': 'application/json',
                    source,
                }
            ).catch(error => {
                // swallow
            });
        },
    };
};
