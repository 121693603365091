export const state = () => ({
    isOnline: false,
});

export const mutations = {
    setStatus(state, isOnline) {
        state.isOnline = isOnline;
    },
};

export const actions = {
    async fetchStatus({ commit }) {
        const isOnline = await this.$api.support.isLiveChatOnline();

        commit('setStatus', isOnline);
    },
};

export const getters = {
    isOnline(state) {
        return state.isOnline;
    },
};
