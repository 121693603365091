import { getJSON } from "../utils/json";

export default ({ baseURL, providers }) => {
    return {
        getSuggestions(params) {
            // 29-01-2021
            // TODO:
            // queryParams should  actually be parsed on every endpoint, not just this one.
            const qp = new URLSearchParams()
            for (const [k, v] of Object.entries(params)) {
                if (v !== null && v !== undefined) {
                    qp.set(k,v)
                }
            }

            return getJSON(`${baseURL}/address/search?${qp}`);
        },
    };
};