import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app }, inject) => {
    const utils = new Vue({
        methods: {
            formatTimeShort(date) {
                const dateObject = new Date(date);
                const formattedTime = dateObject.toLocaleString(app.$locale.iso, {
                    timeStyle: 'short',
                });
                return formattedTime;
            },
            formatDateShort(date) {
                const dateObject = new Date(date);
                const formattedDate = dateObject.toLocaleString(app.$locale.iso, {
                    dateStyle: 'short',
                });
                return formattedDate;
            },
        },
    });
    inject('utils', utils);
});
