export const state = () => ({
    items: [],
    showLayoutBreadcrumbs: true,
})

export const mutations = {
    setItems(state, items) {
        state.items.splice(0, state.items.length)
        items.forEach(item => state.items.push(item))
    },

    setShowBreadCrumbs(state, bool) {
        state.showLayoutBreadcrumbs = bool
    },
}

export const actions = {
    setItems({ commit }, items) {
        commit('setItems', items)
    },

    showLayoutBreadcrumbs({ commit }, bool) {
        commit('setShowBreadCrumbs', bool)
    },
}

export const getters = {
    getItems(state) {
        return state.items
    },

    showLayoutBreadcrumbs(state) {
        return state.showLayoutBreadcrumbs
    },
}
