

export function getUniqueValues(array) {
    return Array.from(new Set(array));
}

export function shuffle(array) {
    
    const clonedArray = clone(array);

    for (let i = clonedArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [clonedArray[i], clonedArray[j]] = [clonedArray[j], clonedArray[i]];
    }
    
    return clonedArray;
}

export function sum(array) {
    return array.reduce((a, b) => a + b, 0);
}

export function range(start, end, step = 1) {
    return Array.from({ length: (end - start) / step + 1}, (_, i) => start + (i * step));
}

export function reverse(array) {
    
    const result = clone(array);

    result.reverse();

    return result;
}

export function clone(array) {
    return array.slice();
}