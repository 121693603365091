/**
 * This is used to generate a random string stored in a cookie
 * to be used for creating a "fingerprint" used for vaulting PayPal payment methods.
 */

import { COOKIE_NAME, COOKIE_PATH, COOKIE_MAXAGE } from './fingerprint.constants';
import { supportsGetRandomValues, supportsRandomUUID, hexTimeStamp } from './fingerprint.helpers';

/**
 * Generates a random string as securely as possible on the users' device.
 *
 * @returns string
 */
export function generateFingerprint($sentry) {
    try {
        if (supportsRandomUUID()) {
            return globalThis.crypto.randomUUID();
        } else if (supportsGetRandomValues()) {
            const array = globalThis.crypto.getRandomValues(new Uint32Array(1));
            return array[0].toString(16);
        }
    } catch (error) {
        $sentry.captureException(error);
    }

    // Fallback to hexTimeStamp in case of an error
    return hexTimeStamp();
}

/**
 * Returns a unique user identifier from a cookie.
 * If it does not exist yet, it creates and saves it to said cookie.
 *
 * @returns string
 */
const getFingerprint = ({ $cookies, $sentry }) => {
    const fingerPrint = $cookies.get(COOKIE_NAME);

    if (fingerPrint) {
        return fingerPrint;
    }

    const newFingerPrint = generateFingerprint($sentry);

    $cookies.set(COOKIE_NAME, newFingerPrint, {
        path: COOKIE_PATH,
        maxAge: COOKIE_MAXAGE,
    });

    return newFingerPrint;
};

export default ({ app }, inject) => {
    // This plugin is only needed client-side.
    if (!process.client) {
        return;
    }

    inject('fingerprint', {
        get: () => getFingerprint(app),
    });
};
