import {postJSON} from "../utils/json";

export default ({ paymentBaseURL }) => ({

    createNewUserSessionWithoutFingerprint(fingerprint){
        return postJSON(`${paymentBaseURL}/session/createNewAnonymousUserSession`, {
            fingerprint
        });
    },

    createNewUserSession(fingerprint, paymentId) {
        return postJSON(`${paymentBaseURL}/session/createNewUserSession`, { fingerprint, paymentId });
    }

})
