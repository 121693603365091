export const state = () => ({
    stars: 0,
    trustScore: 0,
    reviewTotal: 0,
});

export const mutations = {
    setScore(state, score) {
        state.stars = score.stars;
        state.trustScore = score.trustScore;
    },

    setReviewTotal(state, reviewTotal) {
        state.reviewTotal = reviewTotal;
    },
};

export const actions = {
    async fetch({ commit }) {
        try {
            const trustpilotResult = await this.$api.trustpilot.getServiceReviewSummary();

            const score = trustpilotResult.score;
            const reviewTotal = trustpilotResult.numberOfReviews.total;

            commit('setScore', score);
            commit('setReviewTotal', reviewTotal);
        } catch (e) {}
    },
};

export const getters = {
    getStars(state) {
        return state.stars;
    },

    getTrustScore(state) {
        return state.trustScore;
    },

    getReviewTotal(state) {
        return state.reviewTotal;
    },

    showTrustpilot(state) {
        return state.stars >= 3.5;
    },
};
