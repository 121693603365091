import { resolveWhenIdle } from '@dundle/utils/promise';
import { definePlugin } from '@dundle/utils/nuxt';

// Prevent CLS by doing this much earlier:
if (process.browser) {
    if (document.cookie.includes('dundle.session.acceptCookies=true')) {
        const cookieBanner = document.getElementById('layout-cookie-banner');
        if (cookieBanner) {
            cookieBanner.style.display = 'none';
        }
    }
}

export default definePlugin(async context => {
    const { store, app } = context;

    // Check if spa fallback, by checking if a value in store is empty for example
    if (!store.state.initialized) {
        await store.dispatch('nuxtServerInit', app.context);
    }

    if (process.browser) {
        window.onNuxtReady(async () => {
            await resolveWhenIdle(1000);

            store.dispatch('nuxtClientInit', context);

            // this dispatch is added for the playwright tests to know when hydration happened.
            window.dispatchEvent(new CustomEvent('nuxtReady'));
        });
    }
});
