/**
 * Returns true if the given brand is Google Pplay
 *
 * @param brand
 * @returns { boolean }
 */
export function brandIsFlexepin({ brand }) {
    if (!brand) {
        return false;
    }
    return brand.slug === 'flexepin';
}

/**
 * Returns true if the given product is google-play
 *
 * @param product
 * @returns { boolean }
 */
export function productIsFlexepin({ product }) {
    if (!product) {
        return false;
    }

    return brandIsFlexepin({ brand: product.brand }) && product.currencyCode?.toUpperCase() === 'EUR';
}
/**
 * Returns true if the given list of products contains google-play
 *
 * @param cartItems
 * @returns { boolean }
 */
export function cartHasFlexepin({ cartItems }) {
    if (!cartItems) {
        return false;
    }
    return !!cartItems.find(item => !!productIsFlexepin({ product: item.product }));
}
