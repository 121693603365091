export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
      try {
        const u = new URL('b', 'http://a');

        u.pathname = 'c%20d';

        return !!(u.href === 'http://a/c%20d' && u.searchParams);
      } catch (e) {
        return false;
      }
    };

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "url-polyfill"
        ], function (require) {
            const polyfill = require("url-polyfill");

            resolve();
        });
    });
}