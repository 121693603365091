import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }, inject) => {
    inject(
        'product',
        new Vue({
            computed: {
                keywordData() {
                    return store.getters['translation/getFile']('data/keywords');
                },
            },

            methods: {
                getRoute(product) {
                    return {
                        name: 'brand-product',
                        params: {
                            brand: product.brand.slug,
                            product: `${product.faceValue
                                }-${product.currencyCode.toLowerCase()}-${product.regionCode.toLowerCase()}`,
                        },
                    };
                },

                getPath(product) {
                    return app.$locale.path(this.getRoute(product));
                },

                getURL(product) {
                    return app.$locale.url(this.getRoute(product));
                },

                getName(product) {
                    if (product.productTitle) {
                        return product.productTitle;
                    }

                    const productNameTemplate = product.brand?.productName?.value
                        ? product.brand.productName.value
                        : product.brand?.name;
                    if (productNameTemplate) {
                        // prevent a space when there shouldn't be one
                        let value = `${this.getRepresentationalValue(product)}`;
                        if (this.getRepresentationalUnit(product)) value += ` ${this.getRepresentationalUnit(product)}`;

                        return productNameTemplate
                            .replace('{customName}', this.getCustomName(product))
                            .replace('{value}', value);
                    }

                    return '';
                },

                getSimpleName(product) {
                    const productNameTemplate = product.brand.productName.value;

                    if (productNameTemplate) {
                        return productNameTemplate
                            .replace('{customName}', this.getCustomName(product))
                            .replace('{value}', '');
                    } else {
                        return product.brand.name;
                    }
                },

                getImageSlug(product) {
                    if (
                        app.$locale.region === 'ca' &&
                        product.brand.slug === 'amazon' &&
                        product.currencyCode === 'cad'
                    ) {
                        return product.faceValue + '-cad-' + product.brand.slug;
                    } else {
                        return product.brand.slug;
                    }
                },

                getImage(product) {
                    return `images/products/480w/${this.getImageSlug(product)}-480w.png`;
                },

                getImage200(product) {
                    return `images/products/200w/${this.getImageSlug(product)}-200w.png`;
                },

                getImage340(product) {
                    return `images/products/340w/${this.getImageSlug(product)}-340w.png`;
                },

                getImage480(product) {
                    return `images/products/480w/${this.getImageSlug(product)}-480w.png`;
                },

                getImage640(product) {
                    return `images/products/640w/${this.getImageSlug(product)}-640w.png`;
                },

                getPlatformSrcImage(platform) {
                    // add dashed instead of spaces and concat instead of a '.' or ','
                    const fixedString = String(platform)
                        .replace(/\s+/g, '-')
                        .replace(/[.,\s]/g, '')
                        .toLowerCase();
                    if (fixedString) return `images/brands/platform/${fixedString}.svg`;
                    return null;
                },

                getGoogleShoppingImage(product) {
                    return `https://images.dundle.com/resources/images/products/product-google-shopping/${product.brand.slug}.png`;
                },

                getTextValue(product) {
                    return this.getRepresentationalValue(product) + ' ' + this.getRepresentationalUnit(product);
                },

                getRepresentationalValue(product) {
                    if (product.unitType === 'value') {
                        return app.$formatNumber(product.faceValue, product.currencyCode, {
                            rounded: this.hasRoundFaceValue(product),
                        });
                    } else {
                        return product.unitAmount;
                    }
                },

                hasRoundFaceValue(product) {
                    const faceValueDecimals = String(product.faceValue).split('.')[1];
                    if (!faceValueDecimals || faceValueDecimals === '00') {
                        return true;
                    }
                    return false;
                },

                getRepresentationalUnit(product) {
                    if (!product.unitType || product.unitType === 'value') {
                        return ''; // Empty string, we don't want to show any unit since it's in the representational value.
                    }

                    if (product.unitType === 'time') {
                        const singular = this.keywordData[`keywords.time.${product.unitName}.singular`];
                        const plural = this.keywordData[`keywords.time.${product.unitName}.plural`];

                        return product.unitAmount === 1 ? singular : plural;
                    }

                    if (product.unitType === 'custom') {
                        return product.unitName;
                    }

                    console.warn('Unknown Product::unitType in', product);
                    return '';
                },

                getDiscount(product) {
                    const discount = this.getValue(product) - this.getPrice(product);

                    if (discount <= 0) {
                        return 0;
                    }

                    return discount;
                },

                getDiscountPercentage(product) {
                    const discount =
                        100 * Math.abs((this.getPrice(product) - this.getValue(product)) / this.getValue(product));

                    if (discount <= 0) {
                        return 0;
                    }

                    return Math.ceil(discount);
                },

                /**
                 * Value in EUR
                 */
                getValue(product) {
                    if (product.gameMsrpInEur) {
                        return product.gameMsrpInEur;
                    }
                    return product.faceValue * app.$currency.exchangeFrom(product.currencyCode);
                },

                getPrice(product) {
                    return Math.min(product.price, this.getValue(product));
                },

                getCoinPrice(product) {
                    return product.dundleCoinsNeeded;
                },

                getServiceCosts(product) {
                    return Math.max(0, product.price - this.getValue(product));
                },

                hasDoubleCoinsDeal(product) {
                    if (product.dundleCoinsRewardedAsAction) {
                        return true;
                    }
                    return false;
                },
                getCustomName(product) {
                    if (
                        product &&
                        product.brand &&
                        product.brand.slug &&
                        product.brand.slug.toLowerCase() === 'amazon'
                    ) {
                        const mapDomain = domain => {
                            const map = {
                                us: 'com',
                                gb: 'co.uk',
                                au: 'com.au',
                                jp: 'co.jp',
                                mx: 'com.mx',
                            };

                            if (domain in map) {
                                return map[domain];
                            }

                            return domain;
                        };

                        return 'Amazon.' + mapDomain(product.regionCode);
                    }
                },
            },
        })
    );
});
