import { getJSON } from "../utils/json";

export default ({ staticBaseURL, providers }) => ({
    async getServiceReviewSummary() {
        return await getJSON(
            `${staticBaseURL}/trustpilot/service_review_summary/${providers.trustpilotId()}`
        );
    },

    async getProductReviewSummary(sku) {
        return await getJSON(
            `${staticBaseURL}/trustpilot/product_review_summary/${providers.trustpilotId()}?sku=${sku}`
        );
    },

    async getProductReviews(sku, language, page, perPage = 5) {
        return await getJSON(
            `${staticBaseURL}/trustpilot/product_reviews/${providers.trustpilotId()}?sku=${sku}&language=${language}&page=${page}&perPage=${perPage}`
        );
    },
});
