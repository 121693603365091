/**
 * This Vuex plugin is used to watch for
 * store mutations and update the Sentry Scope
 * accordingly.
 */
export default function sentryPlugin(store) {
    if (process.browser) {
        store.subscribe(handler);
    }
}

const handler = (mutation, state) => {
    // If we don't try/catch here components will break
    // if an exception is thrown.
    try {
        if (mutation.type.includes('user/')) {
            updateSentryUser(state.user);
        }

        if (mutation.type.includes('cart/')) {
            updateSentryCartItems(state.cart);
            updateSentryPaymentMethod(state.cart);
        }

        if (mutation.type.includes('setLocale')) {
            const { regio: region, language, currency } = state.locale.currentLocale;
            updateSentryLocale(region, language, currency);
        }
    } catch (error) {
        $nuxt.$sentry?.withScope(scope => {
            scope.setExtra('mutation', mutation);
            scope.setExtra('state', state);
        });
    }
};

function updateSentryUser(user) {
    $nuxt.$sentry?.configureScope(scope => {
        scope.setUser(user);
    });
}

function updateSentryPaymentMethod(cart) {
    $nuxt.$sentry?.configureScope(scope => {
        scope.setTag('paymentMethod', cart?.paymentMethod?.id ?? 'unknown');
    });
}

function updateSentryCartItems(cart) {
    // Reduce payload size by only sending product ID's and quantities
    const cartItems = cart.items.map(item => {
        return {
            productId: item.productId,
            quantity: item.quantity,
        };
    });

    const isPaysafecardOrder = cartItems.some(item => item.productId?.includes('paysafecard'));

    $nuxt.$sentry?.configureScope(scope => {
        scope.setExtra('cartItems', cartItems);
        scope.setTag('is-paysafecard-order', isPaysafecardOrder);
    });
}

function updateSentryLocale(region, language, currency) {
    region = region.toLowerCase();
    language = language.toLowerCase();
    currency = currency.toLowerCase();

    $nuxt.$sentry?.configureScope(scope => {
        scope.setTag('region', region);
        scope.setTag('language', language);
        scope.setTag('currency', currency);
    });
}
