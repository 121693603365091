/**
 * Utility functions and constants related to NeosurfX brands.
 */

import { NEOSURFX_PRODUCTS, NEOSURFX_MAX_QUANTITY } from './constants';

/**
 * Returns true if the given product belongs to a NeosurfX brand
 *
 * @param product
 * @returns { boolean }
 */
export function isNeosurfX({ productId }) {
    if (!productId) {
        return false;
    }
    return NEOSURFX_PRODUCTS.some(slug => productId.includes(slug));
}

/**
 * Returns true if the user is allowed to add
 * NeosurfX items to the cart.
 *
 * @param items
 * @returns { boolean }
 */
export function isAllowedToAddNeosurfX(items) {
    if (!items) {
        return true;
    }

    /**
     * Check every NeosurfX brand to see if the product limit is reached.
     */
    const isAtLimit = NEOSURFX_PRODUCTS.some(slug => {
        return items.some(({ productId, quantity }) => productId.includes(slug) && quantity >= NEOSURFX_MAX_QUANTITY);
    });

    return !isAtLimit;
}
