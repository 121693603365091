import { getJSON } from '../utils/json';

export default ({ cdnURL, providers, ...rest }) => ({
    async getAll(region = providers.region()) {
        try {
            return (await getJSON(`${cdnURL}/payment-methods/${region}.json`)).methods;
        } catch (error) {
            return (await getJSON(`${cdnURL}/payment-methods/us.json`)).methods;
        }
    },
});
