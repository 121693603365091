import { getJSON, postJSON, patchJSON } from "../utils/json";

export default ({ baseURL, providers }) => {
    return {
        async getById(id) {
            return (await getJSON(`${baseURL}/sessions/${id}`)).session;
        },

        async getOrCreate(id, session = {}) {
            return (
                await postJSON(`${baseURL}/sessions/getOrCreate`, {
                    ...session,
                    id,
                    region: providers.region(),
                    language: providers.language(),
                    currency: providers.currency(),
                })
            ).session;
        },

        async getOrCreateNoUpdate(id, session = {}) {
            return (
                await postJSON(`${baseURL}/sessions/getOrCreateNoUpdate`, {
                    ...session,
                    id,
                    region: providers.region(),
                    language: providers.language(),
                    currency: providers.currency(),
                })
            ).session;
        },

        async create(session) {
            return (
                await postJSON(`${baseURL}/sessions/`, {
                    ...session,
                    region: providers.region(),
                    language: providers.language(),
                    currency: providers.currency(),
                })
            ).session;
        },

        async update(id, session) {
            return (await patchJSON(`${baseURL}/sessions/${id}`, session))
                .session;
        },
    };
};
