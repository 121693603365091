import axios from 'axios';
import * as MockAdapter from 'axios-mock-adapter';

let mock;
let mockUrls;

if (process.env.NUXT_ENV_MOCK) {
    mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
    mockUrls = require(`../mock/index`).default;
}

export async function getJSON(url, headers = {}) {
    if (process.env.NUXT_ENV_MOCK) {
        const mockUrl = mockUrls.find(
            mockUrl =>
                mockUrl.substring(mockUrl.lastIndexOf('/') + 1) ===
                url
                    .replace(/\/$/, '')
                    .substring(url.replace(/\/$/, '').lastIndexOf('/') + 1)
                    .split('?')[0]
        );
        if (mockUrl) {
            console.warn('Mocking is enabled for', mockUrl);
            const json = require(`../mock/${mockUrl}`).default;
            mock.onGet(url).reply(200, json);
        }
    }

    return await axios
        .get(url, {
            headers,
        })
        .then(response => {
            return response.data;
        });
}

export async function postJSON(url, data, headers = { 'Content-Type': 'application/json' }) {
    return await axios
        .post(url, data, {
            headers: {
                ...headers,
                Accept: 'application/json',
            },
        })
        .then(response => {
            return response.data;
        });
}

export async function patchJSON(url, data, headers = {}) {
    return await axios
        .patch(url, data, {
            headers: {
                ...headers,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            return response.data;
        });
}

export async function putJSON(url, data, headers = {}) {
    return await axios
        .put(url, data, {
            headers: {
                ...headers,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            return response.data;
        });
}
