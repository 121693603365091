import { getJSON } from "../utils/json";

export default ({ staticBaseURL, providers }) => ({
    async getTranslationsByKey({key, language = providers.language(), allowFallback = true}) {
        return await getJSON(
            `${staticBaseURL}/translations/key/?key=${encodeURIComponent(key)}&locale=${language}&allowFallback=${allowFallback}`
        );
    },

    async getAllTranslations(language = providers.language()) {
        return await getJSON(
            `${staticBaseURL}/translations/locale/${language}`
        );
    },
});
