import { parseHash } from '@dundle/utils/string';
import { definePlugin } from '@dundle/utils/nuxt';
import { availableModals } from '~/components/modal/ModalContent';

export default definePlugin(({ app, route, store }) => {
    const hash = { ...parseHash(route.hash), ...route.query };
    if (hash.modal || hash.discount_code) store.dispatch('ui/preventRedirection');

    app.router.afterEach(route => {
        const hash = { ...parseHash(route.hash), ...route.query };

        if (hash.discount_code && store.getters['cart/getDiscountCode'] !== hash.discount_code) {
            setTimeout(() => {
                app.$api.actions
                    .checkDiscount(hash.discount_code)
                    .then(res => {
                        if (res) {
                            store.dispatch('cart/setDiscount', res);
                            store.dispatch('cart/setDiscountCode', hash.discount_code);
                            store.dispatch('ui/showModal', 'discount');
                        }
                    })
                    .catch(err => {
                        store.dispatch('cart/setDiscount', {});
                        store.dispatch('cart/setDiscountCode', hash.discount_code);
                        store.dispatch('ui/showModal', 'discount');
                        console.error(err);
                    });
            }, 1500);
        } else if (hash.modal) {
            setTimeout(() => {
                if (availableModals.includes(hash.modal)) store.dispatch('ui/showModal', { name: hash.modal, isLargeModal: true });
            }, 1500);
        } else if (!hash.modal && store.getters['ui/activeModal']) {
            store.dispatch('ui/hideModal');
        }
    });
});
