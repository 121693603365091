import { BUNDLE_SKUS } from './constants';

/**
 * Returns true is the given product has the word bundle in its id
 * @param product
 * @returns { boolean }
 */
export function productIsBundle({ product }) {
    if (!product) {
        return false;
    }
    const skuBundle = BUNDLE_SKUS.find(bundle => bundle.productId === product.productId);
    return !!skuBundle;
}

/**
 * Returns true if the given list of products contains a bundle product
 *
 * @param cartItems
 * @returns { boolean }
 */
export function cartHasBundleProduct({ cartItems }) {
    if (!cartItems) {
        return false;
    }

    return !!cartItems.find(item => !!productIsBundle({ product: item.product }));
}

export function bundleRulesCanAddProduct({ cartItems, product }) {
    if (!cartItems?.length) {
        return true;
    }

    // If product is bundle && there is a bundle product in the cart do not allow add to cart
    if (productIsBundle({ product }) && cartHasBundleProduct({ cartItems })) {
        return false;
    }

    return true;
}

export function getBundleSize({ product }) {
    const skuBundle = BUNDLE_SKUS.find(bundle => bundle.productId === product.productId);
    return `${skuBundle.bundleSize} x ${skuBundle.faceValuePerItem}`;
}
