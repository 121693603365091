export default {
    orderId: '9e27fc20-76b8-4b63-bf56-01934262bb5c',
    transaction: {
        transactionId: '2437685331X15d2e',
        name: '',
        email: 'm.oomens@korsit.com',
        verificationEmail: 'm.oomens@korsit.com',
        method: 'ideal',
        currency: 'EUR',
        amount: 7.54,
        products: [
            {
                productId: 'steam-code-5-euro-nl',
                code: '',
                imageURL: 'http://dev.korsit.com/webapi/v3/payment/smallImage/null',
                priceInEur: 7.54,
            },
        ],
        meta: '{"trxFeeExperimentAmount":0.18,"vatRegionSetting":"nl","ipRegion":"nl","fp1":"a4bfae67-40eb-47a4-91c0-a0b5da34dc96","visitId":"28a2b90f-539a-4384-a1b1-05d8a3b21de7","ab":"next","utm":{"source":"dundle.dev","medium":"referral","referrer":"https://dundle.dev/nl/en/"},"isDev":true,"cid":"","website":"dundle.com","sessionId":"96355f49-9913-4486-a055-f94923a80633","user":{"id":"89acfcd2-289d-439d-81da-1ae939312cf8"},"orderId":"9e27fc20-76b8-4b63-bf56-01934262bb5c","enableCreditCardCheck":true,"strategy":"guest","creditCardData":{"sessionUUID":"96355f49-9913-4486-a055-f94923a80633"},"ipblocktype":"0","trxFeeExperimentOriginalAmount":0.15,"trxFeeExperimentDiscountedAmount":0.15,"cancelUrl":"https://dundle.dev/nl/en/payment/","returnUrl":"https://dundle.dev/nl/en/order/9e27fc20-76b8-4b63-bf56-01934262bb5c/?email\\u003dm.oomens%40korsit.com","initialTransactionId":"4c4ec1f1-1007-4b38-9824-dff0d01a760b","paytrx":"2437685331X15d2e","gaConversionTracked":true,"scoretransaction":0.0,"salt":1.35,"profit":0}',
        status: 'Finished',
        customerPoints: 57.17,
        dundleCoins: 24914,
        emailDeliveryOnly: false,
        platform: 'WEB',
        orderDate: 1714467230000,
        orderLastUpdatedDate: 1714467284000,
        isDeclined: false,
    },
    products: [
        {
            id: 62,
            createdAt: '2019-08-02T11:10:42.622Z',
            updatedAt: '2021-11-29T10:00:05.000Z',
            deletedAt: null,
            productId: 'steam-code-5-euro-nl',
            productTitle: null,
            price: 7.54,
            faceValue: 5,
            isVat: false,
            currencyCode: 'eur',
            type: 'currency',
            stock: 20,
            imageName: 'steam_1',
            regionCode: 'eu',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: true,
            ean13: '4250797668747',
            gtin: null,
            parentId: null,
            gamePlatform: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 3915,
            dundleCoinsRewarded: 207,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 46,
                createdAt: '2019-08-02T11:04:47.428Z',
                updatedAt: '2023-12-11T14:30:51.000Z',
                deletedAt: null,
                name: 'Steam',
                primaryTags: [
                    'подарочная карта стим',
                    'пополнить стим',
                    'как подарить игру в стиме',
                    'как пополнить кошелек стим',
                    'как пополнить стим на любую сумму',
                    'как пополнить стим',
                    'как пополнить баланс стим',
                ],
                secondaryTags: null,
                slug: 'steam',
                color: '#95BCD3',
                parentId: null,
                productMatchers: ['steam-code-.*', 'steam-.*', '^Steam-.*$'],
                excludedRegions: null,
                availableInRegions: [
                    'us',
                    'au',
                    'it',
                    'pl',
                    'es',
                    'pt',
                    'ie',
                    'ca',
                    'gb',
                    'nl',
                    'fr',
                    'de',
                    'at',
                    'be',
                    'ch',
                    'lu',
                    'tr',
                    'dk',
                    'fi',
                    'ru',
                    'br',
                    'mx',
                    'se',
                    'hu',
                    'bg',
                    'lv',
                    'lt',
                    'in',
                    'no',
                    'hr',
                    'ae',
                    'sk',
                ],
                availableInLanguages: [
                    'en',
                    'it',
                    'pl',
                    'fr',
                    'nl',
                    'de',
                    'es',
                    'pt',
                    'tr',
                    'da',
                    'fi',
                    'ru',
                    'sv',
                    'hu',
                    'bg',
                    'hr',
                    'nb',
                    'sk',
                ],
                importKey: 'steam',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: null,
                defaultCurrency: 43,
                spotlightRegions: ['ae', 'mt', 'mx', 'hr', 'bg', 'lt', 'lv', 'lu', 'in'],
                categories: 'gamecards',
                game: null,
                redeemInstructions:
                    '<h2>How to redeem your Steam Code</h2><ol><li class="inline-list-item"><p>Go to the <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://store.steampowered.com/account/redeemwalletcode">Steam Redeem page</a> and log into your Steam account.</p></li><li class="inline-list-item"><p>Enter your code into the code field.</p></li><li class="inline-list-item"><p>Click “Continue” to redeem the code.</p></li><li class="inline-list-item"><p>Done! The gift card value has been added to your Steam Wallet.</p></li></ol><p><strong>Validity: </strong>Your Steam Gift Card currency must match that of the Steam user account it will be redeemed to, or you will get an error. <strong>Your code and its value will never expire</strong>.</p>',
                redeemUrl: 'https://store.steampowered.com/account/redeemwalletcode',
                category: 'gamecards',
                productName: {
                    id: 1613,
                    createdAt: '2019-08-02T11:04:48.127Z',
                    updatedAt: '2019-11-04T10:21:26.000Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'Steam Gift Card {value}',
                    type: 'brand',
                    region: null,
                },
            },
        },
    ],
    rules: [],
    user: {
        id: '89acfcd2-289d-439d-81da-1ae939312cf8',
        userId: '89acfcd2-289d-439d-81da-1ae939312cf8',
        email: 'm.oomens@korsit.com',
        createdAt: '2023-05-09T09:15:32.890Z',
        type: 'user',
        loyaltyPoints: 0,
        verified: { email: false, mobilePhone: false },
        region: 'nl',
        language: 'nl',
        currency: 'EUR',
        _rid: 'VIh4AKtDfhTAFQAAAAAAAA==',
        _self: 'dbs/VIh4AA==/colls/VIh4AKtDfhQ=/docs/VIh4AKtDfhTAFQAAAAAAAA==/',
        _etag: '"9400cedb-0000-0d00-0000-6630b1c90000"',
        _attachments: 'attachments/',
        domain: 'dundle.com',
        firstName: 'naam',
        lastName: 'achternaam',
        address: {
            city: 'Stad',
            postalCode: '1234 CM',
            countryCode: 'nl',
            country: 'Netherlands',
            streetName: 'straat',
            displayValue: 'straat, 1234 CM Stad',
            geoloc: { lat: 51.51688, lon: 5.39715 },
            source: 'azure-maps',
            raw: {
                type: 'Street',
                id: 'dVuyZN48lxxs0qDoQCix7A',
                score: 3.9823999405,
                matchConfidence: { score: 1 },
                address: {
                    streetName: 'wef',
                    municipality: 'Best',
                    countrySubdivision: 'North Brabant',
                    countrySubdivisionName: 'North Brabant',
                    countrySubdivisionCode: 'NB',
                    postalCode: 'wef',
                    extendedPostalCode: '1233 CM',
                    countryCode: 'NL',
                    country: 'Netherlands',
                    countryCodeISO3: 'NLD',
                    freeformAddress: 'qwdqwd, 1231 CM Stad',
                    localName: 'Stad',
                },
            },
        },
        mobilePhone: '+31123123123',
        _ts: 1714467273,
    },
    isValidated: true,
    paymentUrl:
        'https://api.pay.nl/controllers/payments/issuer.php?orderId=2437685331X15d2e&entranceCode=885e929c0fee1d4863feae65487b2cd3911e24bd&profileID=613&lang=NL',
    gifts: [],
    isCreditCard: false,
};
