import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app }, inject) => {
    inject('navigation', {
        goto: route => {
            return new Promise((resolve, reject) => {
                app.router.push(route, resolve, reject);
            });
        },
    });
});
