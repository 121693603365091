import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":false,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":false,"home":false,"callback":"/connect"},"vuex":{"namespace":"auth"},"cookie":false,"localStorage":{"prefix":"dundle_"},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"guest"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // guest
  $auth.registerStrategy('guest', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"https://v2.dundle-api.com/api/auth/guest/login","method":"post","propertyName":"token"},"logout":{"url":"https://v2.dundle-api.com/api/auth/guest/logout","method":"post"},"user":{"url":"https://v2.dundle-api.com/api/auth/guest/user","method":"get","propertyName":"user"}},"tokenRequired":true,"autoFetchUser":false,"_name":"guest"}))

  // account
  $auth.registerStrategy('account', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"https://v2.dundle-api.com/api/auth/account/login ","method":"post","propertyName":"token"},"logout":{"url":"https://v2.dundle-api.com/api/auth/account/logout","method":"post"},"user":{"url":"https://v2.dundle-api.com/api/auth/account/user","method":"get","propertyName":"user"}},"tokenRequired":true,"autoFetchUser":false,"_name":"account"}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"1024742687705262","userinfo_endpoint":"https://graph.facebook.com/v2.12/me?fields=about,name,picture{url},email,birthday","scope":["public_profile","email"],"_name":"facebook","authorization_endpoint":"https://facebook.com/v2.12/dialog/oauth"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"client_id":"1056778839564-j33g307i5rvtni56sgjhtocm0gjj40d8.apps.googleusercontent.com","_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo","scope":["openid","profile","email"]}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
