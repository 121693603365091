/**
 * Base path to the endpoint for the gift paper PDF rendering.
 */
const basePath = '/gift-paper/pdf';

export default ({ staticBaseURL, providers }) => ({
    async getPdf({ code, message, productId, templateId }, region = providers.region(), language = providers.language()) {
        const params = new URLSearchParams({ code, message, productId, templateId, region, language });
        const response = await fetch(`${staticBaseURL}${basePath}?${params}`);

        if (response.status >= 200 && response.status <= 299) {
            return await response.blob();
        } else {
            throw new Error(response.status);
        }
    },
});
