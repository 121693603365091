import { getJSON } from "../utils/json";

export default ({ staticBaseURL, providers }) => ({
    async getNotifications(
        region = providers.region(),
        language = providers.language()
    ) {
        return await getJSON(
            `${staticBaseURL}/notifications/region/${region}?lang=${language}`
        );
    },
});
