import Vue from 'vue';

export const state = () => ({
    isEnabled: false,
    token: '',
    changes: [],
});

export const mutations = {
    init(state, changes) {
        Vue.set(state, 'isEnabled', true);
        Vue.set(state, 'changes', changes);
    },

    saveContent(state, change) {

        const index = state.changes.findIndex(c => c.key === change.key);

        if (index > -1) {
            Vue.set(state.changes, index, change);
        } else {
            state.changes.push(change);
        }
    },

    setToken(state, token) {
        state.token = token;
    }
};

export const actions = {

    mount({ commit }) {

        window.addEventListener('message', (message) => {
            if (message.data.validate === 'dundle') {
                if (message.data.type === 'init') {
                    console.log('Initialized CMS');
                    commit('init', message.data.changes);
                    console.log(message.data.changes);

                    window.parent.postMessage({
                        validate: 'dundle',
                        type: 'finish-init'
                    }, '*');
                }

                if (message.data.type === 'token') commit('setToken', message.data.token);
            }
        }, false);

        try {

            // Request initialize to parent CMS:
            window.parent.postMessage({
                validate: 'dundle',
                type: 'request-init'
            }, '*');

        } catch (e) {

        }
    },

    saveContent({ commit }, content) {

        const change = {
            type: 'content',
            key: 'content-' + content.id,
            title: content.type + '.' + content.key,
            subtitle: window.location.pathname,
            parentId: content.parentId,
            newValue: content
        };

        commit('saveContent', change);

        // Send message to CMS:
        window.parent.postMessage({
            validate: 'dundle',
            type: 'update',
            change: change
        }, '*');
    }
};

export const getters = {

    isEnabled(state) {
        return state.isEnabled;
    },

    getChange(state) {

        return (id) => {

            const change = state.changes.find(c => c.type === 'content' && c.newValue.id === id);

            if (change) {
                return change.newValue;
            }
        }
    },

    getToken(state) {
        return state.token;
    }
};
