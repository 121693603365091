export const state = () => ({
    firstCategory: null,
})

export const actions = {
    setCategory({ commit }) {
        commit('setCategory')
    },
}

export const mutations = {
    setCategory(state, category) {
        state.firstCategory = category
    },
}

export const getters = {
    getCategory(state) {
        return state.firstCategory || 'giftcards'
    },
}
