export default {
    orderId: 'b147faf8-15c4-461c-9e89-0ec2fa0d11fc',
    transaction: {
        transactionId: '2595190710Xbc7c4',
        name: '',
        email: 'eline_paysafecard_mx@testtest.nl',
        verificationEmail: 'eline_paysafecard_mx@testtest.nl',
        method: 'ideal',
        currency: 'EUR',
        amount: 7.1,
        products: [
            {
                productId: 'paysafecard-100-mxn-mx',
                code: '420d1ccf-691e-11ef-8705-0a7aa3a969e6',
                serial: '420d1cd7-691e-11ef-8705-0a7aa3a969e6',
                imageURL: 'https://dev.korsit.com/webapi/v3/payment/smallImage/null',
                issuingDate: 1725276414000,
                terminalId: '28785019',
                traceCode: '1725621939494',
                priceInEur: 7.1,
                parsedCode: {
                    cvc: null,
                    date: null,
                    pin: null,
                    value: '420d1ccf-691e-11ef-8705-0a7aa3a969e6',
                },
            },
        ],
        meta: '{"trxFeeExperimentAmount":0.18,"vatRegionSetting":"mx","ipRegion":"nl","fp1":"bce37db4-d832-40ad-be35-f70673cc9c0e","visitId":"462604ea-5e85-4591-b5dd-a1f895c746f7","ab":"next","utm":{"source":"dundle.dev","medium":"referral","referrer":"https://dundle.dev/mx/"},"isDev":true,"cid":"1861867373.1724923882","website":"dundle.com","sessionId":"8b15becc-1b90-463b-ac95-fb7a1e75be18","user":{"id":"b9789ba4-4c1a-4a34-810b-db13e4454d56"},"orderId":"b147faf8-15c4-461c-9e89-0ec2fa0d11fc","enableCreditCardCheck":true,"strategy":"guest","creditCardData":{"sessionUUID":"8b15becc-1b90-463b-ac95-fb7a1e75be18"},"trxFeeExperimentOriginalAmount":0.15,"trxFeeExperimentDiscountedAmount":0.15,"cancelUrl":"https://dundle.dev/mx/pago/","returnUrl":"https://dundle.dev/mx/order/b147faf8-15c4-461c-9e89-0ec2fa0d11fc/?email\\u003deline_paysafecard_mx%40testtest.nl","scoreorder":38.74993037269599,"PrePaymentScore":38.74993037269599,"initialTransactionId":"c001ee29-ecf7-4198-9c82-5b13eaa6308b","paytrx":"2595190710Xbc7c4","payIp":"95.97.27.98","AfterPaymentScore":0.0,"gaConversionTracked":true,"scoretransaction":0.0,"salt":0.5599999999999999,"profit":0}',
        status: 'Finished',
        customerPoints: 0.32,
        dundleCoins: 140,
        emailDeliveryOnly: false,
        platform: 'WEB',
        orderDate: 1725625012000,
        orderLastUpdatedDate: 1725625029000,
        isDeclined: false,
    },
    products: [
        {
            id: 13890,
            createdAt: '2024-02-21T08:00:12.767Z',
            updatedAt: '2024-02-21T08:00:12.767Z',
            deletedAt: null,
            productId: 'paysafecard-100-mxn-mx',
            productTitle: null,
            price: 7.1,
            faceValue: 100,
            isVat: false,
            currencyCode: 'mxn',
            type: 'region',
            stock: 1,
            imageName: 'paysafecard',
            regionCode: 'mx',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: false,
            ean13: null,
            gtin: null,
            parentId: null,
            gamePlatform: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 3480,
            dundleCoinsRewarded: 140,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 55,
                createdAt: '2019-08-02T11:04:48.544Z',
                updatedAt: '2024-07-04T09:23:11.000Z',
                deletedAt: null,
                name: 'paysafecard',
                primaryTags: ['psc'],
                secondaryTags: null,
                slug: 'paysafecard',
                color: '#3200FF',
                parentId: null,
                productMatchers: ['paysafecard-.*'],
                excludedRegions: null,
                availableInRegions: [
                    'us',
                    'it',
                    'pl',
                    'es',
                    'pt',
                    'ie',
                    'gb',
                    'nl',
                    'fr',
                    'de',
                    'at',
                    'be',
                    'ch',
                    'lu',
                    'tr',
                    'cz',
                    'se',
                    'sk',
                    'no',
                    'fi',
                    'bg',
                    'hu',
                    'ro',
                    'mt',
                    'lt',
                    'lv',
                    'dk',
                    'br',
                    'ae',
                    'au',
                    'mx',
                    'hr',
                ],
                availableInLanguages: [
                    'en',
                    'it',
                    'pl',
                    'fr',
                    'nl',
                    'de',
                    'es',
                    'pt',
                    'tr',
                    'fi',
                    'sv',
                    'cs',
                    'da',
                    'sk',
                    'nb',
                    'ro',
                    'hu',
                    'bg',
                    'ru',
                ],
                importKey: 'paysafecard',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: 79,
                defaultCurrency: null,
                spotlightRegions: ['ae', 'br', 'no', 'bg', 'mx', 'hr'],
                categories: 'paymentcards',
                game: null,
                redeemInstructions:
                    '<h2>Cómo canjear la tarjeta paysafecard</h2><p><strong>Sin registro:</strong></p><ol><li class="inline-list-item"><p>Ve al sitio web donde quieres gastar el crédito (o parte del mismo).</p></li><li class="inline-list-item"><p>Añade tus productos al carrito.</p></li><li class="inline-list-item"><p>Al pagar, selecciona paysafecard como forma de pago.</p></li><li class="inline-list-item"><p>Introduce el código de 16 dígitos que te hemos enviado por e-mail.</p></li><li class="inline-list-item"><p>Confirma el pedido. ¡Y listo! El crédito que no gastes queda guardado en tu cuenta para futuros pagos.</p></li></ol><p><strong>Con registro</strong> (necesario para pagos de más de 50 €):</p><ol><li class="inline-list-item"><p>Para empezar, necesitas tener o crear <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://registration.paysafecard.com/customer-registration/#/country?product=psc&registration_interface=WEB">una cuenta myPaysafe</a>.</p></li><li class="inline-list-item"><p>Añade saldo canjeando tu código PIN directamente en tu cuenta.</p></li><li class="inline-list-item"><p>Ve a la web donde quieras pagar y, en el momento de la compra, selecciona paysafecard como método de pago.</p></li><li class="inline-list-item"><p>Introduce tus datos de inicio de sesión, ¡y listo!  </p></li></ol><p><strong>Validez</strong>: Este código no caduca, pero está sujeto a tasas de inactividad. Sin registro, tienes 30 días para usarlo sin coste extra. Con registro en tu cuenta paysafecard, tienes 12 meses. Este PIN solo es válido en cuentas configuradas en la misma moneda. Consulta las <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://www.paysafecard.com/es/condiciones-generales/">condiciones generales</a> de paysafecard.</p>',
                redeemUrl: 'https://www.paysafecard.com/es/consulta-de-saldo/',
                category: 'paymentcards',
                productName: {
                    id: 5496,
                    createdAt: '2019-10-01T09:28:35.525Z',
                    updatedAt: '2020-10-09T13:33:49.000Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'paysafecard {value}',
                    type: 'brand',
                    region: null,
                },
            },
        },
    ],
    rules: [],
    user: {
        id: 'b9789ba4-4c1a-4a34-810b-db13e4454d56',
        userId: 'b9789ba4-4c1a-4a34-810b-db13e4454d56',
        email: 'eline_paysafecard_mx@testtest.nl',
        createdAt: '2024-09-06T12:16:41.327Z',
        type: 'user',
        loyaltyPoints: 0,
        verified: {
            email: false,
            mobilePhone: false,
        },
        region: 'mx',
        language: 'es',
        currency: 'MXN',
        _rid: 'VIh4AKtDfhQ0HQAAAAAAAA==',
        _self: 'dbs/VIh4AA==/colls/VIh4AKtDfhQ=/docs/VIh4AKtDfhQ0HQAAAAAAAA==/',
        _etag: '"0100310d-0000-0d00-0000-66daf2bc0000"',
        _attachments: 'attachments/',
        domain: 'dundle.com',
        _ts: 1725625020,
    },
    isValidated: true,
    paymentUrl:
        'https://api.pay.nl/controllers/payments/issuer.php?orderId=2595190710Xbc7c4&entranceCode=f11970a1fcc448f1413ef0df2ed983d63e404d86&profileID=613&lang=NL',
    gifts: [],
    isCreditCard: false,
};
