import { patchJSON, postJSON } from '../utils/json';

export default ({ app, baseURL, providers }) => {
    return {
        async updateUser(userData) {
            return await patchJSON(`${baseURL}/user`, userData, {
                Authorization: providers.userToken(),
            });
        },

        async shouldVerify(cartItems, methodId) {
            let detectedCountry = providers.userRegion();

            if (!detectedCountry) {
                const ipResult = await app.$api.ip.check();
                detectedCountry = ipResult?.region;
            }

            const endpoint = `${baseURL}/user/order/rule/should-verify`;
            const body = {
                methodId: methodId || '',
                ipCountry: detectedCountry,
                cartItems: cartItems.map(item => ({
                    productId: item.productId,
                })),
            };

            return await postJSON(endpoint, body, {
                Authorization: providers.userToken(),
            });
        },
    };
};
