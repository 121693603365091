export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
      // Can perhaps be replaced by a simpler implementation:
      // `return 'loading' in HTMLImageElement.prototype;`
      // but I am unable to test this. The check below works.
      if ('userAgent' in navigator) {
        const isMobileSafari = /(iPod|iPhone|iPad)/.test(navigator.userAgent) && /AppleWebKit/.test(navigator.userAgent);
        return !isMobileSafari;
      }
      return false; // Load polyfill just to be safe if userAgent can't be detected.
    };

    if (detect()) {
        return;
    }

            const polyfill = require("loading-attribute-polyfill");
}