import { getCookieMainDomain } from './helpers/cookie.helpers';

export default function ({ app }) {
    const originalCookieSet = app.$cookies.set;

    app.$cookies.set = function (name, value, options) {
        const domain = getCookieMainDomain();
        originalCookieSet(name, value, { ...options, domain: `${domain}` });
    };
    app.$cookies.remove = function (name, options) {
        const domain = getCookieMainDomain();
        const opts = {
            domain: options?.domain || domain,
            path: options?.path || '/',
            maxAge: 0,
        };
        originalCookieSet(name, '', opts);
    };
}
