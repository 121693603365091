import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ route }, inject) => {
    const device = new Vue({
        data() {
            return {
                deviceWidth: this.getWidth(),
            };
        },
        computed: {
            isMobileDevice() {
                return this.deviceWidth < 768;
            },
        },
        created() {
            // eslint-disable-next-line nuxt/no-globals-in-created
            window.onresize = () => {
                this.deviceWidth = this.getWidth();
            };
        },
        methods: {
            getWidth() {
                return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            },
            isMobile() {
                return this.deviceWidth < 768;
            },
            isIOS() {
                return (
                    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                        navigator.platform
                    ) ||
                    // iPad on iOS 13 detection
                    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
                );
            },
        },
    });
    inject('device', device);
});
