import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app }, inject) => {
    inject(
        'search',
        new Vue({
            data() {
                return {
                    filterTypes: [
                        { type: 'sort', query: 'sort' },
                        { type: 'category', query: 'c' },
                        { type: 'stock', query: 'hoos' },
                    ],
                    sorting: [
                        {
                            query: 'popularity', // query that is send to the backend
                            key: 'popularity', // key for the sorting label
                            dir: 'desc',
                        },
                        {
                            query: 'game.firstReleaseDate',
                            key: 'newest',
                            dir: 'desc',
                        },
                        {
                            query: 'price',
                            key: 'highestPrice',
                            dir: 'desc',
                        },
                        {
                            query: 'price',
                            key: 'lowestPrice',
                            dir: 'asc',
                        },
                    ],
                };
            },
            methods: {
                async getSearchResults(query) {
                    const searchResults = await app.$api.search.searchByQuery(query);
                    searchResults.hits = Object.keys(searchResults.hits).flatMap(key => {
                        return searchResults.hits[key];
                    });
                    return searchResults;
                },
            },
        })
    );
});
