import sentryPlugin from './plugins/sentry';

export const state = () => ({
    version: '0.0.1',
    initialized: false,
});

export const mutations = {
    initialize(state) {
        state.initialized = true;
    },
};

export const plugins = [sentryPlugin];

export const actions = {
    async nuxtServerInit({ dispatch, commit, getters }, ctx) {
        const { app, route, error, redirect, isDev } = ctx;

        const req = ctx.req || { headers: { host: 'dundle.com' } };
        req.headers.host = 'dundle.com';

        if ((isDev || process.env.LOCATION === 'local') && process.env.WEBSITE) {
            req.headers.host = process.env.WEBSITE;
        }

        // set the current occasion
        dispatch('occasion/setCurrentOccasion');

        // const isReturning = !!req.session.initialized && req.session.cart && req.session.cart.items && req.session.cart.items.length;

        // req.session.initialized = true;

        // await dispatch('data/load');
        // await dispatch('data/fetchMethods');

        await app.$locale.initialize({
            req,
            route,
            error,
            redirect,
        });

        app.$locale.applyWebsiteName();

        const currencyFromQuery =
            typeof route.query.currency === 'string' && /[a-zA-Z]{3}/.test(route.query.currency)
                ? this.$locale.getCurrency(route.query.currency.toUpperCase())
                : null;
        const currency = currencyFromQuery || app.$locale.getCurrencyOfRegion(app.$locale.regio)[0];
        const currencyFormat = await app.$api.currency.getCurrencyFormat(app.$locale.iso, currency.code);

        await Promise.all([
            dispatch('occasion/fetchCampaignBrands'),
            // dispatch('session/serverInit'),
            dispatch('translation/fetchFiles', app.$locale.language),

            dispatch('currency/fetchCurrencies'),
            dispatch('currency/setCurrency', currency),
            dispatch('currency/setFormatParts', currencyFormat),

            dispatch('data/fetchCategories'),
            dispatch('data/fetchMethods'),
            dispatch('trustpilot/fetch'),
        ]);

        commit('initialize');

        app.$locale.setGlobal('disclaimerLink', app.$locale.path('legal-disclaimer'));
        app.$locale.setGlobal('termsLink', app.$locale.path('legal-terms-and-conditions'));
        app.$locale.setGlobal('privacyLink', app.$locale.path('legal-privacy'));
        app.$locale.setGlobal('returnLink', app.$locale.path('legal-return-policy'));
        app.$locale.setGlobal('complaintLink', app.$locale.path('legal-complaint-policy'));

        app.$locale.setGlobal('paymentMethodCount', getters['data/getMethods'].length);
    },

    async nuxtClientInit({ dispatch, commit, getters }, context) {
        const ipPromise = this.$api.ip.check();

        // Tim: Als dit werkt kan heel de API call weg:
        const ipCookiePromise = (async () => {
            const country = this.$cookies.get('dundle.session.detectedRegion');

            if (country && /[a-z]{2}/.test(country)) {
                return {
                    region: country,
                };
            }

            return await ipPromise;
        })();

        await dispatch('session/initialize', context);

        const cartLoadPromise = dispatch('cart/initialize', getters['session/cart']);

        const isReturning = getters['session/isReturning'];

        if (isReturning) {
            const language = getters['session/language'];
            const region = getters['session/region'];
            // const currency = getters['session/currency']

            // Redirect to correct url if neccesary:
            if (
                true &&
                (this.$locale.language !== language || this.$locale.region !== region) &&
                context.route.path === '/'
            ) {
                const url = this.$locale.url(this.$locale.reverseRoute(context.route), `${language}-${region}`);

                if (window.location.href !== url) {
                    this.$analytics?.event('locale_force_redirect');
                    return window.location.replace(url);
                }
            }
        }

        await dispatch('handleLocalization', { ipPromise, ipCookiePromise, route: context.route });

        await cartLoadPromise;

        const ip = await ipPromise;

        await dispatch('ip/init', ip);

        /**
         * Initialize Sentry locale scope.
         */
        await this.app.$sentry?.configureScope(scope => {
            scope.setTag('region', this.$locale.region?.toLowerCase());
            scope.setTag('language', this.$locale.language?.toLowerCase());
            scope.setTag('currency', this.$locale.currency?.toLowerCase());
        });
    },

    async handleLocalization({ dispatch, getters }, { ipCookiePromise, route }) {
        if (this.$locale.domain.length === 0) {
            return;
        }

        const region = (await ipCookiePromise).region;

        dispatch('locale/setDetectedRegion', region);

        // Check if user region does not match current region:
        if (this.$locale.regio.toLowerCase() !== region.toLowerCase() && !getters['ui/preventRedirectionHeader']) {
            if (
                this.$locale.supportedRegions.includes(region) &&
                ['index', 'brand', 'brand-giftcard'].includes(this.$locale.reverseRoute(route)?.name)
            ) {
                if (!(typeof navigator.userAgent === 'string' && navigator.userAgent.includes('Googlebot'))) {
                    dispatch('ui/showPopup', {
                        name: 'settings-redirection',
                    });
                    this.$analytics?.event('locale_suggest_region_swap');
                }
            }
        }
    },
};

export const strict = false;
