export const state = () => ({
    products: [],
});

export const mutations = {
    setProducts(state, products) {
        state.products = undefined;
        state.products = products;
    },
};

export const actions = {
    setProducts({ commit }, products) {
        commit('setProducts', products);
    },
};

export const getters = {
    getProducts(state) {
        return state.products;
    },
    getByGiftIndex: state => index => {
        return state.products.find(product => product.getByGiftIndex === index);
    },
};
