import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }, inject) => {
    function formatNumber(
        number,
        currencyFormat,
        rounded,
        displayCurrency,
        currencyDisplay = null,
        currencyAbbreviation
    ) {
        const { decimal, group } = currencyFormat.options;
        // some currencies don't have any decimal sign thats why its fixed to 0
        const [int, fraction = ''] = number.toFixed(rounded || !decimal ? 0 : 2).split('.');
        // add the thousand seperators
        const formattedInteger = int.toString().replace(/\B(?=(\d{3})+(?!\d))/gs, group);
        const formattedNumber = rounded ? `${formattedInteger}` : `${formattedInteger}${decimal || ''}${fraction}`;
        const currency = currencyDisplay || currencyAbbreviation || currencyFormat.options.currency;

        return currencyFormat.template
            .replace('{{currency}}', displayCurrency ? currency : '')
            .replace('{{value}}', formattedNumber);
    }

    inject('formatNumber', function (number, currency, options = {}) {
        const { rounded = false, displayCurrency = true } = options;

        const currencyFormat = (this.$store || this.store).getters['currency/getFormatParts'];

        return formatNumber(
            number,
            currencyFormat,
            rounded,
            displayCurrency,
            currencyFormat.currencyDisplays[currency.toUpperCase()],
            currency.toUpperCase()
        );
    });

    inject('formatCurrency', function (number, options = {}) {
        const { from = 'EUR', rounded = false, displayCurrency = true } = options;

        const exchange = (this.$store || this.store).getters['currency/exchangeCurrency'];
        const currency = (this.$store || this.store).getters['currency/getSelectedCurrency'];
        const currencyFormat = (this.$store || this.store).getters['currency/getFormatParts'];

        const to = options.to || currency;

        number = exchange(from, to) * number;

        return formatNumber(number, currencyFormat, rounded, displayCurrency);
    });

    inject(
        'currency',
        new Vue({
            methods: {
                /**
                 *
                 * Calculate the exchange rate from one currency to another.
                 *
                 * @param {String} currencySource The currency code from which it needs to calculate the rate to
                 * @param {String} currencyTarget The currency code to which it needs to calculate the rate to
                 * @returns {Number} The currency exchange rate
                 */
                exchange(currencySource, currencyTarget) {
                    // Retrieve exchange function from store getter:
                    const exchangeFunction = store.getters['currency/exchangeCurrency'];

                    // Execute and return:
                    return exchangeFunction(currencySource, currencyTarget);
                },

                /**
                 * Calculate the exchange rate from a currency to EUR.
                 *
                 * @param {String} currency The currency code from which it needs to calculate the rate from
                 * @returns {Number} The currency exchange rate
                 */
                exchangeFrom(currency) {
                    return this.exchange(currency, 'EUR');
                },

                /**
                 * Calculate the exchange rate from EUR to a currency.
                 *
                 * @param {String} currency The currency code to which it needs to calculate the rate from
                 * @returns {Number} The currency exchange rate
                 */
                exchangeTo(currency) {
                    return this.exchange('EUR', currency);
                },
            },
        })
    );
});
