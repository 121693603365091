export default context => {
    const { res, isDev, query, app } = context;

    if (process.server && !isDev && !process.static) {
        const httpCaching = [].concat
            .apply(
                [],
                context.route.matched.map(({ components }) => Object.values(components))
            )
            .reduce((httpCaching, component) => {
                if (component.options && component.options.httpCaching) {
                    Object.assign(httpCaching, component.options.httpCaching);
                }

                return httpCaching;
            }, {});

        /**
         * Is a preview of the page being request,
         * e.g. does the user want to specifically
         * bypass the cache?
         */
        const isPreview = query.preview === 'true';

        if (httpCaching.maxAge && !isPreview) {
            res.setHeader('Cache-Control', `s-maxage=${httpCaching.maxAge + Math.floor(Math.random() * 10)}, max-age=0`);
        } else {
            res.setHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
        }
    }
};
