import { sum } from '@dundle/utils/array';
import {
    NEOSURFX_MAX_QUANTITY,
    bundleRulesCanAddProduct,
    discountIsValidForPaysafecard,
    googlePlayRulesCanAddProduct,
    isAllowedToAddNeosurfX,
    isNeosurfX,
    productIsPaysafecard,
} from '@dundle/utils/cart';
import { cartHasGooglePlayEurProduct, getGooglePlayExcludedPaymentMethods } from '../utils/cart/google-play';
export const state = () => ({
    items: [],
    paymentMethod: null,
    discount: {
        value: 0,
    },
    discountCode: '',
    creditCardData: null,
    isLoaded: false,
});
function findIndexGiftProduct(state, gift) {
    return state.items.findIndex(el => el.gift && el.gift === gift);
}

function findIndexNoGiftProduct(state, productId) {
    return state.items.findIndex(el => !el.gift && el.product.productId === productId);
}

export const mutations = {
    setIsLoaded(state) {
        state.isLoaded = true;
    },

    removeGift(state, gift) {
        const giftItem = state.items.find(item => item.gift === gift);
        const additionalItem = state.items.find(item => item.productId === gift.productId);

        if (additionalItem) {
            if (
                additionalItem.quantity < additionalItem.product.stock &&
                additionalItem.quantity < 9 &&
                !additionalItem.gift
            ) {
                additionalItem.quantity++;
                state.items.splice(state.items.indexOf(giftItem), 1);
                return;
            }
        }
        giftItem.gift = undefined;
    },

    createGiftItem(state, gift) {
        let item = state.items.find(item => item.productId === gift.productId && !item.gift);

        if (!item) item = state.items.find(item => item.productId === gift.productId);

        if (item.quantity > 1) {
            item.quantity -= 1;

            if (item.quantity === 0) {
                state.items.splice(state.items.indexOf(item), 1);
            }
        } else {
            state.items.splice(state.items.indexOf(item), 1);
        }
        state.items.push({
            product: item.product,
            productId: item.productId,
            quantity: 1,
            gift,
        });
    },

    setItems(state, items) {
        state.items = undefined;
        state.items = items;
    },

    addItem(state, { product, itemIndex }) {
        if (!bundleRulesCanAddProduct({ cartItems: state.items, product })) {
            return;
        }
        // used to remove/check for paysafecard when discount is added
        if (!discountIsValidForPaysafecard({ discountCode: state.discountCode }) && productIsPaysafecard({ product })) {
            state.discountCode = '';
            state.discount = {
                value: 0,
            };
        }

        const googlePlayRuleResult = googlePlayRulesCanAddProduct({ cartItems: state.items, product });

        if (!googlePlayRuleResult) {
            return;
        }

        // If an item is NOT found:
        if (itemIndex === -1) {
            // Create new item:
            const item = {
                product,
                productId: product.productId,
                quantity: 1,
                gift: undefined,
            };

            // Push to list:
            state.items.push(item);

            this.$analytics.addToCart(item);
        } else if (isNeosurfX(product) && !isAllowedToAddNeosurfX(state.items)) {
            /**
             * Prevent adding NeosurfX items beyond the limit.
             */
        } else {
            // Increase quantity:
            let quantity = state.items[itemIndex].quantity;
            if (quantity < product.stock && quantity < 9) {
                quantity += 1;
                state.items[itemIndex].quantity = quantity;

                this.$analytics.addToCart(state.items[itemIndex]);
            }
        }
    },

    removeItem(state, productIndex) {
        state.items.splice(productIndex, 1);
    },

    setQuantity(state, { product, itemIndex, quantity }) {
        const currentQuantity = state.items[itemIndex].quantity;
        let newQuantity = quantity;

        if (isNeosurfX(product)) {
            // product exception for Neosurf Norway brands
            newQuantity = Math.min(NEOSURFX_MAX_QUANTITY, quantity);
        } else if (newQuantity > 9) {
            // product exception: we only allow 9 of the same product to be sold at a time
            newQuantity = 9;
        }

        if (newQuantity === currentQuantity) {
            return;
        }

        state.items[itemIndex].quantity = newQuantity;

        // Send correct analytics tracking event based on previous vs current quantity
        if (newQuantity < currentQuantity) {
            this.$analytics.removeFromCart(state.items[itemIndex]);
        } else {
            this.$analytics.addToCart(state.items[itemIndex]);
        }
    },

    setDiscount(state, discount) {
        state.discount = discount;
    },

    setPaymentMethod(state, method) {
        state.paymentMethod = method;
    },

    clearCart(state) {
        state.items.splice(0, state.items.length);
        state.discount = 0;
    },

    setDiscountCode(state, discountCode) {
        state.discountCode = discountCode;
    },

    setCreditCardData(state, creditCardData) {
        state.creditCardData = creditCardData;
    },
};

export const actions = {
    async initialize({ commit, dispatch }, cart) {
        if (cart.items && cart.items.length > 0) {
            const completeItem = item =>
                (async () => {
                    let productPromise;

                    if (typeof item.productId !== 'undefined') {
                        productPromise = this.$api.product.getById(item.productId);
                    }

                    return {
                        ...item,
                        product: await productPromise,
                    };
                })();

            const items = await Promise.all(cart.items.map(completeItem));

            // Check stock, update quantity if needed and filter out of stock items
            const filteredItems = items
                .map(item => {
                    return {
                        ...item,
                        quantity: item.product
                            ? item.quantity > item.product.stock
                                ? item.product.stock
                                : item.quantity
                            : 0,
                    };
                })
                .filter(el => el.quantity > 0);

            commit('setItems', filteredItems);
        }

        commit('setIsLoaded');
    },

    syncItems({ state, dispatch }) {
        return dispatch('setItems', state.items);
    },

    async setItems({ commit }, items) {
        // Make sure the session object is initialized:
        await this.$session.waitUntilIsLoaded();

        return await this.$api.cart.update(this.$session.id, {
            couponCode: null,
            items: items.map(item => ({
                productId: item.product.productId,
                quantity: item.quantity,
                gift: item.gift,
            })),
        });
    },

    clearCart({ commit, dispatch }) {
        commit('clearCart');

        return dispatch('syncItems');
    },

    setQuantity({ commit, dispatch, state }, { product, quantity }) {
        const itemIndex = findIndexNoGiftProduct(state, product.productId);
        commit('setQuantity', { product, itemIndex, quantity });

        return dispatch('syncItems');
    },

    removeItem({ state, commit, dispatch }, item) {
        let productIndex;

        if (item.gift) {
            productIndex = findIndexGiftProduct(state, item.gift);
        } else {
            productIndex = findIndexNoGiftProduct(state, item.product.productId);
        }

        this.$analytics.removeFromCart(item);

        commit('removeItem', productIndex);
        return dispatch('syncItems');
    },

    addItem({ state, commit, dispatch }, product) {
        const item = findIndexNoGiftProduct(state, product.productId);

        commit('addItem', { product, itemIndex: item });

        return dispatch('syncItems');
    },

    createGiftItem({ commit, dispatch, state }, gift) {
        commit('createGiftItem', gift);
        return dispatch('syncItems');
    },

    editGiftItem({ commit, dispatch, state }, gift) {
        commit('editGiftItem', { gift });

        return dispatch('syncItems');
    },

    removeGift({ commit, dispatch }, gift) {
        commit('removeGift', gift);
        return dispatch('syncItems');
    },

    setDiscount({ commit }, discount) {
        commit('setDiscount', discount);
    },

    setDiscountCode({ commit }, discountCode) {
        commit('setDiscountCode', discountCode);
    },

    setPaymentMethod({ commit }, method) {
        commit('setPaymentMethod', method);
    },

    setCreditCardData({ commit }, creditCardData) {
        commit('setCreditCardData', creditCardData);
    },
};

export const getters = {
    getPaymentMethod(state) {
        return state.paymentMethod;
    },

    getPaymentMethodId(state) {
        return state.paymentMethod ? state.paymentMethod.id : null;
    },

    getItems(state) {
        return state.items;
    },

    getAvailablePaymentMethods: (state, getters, rootState, rootGetters) => product => {
        // filter methods based on state.items in cart
        // used product param for populating quick checkout button+dropdown

        let subTotal = sum(
            state.items.map(item => {
                return item.product.price * item.quantity;
            })
        );
        const cartItems = state.items.slice();

        if (product) {
            cartItems.push({
                product,
            });

            subTotal += product.price;
        }

        // exclude coinpayments if subTotal < 15 USD
        let coinpaymentsMinimumMet = true;
        const exchangeRate = rootGetters['currency/exchangeCurrency'];
        const currentLocale = rootGetters['locale/currentLocale'];
        const USDTotal = exchangeRate(currentLocale.currency, 'USD') * subTotal;
        if (USDTotal < 15) coinpaymentsMinimumMet = false;

        const paymentMethods = rootGetters['data/getMethods'];
        const googlePlayExcludedPaymentMethods = getGooglePlayExcludedPaymentMethods();

        return paymentMethods.filter(method => {
            if (cartHasGooglePlayEurProduct({ cartItems }) && googlePlayExcludedPaymentMethods.includes(method.id)) {
                return false;
            }

            if (method.id === 'dinrex.coinpayments' && !coinpaymentsMinimumMet) {
                return false;
            }

            // Process maximumAmount:
            if (method.maximumAmount) {
                /*
                if (method.transactionCosts.percentage) {
                    subTotal += subTotal * method.transactionCosts.percentage / 100;
                }

                if (method.transactionCosts.amount) {
                    subTotal += method.transactionCosts.amount;
                }
                */
                if (subTotal >= method.maximumAmount) {
                    return false;
                }
            }

            // Process excludedBrands:
            if (method.excludedBrands) {
                if (
                    cartItems.find(item => {
                        return method.excludedBrands.includes(item.product.brand.slug);
                    })
                ) {
                    return false;
                }
            }

            // Disable PayPal and Creditcard if the cart contains Open Loop vouchers:
            if (
                method.id === 'paypal' ||
                method.id === 'payvision.creditcard' ||
                method.id === 'dinrex.emerchantpay.creditcard'
            ) {
                const openLoopBrands = [
                    'skrill', // Keep
                    'perx', // Keep
                    'bitsa', // Keep
                    'vanilla', // Keep
                    'bitnovo', // Keep
                    'visa-mastercard', // Keep
                    'cryptovoucher', // Keep
                    'paypal', // Keep
                    'amex', // Keep
                    'prepaid-mastercard', // Keep
                    'keplerk',
                ];

                const containsOpenLoop = cartItems.find(item => {
                    return openLoopBrands.includes(item.product.brand.slug);
                });

                if (containsOpenLoop) {
                    return false;
                }
            }

            // Norway payment providers
            let allowedByNorwegianPaymentProviders = true;
            if (currentLocale.regio === 'NO') {
                if (method.id === 'onebip' || method.id === 'fortumo') {
                    cartItems.forEach(item => {
                        if (['astropay', 'jeton-cash', 'paysafecard'].includes(item.product.brand.slug)) {
                            allowedByNorwegianPaymentProviders = false;
                        }
                    });
                }
                return allowedByNorwegianPaymentProviders;
            }

            return true;
        });
    },

    getDiscountCode(state) {
        return state.discountCode;
    },

    getDiscount(state) {
        return state.discount;
    },

    getDiscountIsValid(state) {
        if (
            (state.discount.value && state.discount.value !== 0) ||
            (state.discount.percentage && state.discount.percentage !== 0)
        ) {
            return true;
        }
        return false;
    },

    getCreditCardData(state) {
        return state.creditCardData;
    },

    isLoaded(state) {
        return state.isLoaded;
    },
    getGiftItem: state => id => {
        return state.items.find(item => item.productId === id && item.gift).gift;
    },
};
