import createActionsApi from './actions';
import createAddressAPI from './address';
import createAuthAPI from './auth';
import createCartAPI from './cart';
import createGiftAPI from './gift';
import createOrderAPI from './order';
import createPagesAPI from './pages';
import createPaymentMethodAPI from './paymentMethod';
import createSearchAPI from './search';
import createSessionAPI from './session';
import createUserAPI from './user';
import createExchangeRatesAPI from './exchangeRates';

export default options => ({
    actions: createActionsApi(options),
    address: createAddressAPI(options),
    auth: createAuthAPI(options),
    cart: createCartAPI(options),
    gift: createGiftAPI(options),
    order: createOrderAPI(options),
    pages: createPagesAPI(options),
    paymentMethod: createPaymentMethodAPI(options),
    search: createSearchAPI(options),
    session: createSessionAPI(options),
    user: createUserAPI(options),
    exchangeRates: createExchangeRatesAPI(options),
});
