import { getJSON, patchJSON } from "../utils/json";

export default ({ baseURL }) => {
    return {
        async getById(id) {
            return (await getJSON(`${baseURL}/sessions/${id}/cart`)).cart;
        },

        async update(id, cart) {
            return (await patchJSON(`${baseURL}/sessions/${id}/cart`, { cart }))
                .cart;
        },
    };
};
