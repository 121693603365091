export const state = () => ({
    categories: [
        { slug: 'paymentcards', image: 'payment-cards', colour: '#1b2023' },
        { slug: 'giftcards', image: 'gift-cards', colour: '#007dd3' },
        { slug: 'gamecards', image: 'gaming-credits', colour: '#facd04' },
        { slug: 'prepaid', image: 'mobile-recharge', colour: '#875288' },
    ],
    activeCategories: [],
    /**
     * Array of payment methods available in the CDN
     * https://cdn.dundle.dev/payment-methods/nl.json
     * {
     *      id: string;
     *      description: string;
     *      category: string;
     *      transactionCosts: {
     *          amount: number;
     *          percentage: number;
     *      }
     *      supportedCurrencies: string[];
     *      excludedBrands: string[];
     *      isActive: boolean;
     * }
     */
    methods: [],
    fallbackMethod: {
        id: '',
        description: '',
        category: '',
        transactionCosts: {
            amount: 0.15,
            percentage: 0,
        },
        supportedCurrencies: [],
        excludedBrands: [],
        isActive: true,
    },
    totalCoins: 0,
});

export const mutations = {
    setMethods(state, methods) {
        state.methods.splice(0, state.methods.length);

        methods.forEach(method => state.methods.push(method));
    },

    setActiveCategories(state, categories) {
        state.activeCategories = categories;
    },

    setTotalCoins(state, coins) {
        state.totalCoins = coins;
    },
};

export const actions = {
    load({ state }) {
        state.products = [];
    },

    async fetchCategories({ commit, state }) {
        const categories = await this.$api.brand.getCategoriesForLocale();
        commit('setActiveCategories', categories);
    },

    async fetchMethods({ commit }) {
        const methods = await this.$api.paymentMethod.getAll();

        commit('setMethods', methods);
    },

    updateTotalCoins({ commit }, coins) {
        commit('setTotalCoins', coins);
    },
};

export const getters = {
    getFallbackMethod(state) {
        return state.fallbackMethod;
    },

    getMethods(state) {
        return state.methods.filter(method => method.id !== 'test');
    },

    getActiveCategories(state) {
        return state.activeCategories;
    },

    getCategories(state) {
        return state.categories;
    },
    getCategoryImage: state => category => {
        const selected = state.categories.find(stateCategory => {
            return stateCategory.slug === category;
        });
        return `images/home-page/categories/category-${selected.image}.png`;
    },
    getCategoryColour: state => category => {
        const selected = state.categories.find(stateCategory => {
            return stateCategory.slug === category;
        });
        return selected.colour;
    },
    getTotalCoins(state) {
        return state.totalCoins;
    },
};
