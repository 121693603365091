import { getJSON } from '../utils/json';

export default ({ strapiBaseURL, providers }) => {
    return {
        async getArticleByBrand(slug = null, locale = providers.language(), limit = 2) {
            return getJSON(`${strapiBaseURL}/articles/related/brand?slug=${slug}&locale=${locale}&limit=${limit}`);
        },
        async getLatestArticles(locale = providers.language(), limit = 2) {
            return getJSON(`${strapiBaseURL}/articles/?_locale=${locale}&_limit=${limit}`);
        },
    };
};
