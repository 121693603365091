export default {
    orderId: 'b9045c45-0043-4497-b992-57d5323700c6',
    transaction: {
        transactionId: '1858864902X32d38',
        name: '',
        email: 'npc@example.com',
        verificationEmail: 'npc@example.com',
        method: 'sofort',
        currency: 'EUR',
        amount: 54,
        products: [
            {
                productId: 'paysafecard-50-eur-de',
                code: '',
                imageURL: 'http://korsit.com/webapi/v3/payment/smallImage/null',
                priceInEur: 52.5,
            },
        ],
        meta: '{"trxFeeExperimentAmount":0.18,"vatRegionSetting":"de","ipRegion":"de","fp1":"Y5z7z18KJWWnNwE8T5Is","utm":{"source":"google","medium":"cpc","referrer":"https://www.google.com/"},"cid":"277546403.1650733496","website":"dundle.com","sessionId":"9c2e564a-d327-490b-8585-a7268809535d","user":{"id":"f51d7988-3e9d-4f6a-b2b3-a631d11be79b"},"orderId":"b9045c45-0043-4497-b992-57d5323700c6","enableCreditCardCheck":true,"strategy":"guest","creditCardData":{"sessionUUID":"9c2e564a-d327-490b-8585-a7268809535d"},"ipblocktype":"0","trxFeeExperimentOriginalAmount":1.5025,"cancelUrl":"https://dundle.com/de/bezahlen/","returnUrl":"https://dundle.com/de/order/b9045c45-0043-4497-b992-57d5323700c6/?email\\u003dfrederikstueve%40outlook.de","7DayCorrelations":1,"emailhippo":8.0,"paytrx":"1858864902X32d38","scoreorder":27.415361045412386,"blockAutoRefund":true,"hasSofortUnpaid":"1841392722X857b7, ","endUserFirstName":"Frederik","endUserLastName":"Stüve","phone":"+491735607652","endUserAddressCity":"Werther","endUserCountryCode":"de","hasRulesValidated":"true","profit":0}',
        status: 'Review',
        customerPoints: 30.86,
        dundleCoins: 13443,
        emailDeliveryOnly: false,
        platform: 'WEB',
        orderDate: 1662098913000,
        orderLastUpdatedDate: 1662117991000,
    },
    products: [
        {
            id: 6108,
            createdAt: '2020-02-20T08:04:41.244Z',
            updatedAt: '2021-11-29T10:00:13.000Z',
            deletedAt: null,
            productId: 'paysafecard-50-eur-de',
            productTitle: null,
            price: 52.5,
            faceValue: 50,
            isVat: false,
            currencyCode: 'eur',
            type: 'region',
            stock: 1,
            imageName: 'paysafecard',
            regionCode: 'de',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: false,
            ean13: '9120005811096',
            gtin: null,
            parentId: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 23490,
            dundleCoinsRewarded: 338,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 55,
                createdAt: '2019-08-02T11:04:48.544Z',
                updatedAt: '2022-05-17T20:18:22.000Z',
                deletedAt: null,
                name: 'paysafecard',
                primaryTags: ['psc'],
                secondaryTags: null,
                slug: 'paysafecard',
                color: '#3200FF',
                parentId: null,
                productMatchers: ['paysafecard-.*'],
                excludedRegions: null,
                availableInRegions: [
                    'us',
                    'au',
                    'it',
                    'pl',
                    'es',
                    'pt',
                    'ie',
                    'ca',
                    'gb',
                    'nl',
                    'fr',
                    'de',
                    'at',
                    'be',
                    'ch',
                    'lu',
                    'tr',
                    'cz',
                    'se',
                    'sk',
                    'no',
                    'fi',
                    'bg',
                    'hu',
                    'ro',
                    'mt',
                    'lt',
                    'lv',
                    'dk',
                    'br',
                    'ae',
                ],
                availableInLanguages: [
                    'en',
                    'it',
                    'pl',
                    'fr',
                    'nl',
                    'de',
                    'es',
                    'pt',
                    'tr',
                    'fi',
                    'sv',
                    'cs',
                    'da',
                    'sk',
                    'nb',
                    'ro',
                    'hu',
                    'bg',
                    'ru',
                ],
                importKey: 'paysafecard',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: 79,
                defaultCurrency: null,
                spotlightRegions: ['ae', 'br', 'no'],
                categories: 'paymentcards',
                game: null,
                redeemInstructions:
                    '<h2>Wie funktioniert bezahlen mit paysafecard?</h2><p><strong>Über 50 €:</strong></p><ol><li class="inline-list-item"><p>Registrieren Sie den Code, den Sie von uns erhalten haben, auf Ihrem <em><a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://login.paysafecard.com/customer-auth/?client_id=">my paysafecard</a></em><a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://login.paysafecard.com/customer-auth/?client_id="> Konto</a>. </p></li><li class="inline-list-item"><p>Besuchen Sie eine Webseite, die paysafecard akzeptiert.</p></li><li class="inline-list-item"><p>Wählen Sie beim Bezahlen “paysafecard” als Zahlungsmittel.</p></li><li class="inline-list-item"><p>Melden Sie sich mit Ihrem <em>my paysafecard-</em>Konto an, um mit Ihrem Guthaben zu bezahlen.</p></li></ol><p><strong>Unter 50 €: </strong></p><ol><li class="inline-list-item"><p>Gehen Sie zu einem Online-Shop, der paysafecard akzeptiert.</p></li><li class="inline-list-item"><p>Wählen Sie beim Bezahlen “paysafecard” als Zahlungsmittel.</p></li><li class="inline-list-item"><p>Führen Sie den 16-stelligen Code ein, um damit zu bezahlen.</p></li></ol><p><strong>Gültigkeit: </strong>Der Gebrauch Ihrer paysafecard ist gratis bis 30 Tagen nach dem Erwerb. Nach 30 Tagen werden Ihnen 3 € pro Monat abgezogen. Um dies zu vermeiden, können Sie Ihren psc-Code auf Ihrem paysafecard-Konto einlösen. Da fallen erst nach einem Jahr monatliche Gebühren an. Mehr darüber lesen Sie in den <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://www.paysafecard.com/de/agb/?country=de">AGB von paysafecard</a>.</p>',
                redeemUrl: 'https://www.paysafecard.com/de/guthabenabfrage/',
                category: 'paymentcards',
                productName: {
                    id: 1785,
                    createdAt: '2019-08-02T11:04:48.899Z',
                    updatedAt: '2020-10-09T13:33:49.000Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'paysafecard {value} ',
                    type: 'brand',
                    region: null,
                },
            },
        },
    ],
    rules: [
        { name: 'hasName', passes: true },
        { name: 'hasDateOfBirth', passes: true },
        { name: 'hasAddress', passes: true },
        { name: 'hasPhoneNumber', passes: true },
        { name: 'hasVerifiedPhoneNumber', passes: true },
        { name: 'phoneNumberMatchesPaysafecardRegion', region: 'de', passes: true },
        { name: 'addressCountryMatchesPaysafecardRegion', region: 'de', passes: true },
    ],
    user: {
        id: 'f51d7988-3e9d-4f6a-b2b3-a631d11be79b',
        userId: 'f51d7988-3e9d-4f6a-b2b3-a631d11be79b',
        email: 'npc@example.com',
        createdAt: '2020-05-22T09:16:13.666Z',
        type: 'user',
        loyaltyPoints: 0,
        verified: { email: false, mobilePhone: true },
        region: 'de',
        language: 'de',
        currency: 'EUR',
        _rid: 'oidsAPvhsfpI96UAAAAAAA==',
        _self: 'dbs/oidsAA==/colls/oidsAPvhsfo=/docs/oidsAPvhsfpI96UAAAAAAA==/',
        _etag: '"b503252a-0000-0d00-0000-63119e080000"',
        _attachments: 'attachments/',
        domain: 'dundle.com',
        firstName: 'Frederik',
        lastName: 'Stüve',
        address: {
            value: 'Jöllenbäcker str 5d, Werther, 33824, de',
            type: 'paypal',
            countryCode: 'de',
            city: 'Werther',
            result: { line1: 'Jöllenbäcker str 5d', city: 'Werther', zip: '33824' },
        },
        mobilePhone: '+491735607652',
        dateOfBirth: '1998-04-08T00:00:00.000Z',
        secureToken: '108c866e-d03e-4d04-9ab3-21a931e6b319',
        _ts: 1662098952,
    },
    isValidated: true,
    paymentUrl:
        'https://api.pay.nl/controllers/payments/issuer.php?orderId=1858864902X32d38&entranceCode=fbdb40b23b1c2eec5af51471cd7e6e4fdb2e791f&profileID=559',
    gifts: [],
    patch: true,
    isCreditCard: false,
};
