export const state = () => ({
    verification: null,
    verificationResult: null,
});

export const actions = {
    /**
     * Fetch existing verification from the server if it exists.
     */
    async initialize({ dispatch }) {
        const idFromCookie = this.$cookies.get(cookieName);
        if (idFromCookie) {
            await dispatch('getSmsVerification', idFromCookie);
        }
    },
    /**
     * Attempt to verify the current verification
     * using the given OTP.
     *
     * @param {*} otp
     */
    async attemptVerification({ commit, state }, otp) {
        const result = await this.$api.verification.attemptVerification(
            this.$auth.user.userId,
            state.verification.id,
            otp
        );
        if (result && result.success) {
            this.$cookies.set(cookieName, null);
        }
        commit('setVerificationResult', result);
    },

    /**
     * Requests a new SMS verification code.
     *
     * @param phoneNumber
     */
    async requestSmsVerification({ commit }, { phoneNumber, token }) {
        const verification = await this.$api.verification.requestSmsVerification(
            this.$auth.user.userId,
            phoneNumber,
            token
        );
        if (verification) {
            commit('setVerification', verification);
            this.$cookies.set(cookieName, verification.id);
        }
    },
    /**
     * Fetch an existing SMS Verification using a verification ID.
     *
     * @param {*} verificationId
     */
    async getSmsVerification({ commit }, verificationId) {
        try {
            const verification = await this.$api.verification.getSmsVerification(
                this.$auth.user.userId,
                verificationId
            );
            commit('setVerification', verification);
        } catch (error) {
            this.$cookies.set(cookieName, null);
        }
    },
};

export const mutations = {
    setVerification(state, verification) {
        state.verification = verification;
    },
    setVerificationResult(state, verificationResult) {
        state.verificationResult = verificationResult;
    },
};

export const getters = {
    getVerification(state) {
        return state.verification;
    },
    getVerificationResult(state) {
        return state.verificationResult;
    },
    getVerificationId(state) {
        return state.verification ? state.verification.id : null;
    },
    getExpiryDate(state) {
        return state.verification ? state.verification.expiryDate : null;
    },
    getIsExpired(state) {
        return state.verification ? isExpired(state.verification.expiryDate) : null;
    },
    getIsSuccess(state) {
        return state.verificationResult ? state.verificationResult.success : null;
    },
};

function isExpired(expiryDate) {
    return new Date(expiryDate) < new Date();
}

const cookieName = 'dundle.verification.id';
