import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
// import { fas } from '@fortawesome/free-solid-svg-icons';
import {
    faArrowRight as fasArrowRight,
    faBarcodeAlt as fasBarcodeAlt,
    faBold as fasBold,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faChevronDown as fasChevronDown,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faChevronUp as fasChevronUp,
    faCircle as fasCircle,
    faClock as fasClock,
    faCommentDots as fasCommentDots,
    faComments as fasComments,
    faCreditCard as fasCreditCard,
    faCreditCardFront as fasCreditCardFront,
    faEnvelope as fasEnvelope,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faEye as fasEye,
    faFilePdf as fasFilePdf,
    faFilter as fasFilter,
    faGamepad as fasGamepad,
    faGamepadAlt as fasGamepadAlt,
    faGift as fasGift,
    faGiftCard as fasGiftCard,
    faHeadset as fasHeadset,
    faHome as fasHome,
    faIdCard as fasIdCard,
    faInfoCircle as fasInfoCircle,
    faItalic as fasItalic,
    faLink as fasLink,
    faListOl as fasListOl,
    faListUl as fasListUl,
    faLockAlt as fasLockAlt,
    faMapMarkerAlt as fasMapMarkerAlt,
    faMinusCircle as fasMinusCircle,
    faNewspaper as fasNewspaper,
    faPhoneAlt as fasPhoneAlt,
    faPlusCircle as fasPlusCircle,
    faQuestionCircle as fasQuestionCircle,
    faQuoteLeft as fasQuoteLeft,
    faQuoteRight as fasQuoteRight,
    faShareAlt as fasShareAlt,
    faShoppingBag as fasShoppingBag,
    faShoppingCart as fasShoppingCart,
    faStar as fasStar,
    faSyncAlt as fasSyncAlt,
    faTimesCircle as fasTimesCircle,
    faTrash as fasTrash,
    faTvMusic as fasTvMusic,
    faUndoAlt as fasUndoAlt,
    faUser as fasUser,
    faUserCircle as fasUserCircle,
    faUserShield as fasUserShield,
    faWallet as fasWallet,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faArrowDown as falArrowDown,
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowUp as falArrowUp,
    faBars as falBars,
    faBoxes as falBoxes,
    faChevronDoubleRight as falChevronDoubleRight,
    faChevronDown as falChevronDown,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faChevronUp as falChevronUp,
    faCog as falCog,
    faCommentSlash as falCommentSlash,
    faCopy as falCopy,
    faCreditCard as falCreditCard,
    faEdit as falEdit,
    faGamepadAlt as falGamepadAlt,
    faGift as falGift,
    faGingerbreadMan as falGingerBreadMan,
    faHeadset as falHeadset,
    faHome as falHome,
    faLock as falLock,
    faNewspaper as falNewspaper,
    faOrnament as falOrnament,
    faPlus as falPlus,
    faPlusCircle as falPlusCircle,
    faSearch as falSearch,
    faShoppingCart as falShoppingCart,
    faStocking as falStocking,
    faTimes as falTimes,
    faUserCircle as falUserCircle,
} from '@fortawesome/pro-light-svg-icons';

import {
    faCheckCircle,
    faArrowRight as farArrowRight,
    faBox as farBox,
    faCheckCircle as farCheckCircle,
    faChevronDown as farChevronDown,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faCircle as farCircle,
    faCopy as farCopy,
    faLockAlt as farLockAlt,
    faSearch as farSearch,
    faSearchPlus as farSearchPlus,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faFacebookF,
    faFacebookMessenger,
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faLinkedinIn,
    faTwitter,
    faTwitterSquare,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

// import { faCheckCircle } from '@dundle/custom-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.

library.add(
    farLockAlt,
    fasShoppingCart,
    fasHeadset,
    fasComments,
    fasGift,
    fasChevronUp,
    fasChevronRight,
    fasChevronDown,
    fasChevronLeft,
    fasCheck,
    fasCheckCircle,
    fasStar,
    fasCircle,
    fasEnvelope,
    fasInfoCircle,
    fasBold,
    fasItalic,
    fasListUl,
    fasListOl,
    fasExclamationTriangle,
    fasExclamationCircle,
    fasLink,
    fasHome,
    fasFilter,
    fasWallet,
    fasQuestionCircle,
    fasMinusCircle,
    fasQuoteLeft,
    fasQuoteRight,
    fasLockAlt,
    fasPhoneAlt,
    fasNewspaper,
    fasGamepadAlt,
    fasCreditCardFront,
    fasGiftCard,
    falOrnament,
    falStocking,
    falGingerBreadMan,
    fasCreditCard,
    fasEye,
    fasShareAlt,
    fasClock,
    fasTimesCircle,
    fasGamepad,
    fasShoppingBag,
    fasTvMusic,
    fasSyncAlt,
    fasUserCircle,
    fasUser,
    fasMapMarkerAlt,
    fasBarcodeAlt,
    fasUndoAlt,
    fasUserShield,
    fasCommentDots,
    fasPlusCircle,
    falShoppingCart,
    falCommentSlash,
    falHeadset,
    falBars,
    falGift,
    falChevronUp,
    falChevronRight,
    falChevronDown,
    falChevronLeft,
    falArrowUp,
    falArrowRight,
    falArrowDown,
    falArrowLeft,
    falEdit,
    falCopy,
    falPlus,
    falTimes,
    falSearch,
    falLock,
    falCog,
    falCreditCard,
    falHome,
    falChevronDoubleRight,
    falBoxes,
    falUserCircle,
    falNewspaper,
    falGamepadAlt,
    farSearch,
    farSearchPlus,
    farCheckCircle,
    farChevronLeft,
    farChevronRight,
    farChevronDown,
    farArrowRight,
    farCopy,
    farCircle,
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faFacebookMessenger,
    faTwitterSquare,
    faLinkedinIn,
    faWhatsapp,
    faFacebookF,
    faTwitter,
    faCheckCircle,
    falPlusCircle,
    fasArrowRight,
    fasTrash,
    fasIdCard,
    fasFilePdf,
    farBox
);

// Register the component globally
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
