export const state = () => ({
    gift: {
        message: '',
        productId: '',
        senderName: '',
        templateId: '',
        index: null,
    },
    themes: ['christmas', 'valentine', 'general', 'birthday', 'halloween', 'sinterklaas'],
    // The length of the template name cannot exceed 24 characters.
    templates: [
        {
            theme: 'christmas',
            name: 'christmas1',
            thumbnail: 'images/gift/images/christmas-1-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas2',
            thumbnail: 'images/gift/images/christmas-2-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas3',
            thumbnail: 'images/gift/images/christmas-3-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas4',
            thumbnail: 'images/gift/images/christmas-4-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas5',
            thumbnail: 'images/gift/images/christmas-5-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas6',
            thumbnail: 'images/gift/images/christmas-6-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas7',
            thumbnail: 'images/gift/images/christmas-7-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas8',
            thumbnail: 'images/gift/images/christmas-8-thumbnail.png',
        },
        {
            theme: 'christmas',
            name: 'christmas9',
            thumbnail: 'images/gift/images/christmas-9-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine1',
            thumbnail: 'images/gift/images/valentine-1-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine2',
            thumbnail: 'images/gift/images/valentine-2-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine3',
            thumbnail: 'images/gift/images/valentine-3-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine4',
            thumbnail: 'images/gift/images/valentine-4-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine5',
            thumbnail: 'images/gift/images/valentine-5-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine6',
            thumbnail: 'images/gift/images/valentine-6-thumbnail.png',
        },
        {
            theme: 'valentine',
            name: 'valentine7',
            thumbnail: 'images/gift/images/valentine-7-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic1',
            thumbnail: 'images/gift/images/general-1-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic2',
            thumbnail: 'images/gift/images/general-2-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic3',
            thumbnail: 'images/gift/images/general-3-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic4',
            thumbnail: 'images/gift/images/general-4-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic5',
            thumbnail: 'images/gift/images/general-5-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic6',
            thumbnail: 'images/gift/images/general-6-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic7',
            thumbnail: 'images/gift/images/general-7-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic8',
            thumbnail: 'images/gift/images/general-8-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic9',
            thumbnail: 'images/gift/images/general-9-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic10',
            thumbnail: 'images/gift/images/general-10-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic11',
            thumbnail: 'images/gift/images/general-11-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic12',
            thumbnail: 'images/gift/images/general-12-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic13',
            thumbnail: 'images/gift/images/general-13-thumbnail.png',
        },
        {
            theme: 'general',
            name: 'generic14',
            thumbnail: 'images/gift/images/general-14-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday1',
            thumbnail: 'images/gift/images/birthday-1-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday2',
            thumbnail: 'images/gift/images/birthday-2-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday3',
            thumbnail: 'images/gift/images/birthday-3-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday4',
            thumbnail: 'images/gift/images/birthday-4-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday5',
            thumbnail: 'images/gift/images/birthday-5-thumbnail.png',
        },
        {
            theme: 'birthday',
            name: 'birthday6',
            thumbnail: 'images/gift/images/birthday-6-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween1',
            thumbnail: 'images/gift/images/halloween-1-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween2',
            thumbnail: 'images/gift/images/halloween-2-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween3',
            thumbnail: 'images/gift/images/halloween-3-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween4',
            thumbnail: 'images/gift/images/halloween-4-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween5',
            thumbnail: 'images/gift/images/halloween-5-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween6',
            thumbnail: 'images/gift/images/halloween-6-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween7',
            thumbnail: 'images/gift/images/halloween-7-thumbnail.png',
        },
        {
            theme: 'halloween',
            name: 'halloween8',
            thumbnail: 'images/gift/images/halloween-8-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas1',
            thumbnail: 'images/gift/images/sinterklaas-1-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas2',
            thumbnail: 'images/gift/images/sinterklaas-2-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas3',
            thumbnail: 'images/gift/images/sinterklaas-3-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas4',
            thumbnail: 'images/gift/images/sinterklaas-4-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas5',
            thumbnail: 'images/gift/images/sinterklaas-5-thumbnail.png',
        },
        {
            theme: 'sinterklaas',
            name: 'Sinterklaas6',
            thumbnail: 'images/gift/images/sinterklaas-6-thumbnail.png',
        },
    ],
});

export const mutations = {
    createGift(state, { productId, index = null }) {
        state.gift.productId = productId;
        state.gift.index = index;
    },
    clearGift(state) {
        state.gift = {
            message: '',
            productId: '',
            templateId: '',
        };
    },
    addTemplate(state, templateId) {
        state.gift.templateId = templateId;
    },
    addMessage(state, message) {
        state.gift.message = message;
    },
    editGift(state, gift) {
        state.gift = gift;
    },
};

export const actions = {
    createGift({ commit }, { productId, index = null }) {
        commit('createGift', { productId, index });
    },
    clearGift({ commit }) {
        commit('clearGift');
    },
    addTemplate({ commit }, templateId) {
        commit('addTemplate', templateId);
    },
    addMessage({ commit }, message) {
        commit('addMessage', message);
    },
    editGift({ commit }, gift) {
        commit('editGift', gift);
    },
};

export const getters = {
    getGift(state) {
        return state;
    },
    getMessage(state) {
        return state.gift.message;
    },
    getThemes(state) {
        return state.themes;
    },
    getTemplates(state) {
        return state.templates;
    },
    getTheme: state => themeName => {
        const thumbnailSrc = state.templates.find(t => {
            return t.name === themeName;
        });
        return thumbnailSrc?.thumbnail;
    },
};
