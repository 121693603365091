<template class="ui-button">
    <UIRipple
        v-if="ripple"
        color="black"
        :to="to"
        :tag="componentTag"
        class="ui-button"
        :class="[variant, { loading: isLoading, 'loading-indicator': showLoadingIndicator }, size]"
        :variant="variant"
        v-bind="$attrs"
        :disabled="disabled || isLoading"
        @touchstart.native.stop=""
        @mousedown.native.stop=""
        @click.native="onClick"
        v-on="$listeners"
    >
        <UILoadingIndicator v-if="showLoadingIndicator" />
        <slot />
    </UIRipple>
    <component
        :is="componentTag"
        v-else
        color="black"
        :to="to"
        :tag="componentTag"
        class="ui-button"
        :class="[variant, { loading: isLoading }]"
        :variant="variant"
        v-bind="$attrs"
        :disabled="disabled || isLoading"
        @touchstart.stop=""
        @mousedown.stop=""
        @click="onClick"
        v-on="$listeners"
    >
        <UILoadingIndicator v-if="showLoadingIndicator" />
        <slot />
    </component>
</template>

<script>
import { UIRipple, UILoadingIndicator } from '@dundle/ui/components';

export default {
    components: {
        UIRipple,
        UILoadingIndicator,
    },

    props: {
        variant: {
            type: String,
            default: 'default',
            validator: value => {
                return [
                    'default',
                    'primary',
                    'secondary',
                    'loyalty',
                    'loyalty-minimal',
                    'blackfriday',
                    'google',
                    'facebook',
                    'twitter',
                    'linkedin',
                    'whatsapp',
                    'paypal',
                ].includes(value);
            },
        },

        tag: {
            type: String,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        to: {
            required: false,
            default: null,
        },

        ripple: {
            default: true,
        },

        size: {
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            showLoadingIndicator: false,
        };
    },

    computed: {
        componentTag() {
            if (this.tag) {
                return this.tag;
            }

            if (this.to) {
                return 'nuxt-link';
            }

            return 'button';
        },
    },

    methods: {
        async onClick(ev) {
            try {
                this.startLoading();

                if (this.$listeners.click) {
                    await this.$listeners.click(ev);
                }
            } catch (error) {
                console.error(error);
            }

            this.stopLoading();
        },

        startLoading() {
            this.isLoading = true;

            const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1000));

            timeoutPromise.then(() => {
                if (this.isLoading) {
                    this.showLoadingIndicator = true;
                }
            });
        },

        stopLoading() {
            this.isLoading = false;
            this.showLoadingIndicator = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-button {
    display: inline-block;
    border-radius: 3px;
    font-weight: bold;
    outline: none;
    padding: 0.5rem 1rem;
    line-height: 28px;
    text-align: center !important;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    &[disabled]:not(.loading) {
        cursor: not-allowed;
    }
    &.small {
        height: auto;
        padding: 5px 10px;
        line-height: 1.1rem;
        font-size: 0.85rem;
        border-radius: 2px;

        &:active {
            padding: 6px 11px !important;
        }
    }

    &.default {
        color: $color-primary !important;
        background: $color-button;
        border: 1px solid $color-button-border;

        &:hover,
        &:focus {
            background: $color-button-hover;
            border: 1px solid $color-button-hover-border;
        }

        &.loading {
            ::v-deep .spinner {
                color: $color-primary !important;
            }
        }

        &[disabled] {
            color: rgba($color-grey-4, 0.7) !important;
            border-color: rgba($color-button-border, 0.5);
            background: $color-button;
            font-weight: normal;
        }

        &:active {
            border-color: $color-button-hover-border;
            background: darken($color-button-hover, 5%);
        }
    }

    &.primary {
        color: $color-button-text;
        border: none;
        background-color: $color-button-primary;
        border-bottom: 2px solid $color-button-primary-border;

        &:hover,
        &:focus {
            background: $color-button-primary-hover;
            border-color: transparent;

            &.small {
                padding: 5px 10px !important;
            }
        }

        &[disabled] {
            border-color: transparent;
            background: rgba($color-button-primary, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem);

            &.small {
                padding: 6px 10px !important;
            }
        }
    }

    &.loyalty {
        color: $color-button-text;
        border: none;
        background: linear-gradient(180deg, rgba(255, 202, 0, 1) 0%, rgba(249, 157, 0, 1) 100%);
        border: 1px solid rgba(249, 157, 0, 1);
        overflow: initial;
        line-height: 2.25rem;

        @media screen and (min-width: 768px) {
            &::before {
                content: '';
                bottom: -1.33rem;
                left: -0.66rem;
                background: url('https://images.dundle.com/resources/images/loyalty/coins-medium.svg');
                width: 51px;
                height: 55px;
                position: absolute;
                z-index: 1;
            }
        }

        &:not([disabled])::after {
            content: '';
            top: calc(50% - 12px);
            right: 0.66rem;
            background: url('https://images.dundle.com/resources/images/loyalty/sparkle-white.svg');
            width: 25px;
            height: 25px;
            position: absolute;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        &:hover,
        &:focus {
            border: 1px solid rgba(234, 119, 0, 1);

            &::after {
                opacity: 1;
            }
        }

        &[disabled] {
            color: rgba($color-grey-4, 0.7) !important;
            border: 1px solid rgba(245, 180, 59, 0.5);
            background: $color-button;
        }
    }

    &.loyalty-minimal {
        color: $color-grey-9;
        background-color: #ffcc00;
        border: none;
        min-height: 3rem;
        border-bottom: 2px solid #ed811c;

        &:hover,
        &:focus {
            background-color: #ffcc00d7;

            &::after {
                opacity: 1;
            }
        }
    }

    &.secondary {
        color: $color-button-text;
        border: none;
        background-color: $color-button-secondary;
        border: 1px solid $color-button-secondary-border;

        &:hover,
        &:focus {
            background: $color-button-secondary-hover;
        }

        &[disabled] {
            border-color: transparent;
            background: rgba($color-button-secondary, 0.5);
        }

        &:active {
            border: none;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.blackfriday {
        color: $blackfriday-primary;
        border: none;
        background-color: $blackfriday-black;
        border: 1px solid $color-grey-8;
        width: 100%;

        &:hover,
        &:focus {
            background: black;
        }

        &[disabled] {
            border: 1px solid $color-grey-3;
            background-color: white;
            color: $color-grey-4;
        }

        &:active {
            border: none;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.succeed {
        color: $color-text-inverted;
        border: none;
        background-color: $color-validation;
        border: 1px solid darken($color-validation, 10%);

        &:hover {
            background: darken($color-validation, 5%);
        }

        &[disabled] {
            background: rgba($color-validation, 0.5);
        }
    }

    &.google {
        color: $color-button-text;
        border: none;
        background-color: #4285f4;
        border: 1px solid #4285f4;

        &:hover,
        &:focus {
            background: darken(#4285f4, 10%);
        }

        &[disabled] {
            border-color: transparent;
            background: rgba(#4285f4, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.facebook {
        color: $color-button-text;
        border: none;
        background-color: #3b5998;
        border: 1px solid #3b5998;

        &:hover,
        &:focus {
            background: darken(#3b5998, 10%);
        }

        &[disabled] {
            border-color: transparent;
            background: rgba(#3b5998, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.twitter {
        color: $color-button-text;
        border: none;
        background-color: #1da1f2;
        border: 1px solid #1da1f2;

        &:hover,
        &:focus {
            background: darken(#1da1f2, 10%);
        }

        &[disabled] {
            border-color: transparent;
            background: rgba(#1da1f2, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.whatsapp {
        color: $color-button-text;
        border: none;
        background-color: #25d366;
        border: 1px solid #25d366;

        &:hover,
        &:focus {
            background: darken(#25d366, 10%);
        }

        &[disabled] {
            border-color: transparent;
            background: rgba(#25d366, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.linkedin {
        color: $color-button-text;
        border: none;
        background-color: #2867b2;
        border: 1px solid #2867b2;

        &:hover,
        &:focus {
            background: darken(#2867b2, 10%);
        }

        &[disabled] {
            border-color: transparent;
            background: rgba(#2867b2, 0.5);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    &.paypal {
        color: $color-button-text;
        border: none;
        background-color: #ffc439;
        border: 1px solid #ffc439;

        &:hover,
        &:focus {
            background: darken(#f2ba36, 10%);
        }

        &[disabled],
        &.disabled {
            border-color: transparent;
            background: fade(#f2ba36, 50%);
        }

        &:active {
            border: none !important;
            padding: calc(0.5rem + 1px) calc(1rem + 1px);
        }
    }

    ::v-deep .spinner {
        position: absolute;
        left: calc(50% - 1rem);
    }

    &.loading {
        cursor: wait !important;
        position: relative;

        &.loading-indicator {
            color: transparent !important;
        }

        &.paypal {
            color: $color-button-text;
            border: none;
            background-color: #ffc439;
            border: 1px solid #ffc439;

            &:hover,
            &:focus {
                background: darken(#f2ba36, 10%);
            }

            &[disabled],
            &.disabled {
                border-color: transparent;
                background: rgba(#f2ba36, 0.5);
            }

            &:active {
                border: none !important;
                padding: calc(0.5rem + 1px) calc(1rem + 1px);
            }
        }

        ::v-deep .spinner {
            color: $color-text-inverted !important;
        }

        &:after {
            animation: is-locked 1s infinite;
            content: ' ' !important;
            display: block !important;
            z-index: 9999;
            left: 0;

            padding: 0 !important;
            position: absolute !important;
            top: 0 !important;
            width: auto;
            bottom: 0;

            background-color: rgba(255, 255, 255, 0.25);
        }
    }
}

@mixin btn-colorer($color, $secondary-color, $focus-color) {
    &.x-button {
        background: $color;
        &:hover {
            background: $secondary-color;
        }
        &:active {
            background: $secondary-color;
        }

        &:focus {
            background-color: $secondary-color;
            box-shadow: 0 0 0 0.2rem rgba($focus-color, 0.5);
        }
        &.is-locked {
            background: darken($color, 5%) !important;
            border-color: darken($color, 5%);
            .price:after {
                background: lighten($secondary-color, 5%);
            }
            &:after {
                background: $color;
            }
        }
        .ripple .wave {
            background: $color;
        }
        .price {
            background-color: $secondary-color;
        }
    }
}
</style>
