import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0beefc38 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _00638bd0 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _bfef76d6 = () => interopDefault(import('../pages/account/account-information.vue' /* webpackChunkName: "pages/account/account-information" */))
const _31dea3e6 = () => interopDefault(import('../pages/account/personal-information.vue' /* webpackChunkName: "pages/account/personal-information" */))
const _bfc662ca = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _526ff18c = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _8e12ea76 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _12ba8b6b = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _379d59ce = () => interopDefault(import('../pages/delivery.vue' /* webpackChunkName: "pages/delivery" */))
const _07257df8 = () => interopDefault(import('../pages/empty.vue' /* webpackChunkName: "pages/empty" */))
const _44bc2b2c = () => interopDefault(import('../pages/empty2.vue' /* webpackChunkName: "pages/empty2" */))
const _556804c1 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _178e09fe = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _6effb595 = () => interopDefault(import('../pages/return.vue' /* webpackChunkName: "pages/return" */))
const _557da377 = () => interopDefault(import('../pages/reward-info.vue' /* webpackChunkName: "pages/reward-info" */))
const _02c7f38f = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _161a3be6 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _43f10123 = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _2206a1bd = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _c8a832f0 = () => interopDefault(import('../pages/test-cloudflare-session.vue' /* webpackChunkName: "pages/test-cloudflare-session" */))
const _132dc73c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _056b7026 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _6fce354a = () => interopDefault(import('../pages/warning/index.vue' /* webpackChunkName: "pages/warning/index" */))
const _4854c9a0 = () => interopDefault(import('../pages/feeds/shopping.xml.vue' /* webpackChunkName: "pages/feeds/shopping.xml" */))
const _1cbe301a = () => interopDefault(import('../pages/gift/print.vue' /* webpackChunkName: "pages/gift/print" */))
const _f0b7d17a = () => interopDefault(import('../pages/giveaway/next-level-logo-giveaway-2023.vue' /* webpackChunkName: "pages/giveaway/next-level-logo-giveaway-2023" */))
const _81b572e2 = () => interopDefault(import('../pages/legal/complaint-policy.vue' /* webpackChunkName: "pages/legal/complaint-policy" */))
const _ca5c4b54 = () => interopDefault(import('../pages/legal/disclaimer.vue' /* webpackChunkName: "pages/legal/disclaimer" */))
const _653410e8 = () => interopDefault(import('../pages/legal/impressum.vue' /* webpackChunkName: "pages/legal/impressum" */))
const _4db09546 = () => interopDefault(import('../pages/legal/privacy.vue' /* webpackChunkName: "pages/legal/privacy" */))
const _07c94104 = () => interopDefault(import('../pages/legal/return-policy.vue' /* webpackChunkName: "pages/legal/return-policy" */))
const _1fa091ef = () => interopDefault(import('../pages/legal/terms-and-conditions.vue' /* webpackChunkName: "pages/legal/terms-and-conditions" */))
const _05f4499e = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _c9bab004 = () => interopDefault(import('../pages/warning/attempts.vue' /* webpackChunkName: "pages/warning/attempts" */))
const _1306cd38 = () => interopDefault(import('../pages/warning/blocked.vue' /* webpackChunkName: "pages/warning/blocked" */))
const _9fd63f32 = () => interopDefault(import('../pages/warning/disputes.vue' /* webpackChunkName: "pages/warning/disputes" */))
const _0b85ce5c = () => interopDefault(import('../pages/warning/flagged.vue' /* webpackChunkName: "pages/warning/flagged" */))
const _406db825 = () => interopDefault(import('../pages/warning/klarna-unpaid.vue' /* webpackChunkName: "pages/warning/klarna-unpaid" */))
const _07e8bbb3 = () => interopDefault(import('../pages/warning/limit.vue' /* webpackChunkName: "pages/warning/limit" */))
const _454320ad = () => interopDefault(import('../pages/warning/paysafe-velocity.vue' /* webpackChunkName: "pages/warning/paysafe-velocity" */))
const _ac43cf68 = () => interopDefault(import('../pages/warning/region-restricted.vue' /* webpackChunkName: "pages/warning/region-restricted" */))
const _90fce270 = () => interopDefault(import('../pages/warning/undefined.vue' /* webpackChunkName: "pages/warning/undefined" */))
const _99a1b938 = () => interopDefault(import('../pages/feeds/sitemaps/cloudflare.xml.vue' /* webpackChunkName: "pages/feeds/sitemaps/cloudflare.xml" */))
const _85593f08 = () => interopDefault(import('../pages/meta/feed/sitemap/index.vue' /* webpackChunkName: "pages/meta/feed/sitemap/index" */))
const _4776f658 = () => interopDefault(import('../pages/support/answer/phishing-and-fraud-prevention.vue' /* webpackChunkName: "pages/support/answer/phishing-and-fraud-prevention" */))
const _2ba6d6fd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43e65902 = () => interopDefault(import('../pages/meta/feed/sitemap/category/_slug.vue' /* webpackChunkName: "pages/meta/feed/sitemap/category/_slug" */))
const _8a32a7da = () => interopDefault(import('../pages/meta/feed/shopping/_brand.vue' /* webpackChunkName: "pages/meta/feed/shopping/_brand" */))
const _54529193 = () => interopDefault(import('../pages/meta/feed/sitemap/_route/index.vue' /* webpackChunkName: "pages/meta/feed/sitemap/_route/index" */))
const _5adaa9e0 = () => interopDefault(import('../pages/meta/feed/sitemap/_route/_param/index.vue' /* webpackChunkName: "pages/meta/feed/sitemap/_route/_param/index" */))
const _5baf6089 = () => interopDefault(import('../pages/guides/games/_slug.vue' /* webpackChunkName: "pages/guides/games/_slug" */))
const _5f7fc3c4 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _6f747ec8 = () => interopDefault(import('../pages/manual/_brand.vue' /* webpackChunkName: "pages/manual/_brand" */))
const _6e908672 = () => interopDefault(import('../pages/occasion/_slug.vue' /* webpackChunkName: "pages/occasion/_slug" */))
const _2629a06b = () => interopDefault(import('../pages/order-history/_id.vue' /* webpackChunkName: "pages/order-history/_id" */))
const _e590feb8 = () => interopDefault(import('../pages/order/_id.vue' /* webpackChunkName: "pages/order/_id" */))
const _a7fe0e6a = () => interopDefault(import('../pages/support/_category/index.vue' /* webpackChunkName: "pages/support/_category/index" */))
const _127b85ce = () => interopDefault(import('../pages/support/_category/_subcategory/index.vue' /* webpackChunkName: "pages/support/_category/_subcategory/index" */))
const _5f8381b5 = () => interopDefault(import('../pages/support/_category/_subcategory/_page.vue' /* webpackChunkName: "pages/support/_category/_subcategory/_page" */))
const _785d9bb0 = () => interopDefault(import('../pages/_brand/index.vue' /* webpackChunkName: "pages/_brand/index" */))
const _3b731612 = () => interopDefault(import('../pages/_brand/faq/index.vue' /* webpackChunkName: "pages/_brand/faq/index" */))
const _9ae3d05c = () => interopDefault(import('../pages/_brand/giftcard.vue' /* webpackChunkName: "pages/_brand/giftcard" */))
const _e4e7c512 = () => interopDefault(import('../pages/_brand/faq/_howto.vue' /* webpackChunkName: "pages/_brand/faq/_howto" */))
const _f2f8f17c = () => interopDefault(import('../pages/_brand/_product.vue' /* webpackChunkName: "pages/_brand/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/about/",
    component: _0beefc38,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;about"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/account/",
    component: _00638bd0,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "account-information/",
      component: _bfef76d6,
      pathToRegexpOptions: {"strict":true},
      name: "locale;en;account-account-information"
    }, {
      path: "personal-information/",
      component: _31dea3e6,
      pathToRegexpOptions: {"strict":true},
      name: "locale;en;account-personal-information"
    }, {
      path: "",
      component: _bfc662ca,
      pathToRegexpOptions: {"strict":true},
      name: "locale;en;account"
    }]
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/app/",
    component: _526ff18c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;app"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/cart/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/carrito/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/carrello/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/cart/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/carrinho/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/panier/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ostoskori/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/winkelwagen/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/einkaufswagen/",
    component: _8e12ea76,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;cart"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/payment/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/pago/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/pagamento/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it,pt;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/checkout/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/paiement/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/maksu/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/betaling/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/bezahlen/",
    component: _12ba8b6b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;checkout"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/delivery/",
    component: _379d59ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,fi,de;delivery"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/delivery/",
    component: _379d59ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;delivery"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/entrega/",
    component: _379d59ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;delivery"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/livraison/",
    component: _379d59ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;delivery"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/levering/",
    component: _379d59ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;delivery"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/empty/",
    component: _07257df8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;empty"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/empty2/",
    component: _44bc2b2c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;empty2"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/health/",
    component: _556804c1,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;health"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/redirect/",
    component: _178e09fe,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,fi,nl,de;redirect"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/redirect/",
    component: _178e09fe,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,pt,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;redirect"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/redirection/",
    component: _178e09fe,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;redirect"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/order/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/pedido/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ordine/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/return/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/retornar/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ordre/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/tilaus/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/bestelling/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/bestellung/",
    component: _6effb595,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;return"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/reward-info/",
    component: _557da377,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;reward-info"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/reward-info/",
    component: _557da377,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;reward-info"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/rewards/",
    component: _02c7f38f,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;rewards"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/search/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/buscar/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/cerca/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/szukaj/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/search/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt,tr,da,fi,nb,cs,ro,sv,sk,hu,bg,hr,ja;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/rechercher/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/zoeken/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/suchen/",
    component: _161a3be6,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;search"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/service/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/atencionalcliente/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/servizioclienti/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/suporte/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/serviceclients/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/asiakaspalvelu/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/klantenservice/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/kundenservice/",
    component: _43f10123,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;service"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/",
    component: _2206a1bd,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;support"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/test-cloudflare-session/",
    component: _c8a832f0,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;test-cloudflare-session"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/unsubscribe/",
    component: _132dc73c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;unsubscribe"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/verify-email/",
    component: _056b7026,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;verify-email"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/",
    component: _6fce354a,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/feeds/shopping.xml/",
    component: _4854c9a0,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;feeds-shopping.xml"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/gift/print/",
    component: _1cbe301a,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;gift-print"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/giveaway/next-level-logo-giveaway-2023/",
    component: _f0b7d17a,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;giveaway-next-level-logo-giveaway-2023"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/complaint-policy/",
    component: _81b572e2,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-complaint-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/disclaimer/",
    component: _ca5c4b54,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,fi,nl,de;legal-disclaimer"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/disclaimer/",
    component: _ca5c4b54,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-disclaimer"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/aviso/",
    component: _ca5c4b54,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;legal-disclaimer"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/disclaimer_fr/",
    component: _ca5c4b54,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;legal-disclaimer"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/impressum/",
    component: _653410e8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-impressum"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/privacy-policy/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,pt,fr,de;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legale/privacy-policy/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/informativa-sulla-privacy/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/privacy/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/lainmukainen/tietosuojailmoitus/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/privacybeleid/",
    component: _4db09546,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;legal-privacy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/return-policy/",
    component: _07c94104,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,pt,de;legal-return-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legale/return-policy/",
    component: _07c94104,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;legal-return-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/returnpolicy/",
    component: _07c94104,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it,fi,nl;legal-return-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/return-policy/",
    component: _07c94104,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-return-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/returnpolicy_fr/",
    component: _07c94104,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;legal-return-policy"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/terms-and-conditions/",
    component: _1fa091ef,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,it,pt,fr,fi,de;legal-terms-and-conditions"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legale/terms-and-conditions/",
    component: _1fa091ef,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;legal-terms-and-conditions"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/terms-and-conditions/",
    component: _1fa091ef,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;legal-terms-and-conditions"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/legal/algemenevoorwaarden/",
    component: _1fa091ef,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;legal-terms-and-conditions"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/contact/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/atencionalcliente/contacto/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/servizioclienti/contattaci/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/service/contact/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/suporte/contato/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/serviceclients/contact/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/asiakaspalvelu/otayhteytta/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/klantenservice/contact/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/kundenservice/kontakt/",
    component: _05f4499e,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;service-contact"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/attempts/",
    component: _c9bab004,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-attempts"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/blocked/",
    component: _1306cd38,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-blocked"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/disputes/",
    component: _9fd63f32,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-disputes"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/flagged/",
    component: _0b85ce5c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-flagged"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/klarna-unpaid/",
    component: _406db825,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-klarna-unpaid"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/limit/",
    component: _07e8bbb3,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-limit"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/paysafe-velocity/",
    component: _454320ad,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-paysafe-velocity"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/region-restricted/",
    component: _ac43cf68,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-region-restricted"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/warning/undefined/",
    component: _90fce270,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;warning-undefined"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/feeds/sitemaps/cloudflare.xml/",
    component: _99a1b938,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;feeds-sitemaps-cloudflare.xml"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/meta/feed/sitemap/",
    component: _85593f08,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;meta-feed-sitemap"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/answer/phishing-and-fraud-prevention/",
    component: _4776f658,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;support-answer-phishing-and-fraud-prevention"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/",
    component: _2ba6d6fd,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;index"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/meta/feed/sitemap/category/:slug?/",
    component: _43e65902,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;meta-feed-sitemap-category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/meta/feed/shopping/:brand?/",
    component: _8a32a7da,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;meta-feed-shopping-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/meta/feed/sitemap/:route/",
    component: _54529193,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;meta-feed-sitemap-route"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/meta/feed/sitemap/:route/:param/",
    component: _5adaa9e0,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;meta-feed-sitemap-route-param"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/guides/games/:slug?/",
    component: _5baf6089,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;guides-games-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/category/:slug/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/categoria/:slug/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es,it,pt;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/kategoria/:slug/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,fi;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/categorie/:slug/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr,nl;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/category/:slug?/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/kategorie/:slug/",
    component: _5f7fc3c4,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;category-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/manual/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/manuale/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/manual/:brand?/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl,pt,tr,da,nb,cs,ro,sv,sk,hu,bg,hr,ja;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/modedemploi/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fr;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ohjeet/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;fi;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/handleiding/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/anleitung/:brand/",
    component: _6f747ec8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;manual-brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/occasion/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,fr;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ocasion/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/occasione/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;it;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/okazja/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pl;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/ocasiao/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;pt;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/occasion/:slug?/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;tr,da,fi,nb,cs,ro,sv,sk,hu,bg,hr,ja;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/gelegenheid/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;nl;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/gelegenheit/:slug/",
    component: _6e908672,
    pathToRegexpOptions: {"strict":true},
    name: "locale;de;occasion-slug"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/order-history/:id?/",
    component: _2629a06b,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;order-history-id"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/order/:id?/",
    component: _e590feb8,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;order-id"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/",
    component: _a7fe0e6a,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;support-category"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/",
    component: _a7fe0e6a,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;support-category"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/:subcategory/",
    component: _127b85ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;support-category-subcategory"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/:subcategory/",
    component: _127b85ce,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;support-category-subcategory"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/:subcategory/:page/",
    component: _5f8381b5,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en;support-category-subcategory-page"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/support/:category/:subcategory/:page/",
    component: _5f8381b5,
    pathToRegexpOptions: {"strict":true},
    name: "locale;es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;support-category-subcategory-page"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/:brand/",
    component: _785d9bb0,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;brand"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/:brand/faq/",
    component: _3b731612,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;brand-faq"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/:brand/giftcard/",
    component: _9ae3d05c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;brand-giftcard"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/:brand/faq/:howto/",
    component: _e4e7c512,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;brand-faq-howto"
  }, {
    path: "/:region([a-z]{2})?/:language([a-z]{2})?/:brand/:product/",
    component: _f2f8f17c,
    pathToRegexpOptions: {"strict":true},
    name: "locale;en,es,it,pl,pt,fr,tr,da,fi,nl,de,nb,cs,ro,sv,sk,hu,bg,hr,ja;brand-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
