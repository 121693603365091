import { getJSON } from '../utils/json';

export default ({ searchBaseURL, providers }) => {
    return {
        async searchByQuery({
            query = {},
            sorting = { dir: '', query: '' },
            limit = 20,
            categories = ['gamecards', 'giftcards', 'paymentcards'],
            indices = ['brands', 'products'],
            excludeOutOfStock = false,
        }) {
            const locale = providers.isTargetLocale() ? `${providers.language()}-${providers.region()}` : 'en-ww';

            // TODO: remove filter when API doesn't return gamekeys anymore
            const allowedCategories = ['gamecards', 'giftcards', 'paymentcards'];

            const filters = {
                query: query.q,
                locale,
                categories: categories[0] ? categories : allowedCategories,
                indices,
                from: query.p ? (query.p - 1) * limit : '0',
                size: limit,
                sort: sorting.dir, // asc, desc (firstReleaseDate  is always desc)
                sortField: sorting.query, // price, popularity, firstReleaseDate
                excludeOutOfStock,
            };
            filters.minPrice = query.min;
            filters.maxPrice = query.max;

            let queryString = '';
            for (const key in filters) {
                const value = Array.isArray(filters[key]) ? filters[key].join(',') : filters[key];
                if (value || value === 0) queryString = `${queryString}${key}=${encodeURIComponent(value)}&`;
            }

            queryString = queryString.slice(0, -1); // strip last & sign
            return await getJSON(`${searchBaseURL}/v2/search?${queryString}`);
        },
    };
};
