export default {
    orderId: 'ea30ba2f-1b61-4986-babd-27553f4eea18',
    transaction: {
        transactionId: '1845011487Xe7e0b',
        name: '',
        email: 'willem.alexander@example.com',
        verificationEmail: 'willem.alexander@example.com',
        method: 'ideal',
        currency: 'EUR',
        amount: 45.83,
        products: [],
        meta: '{"doNotShowOnOrderPage":true,"customerCompanyName":"Korsit B.V","customerAddressLine1":"Zwembadweg 12","customerAddressLine2":"5611KS Eindhoven","customerVatNumber":"NL857730824B01","trxFeeExperimentAmount":0.18,"vatRegionSetting":"nl","ipRegion":"nl","fp1":"sybjq7ks5bfVMOdTEkSX","utm":{"source":"t3st.dundle.com","medium":"referral","referrer":"https://t3st.dundle.com/nl/?currency\\u003dEUR"},"cid":"713363475.1654597265","website":"dundle.com","sessionId":"4dcdf670-04b9-4d87-abb9-6fce0420f00d","user":{"id":"63531978-2895-4f87-960f-c7ab2cdd064a"},"orderId":"ea30ba2f-1b61-4986-babd-27553f4eea18","enableCreditCardCheck":true,"strategy":"guest","creditCardData":{"sessionUUID":"4dcdf670-04b9-4d87-abb9-6fce0420f00d"},"ipblocktype":"0","trxFeeExperimentOriginalAmount":0.15,"trxFeeExperimentDiscountedAmount":0.15,"cancelUrl":"https://t3st.dundle.com/nl/betaling/","returnUrl":"https://t3st.dundle.com/nl/order/ea30ba2f-1b61-4986-babd-27553f4eea18/?email\\u003dwillem.alexander@example.com","discountValue":3.6667519999999985,"paytrx":"1845011487Xe7e0b","endUserFirstName":"Willem","endUserLastName":"Alexander","endUserAddressLine1":"Koninklijk Park 16","endUserAddressCity":"Apeldoorn","endUserAddressPostalCode":"7315JA","hasRulesValidated":"true","doNotShowOnOrderPage":true,"gaConversionTracked":true,"salt":3.6700000000000004,"profit":0}',
        status: 'Finished',
        customerPoints: 1.86,
        dundleCoins: 812,
        emailDeliveryOnly: false,
        platform: 'WEB',
        orderDate: 1660742597000,
        orderLastUpdatedDate: 1660742647000,
        isDeclined: false,
    },
    products: [
        {
            id: 722,
            createdAt: '2019-08-02T11:10:43.576Z',
            updatedAt: '2021-11-29T10:00:13.000Z',
            deletedAt: null,
            productId: 'zalando-code-50-eur-nl',
            productTitle: null,
            price: 49.82,
            faceValue: 50,
            isVat: false,
            currencyCode: 'eur',
            type: 'region',
            stock: 20,
            imageName: 'zalando',
            regionCode: 'nl',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: false,
            ean13: null,
            gtin: null,
            parentId: null,
            gamePlatform: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 22185,
            dundleCoinsRewarded: 391,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 58,
                createdAt: '2019-08-02T11:04:48.769Z',
                updatedAt: '2022-11-25T09:00:54.000Z',
                deletedAt: null,
                name: 'Zalando',
                primaryTags: null,
                secondaryTags: null,
                slug: 'zalando',
                color: '#FE6A00',
                parentId: null,
                productMatchers: ['zalando-.*'],
                excludedRegions: null,
                availableInRegions: [
                    'it',
                    'pl',
                    'es',
                    'pt',
                    'gb',
                    'nl',
                    'fr',
                    'de',
                    'at',
                    'be',
                    'fi',
                    'se',
                    'no',
                    'dk',
                ],
                availableInLanguages: ['en', 'it', 'pl', 'fr', 'nl', 'de', 'es', 'pt', 'fi', 'da', 'sv', 'nb'],
                importKey: 'zalando',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: 86,
                defaultCurrency: null,
                spotlightRegions: ['nl', 'be', 'it', 'de', 'dk', 'fi', 'se', 'pl', 'fr', 'es'],
                categories: 'giftcards',
                game: null,
                redeemInstructions:
                    '<p>\nVolg het eenvoudige stappenplan om je Zalando voucher in te wisselen:\n</p>\n<ul>\n    <li>\n     Ga naar de <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://www.zalando.nl/">Zalando webshop</a>\n  </li>\n    <li>\n     Selecteer de artikelen die je wilt kopen en ga door naar \'Bestelling plaatsen\'. \n  </li>\n<li>\n Als je bij de stap \'Afrekenen\' bent, kies je Voucher als betaalmiddel en voer je de Zalando voucher code in.\nDe waarde van de voucher wordt van het totaalbedrag afgetrokken. Eventueel resterende waarde kun je bij een volgende aankoop verzilveren. \nPlaats je bestelling en geniet van je aankopen!\n    </li>\n</ul>',
                redeemUrl: 'https://www.zalando.nl',
                category: 'giftcards',
                productName: {
                    id: 1869,
                    createdAt: '2019-08-02T11:04:49.147Z',
                    updatedAt: '2019-09-28T09:59:46.000Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'Zalando Cadeaubon {value} ',
                    type: 'brand',
                    region: null,
                },
            },
            transactionProduct: {
                productId: 'zalando-code-50-eur-nl',
                code: '1UWNGZS2V6WKQIYBF',
                serial: '9850385026975278',
                imageURL: 'http://korsit.com/webapi/v3/payment/smallImage/null',
                priceInEur: 49.82,
                parsedCode: {
                    cvc: null,
                    date: null,
                    pin: null,
                    value: '1UWNGZS2V6WKQIYBF',
                },
            },
        },
        {
            id: 723,
            createdAt: '2019-08-02T11:10:43.576Z',
            updatedAt: '2021-11-29T10:00:13.000Z',
            deletedAt: null,
            productId: 'zalando-code-50-eur-nl',
            productTitle: null,
            price: 49.82,
            faceValue: 50,
            isVat: false,
            currencyCode: 'eur',
            type: 'region',
            stock: 20,
            imageName: 'zalando',
            regionCode: 'nl',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: false,
            ean13: null,
            gtin: null,
            parentId: null,
            gamePlatform: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 22185,
            dundleCoinsRewarded: 391,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 58,
                createdAt: '2019-08-02T11:04:48.769Z',
                updatedAt: '2022-11-25T09:00:54.000Z',
                deletedAt: null,
                name: 'Zalando',
                primaryTags: null,
                secondaryTags: null,
                slug: 'zalando',
                color: '#FE6A00',
                parentId: null,
                productMatchers: ['zalando-.*'],
                excludedRegions: null,
                availableInRegions: [
                    'it',
                    'pl',
                    'es',
                    'pt',
                    'gb',
                    'nl',
                    'fr',
                    'de',
                    'at',
                    'be',
                    'fi',
                    'se',
                    'no',
                    'dk',
                ],
                availableInLanguages: ['en', 'it', 'pl', 'fr', 'nl', 'de', 'es', 'pt', 'fi', 'da', 'sv', 'nb'],
                importKey: 'zalando',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: 86,
                defaultCurrency: null,
                spotlightRegions: ['nl', 'be', 'it', 'de', 'dk', 'fi', 'se', 'pl', 'fr', 'es'],
                categories: 'giftcards',
                game: null,
                redeemInstructions:
                    '<p>\nVolg het eenvoudige stappenplan om je Zalando voucher in te wisselen:\n</p>\n<ul>\n    <li>\n     Ga naar de <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://www.zalando.nl/">Zalando webshop</a>\n  </li>\n    <li>\n     Selecteer de artikelen die je wilt kopen en ga door naar \'Bestelling plaatsen\'. \n  </li>\n<li>\n Als je bij de stap \'Afrekenen\' bent, kies je Voucher als betaalmiddel en voer je de Zalando voucher code in.\nDe waarde van de voucher wordt van het totaalbedrag afgetrokken. Eventueel resterende waarde kun je bij een volgende aankoop verzilveren. \nPlaats je bestelling en geniet van je aankopen!\n    </li>\n</ul>',
                redeemUrl: 'https://www.zalando.nl',
                category: 'giftcards',
                productName: {
                    id: 1869,
                    createdAt: '2019-08-02T11:04:49.147Z',
                    updatedAt: '2019-09-28T09:59:46.000Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'Zalando Cadeaubon {value} ',
                    type: 'brand',
                    region: null,
                },
            },
            // transactionProduct: {
            //     productId: 'zalando-code-50-eur-nl',
            //     code: '1UWNGZS2V6WKQIYBF',
            //     serial: '9850385026975278',
            //     imageURL: 'http://korsit.com/webapi/v3/payment/smallImage/null',
            //     priceInEur: 49.82,
            //     parsedCode: {
            //         cvc: null,
            //         date: null,
            //         pin: null,
            //         value: '1UWNGZS2V6WKQIYBF',
            //     },
            // },
        },
    ],
    rules: [],
    user: {
        id: '63531978-2895-4f87-960f-c7ab2cdd064a',
        userId: '63531978-2895-4f87-960f-c7ab2cdd064a',
        email: 'willem.alexander@example.com',
        createdAt: '2020-05-04T20:32:00.348Z',
        type: 'user',
        loyaltyPoints: 0,
        verified: {
            email: false,
            mobilePhone: false,
        },
        region: 'us',
        language: 'en',
        currency: 'USD',
        _rid: 'oidsAPvhsfq+dpsAAAAAAA==',
        _self: 'dbs/oidsAA==/colls/oidsAPvhsfo=/docs/oidsAPvhsfq+dpsAAAAAAA==/',
        _etag: '"c400cb98-0000-1500-0000-6374b6900000"',
        _attachments: 'attachments/',
        domain: 'dundle.com',
        countryCode: 'NL',
        firstName: 'Willem',
        lastName: 'Alexander',
        address: {
            line1: 'Koninklijk Park 16',
            city: 'Apeldoorn',
            zip: '7315JA',
        },
        secureToken: '0302bde4-f09f-46a9-98ca-da42092863ac',
        _ts: 1668593296,
    },
    isValidated: true,
    paymentUrl: 'https://ideal.pay.nl/payment/init/1845011487Xe7e0b/909d3fae15a39632b10a55d9236ca80f141f4cf5/10/NL',
    gifts: [],
    isCreditCard: false,
};
