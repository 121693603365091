export default context => {
    if (process.server && !process.static) {
        const headers = [].concat
            .apply(
                [],
                context.route.matched.map(({ components }) => Object.values(components))
            )
            .reduce((headers, component) => {
                if (component.options && component.options.httpHeaders) {
                    Object.assign(headers, component.options.httpHeaders(context));
                }

                return headers;
            }, {});

        Object.keys(headers).map(function (header) {
            context.res.setHeader(header, headers[header]);
        });
    }
};
