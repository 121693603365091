export default context => {
    const { res, query, isDev } = context;

    if (process.server && !isDev && !process.static) {
        const pageCaching = [].concat
            .apply(
                [],
                context.route.matched.map(({ components }) => Object.values(components))
            )
            .reduce((pageCaching, component) => {
                if (component.options && component.options.pageCaching) {
                    Object.assign(pageCaching, component.options.pageCaching);
                }

                return pageCaching;
            }, {});

        const isPreview = query.preview === 'true';

        if (pageCaching.maxAge && !isPreview) {
            res.setHeader('Page-Cache', pageCaching.maxAge);
        }
    }
};
