const middleware = {}

middleware['breadcrumb'] = require('../middleware/breadcrumb.js')
middleware['breadcrumb'] = middleware['breadcrumb'].default || middleware['breadcrumb']

middleware['cache-tags'] = require('../middleware/cache-tags.js')
middleware['cache-tags'] = middleware['cache-tags'].default || middleware['cache-tags']

middleware['cart'] = require('../middleware/cart.js')
middleware['cart'] = middleware['cart'].default || middleware['cart']

middleware['http-caching'] = require('../middleware/http-caching.js')
middleware['http-caching'] = middleware['http-caching'].default || middleware['http-caching']

middleware['http-headers'] = require('../middleware/http-headers.js')
middleware['http-headers'] = middleware['http-headers'].default || middleware['http-headers']

middleware['page-caching'] = require('../middleware/page-caching.js')
middleware['page-caching'] = middleware['page-caching'].default || middleware['page-caching']

middleware['payment'] = require('../middleware/payment.js')
middleware['payment'] = middleware['payment'].default || middleware['payment']

middleware['route-meta'] = require('../middleware/route-meta.js')
middleware['route-meta'] = middleware['route-meta'].default || middleware['route-meta']

middleware['user'] = require('../middleware/user.js')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
