function supportsCrypto() {
    return globalThis && globalThis.crypto;
}

export function supportsRandomUUID() {
    return supportsCrypto() && globalThis.crypto.randomUUID;
}

export function supportsGetRandomValues() {
    return supportsCrypto() && globalThis.crypto.getRandomValues;
}

export function hexTimeStamp() {
    return Date.now().toString(16);
}
