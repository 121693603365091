function extractHostname(url) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname

    if (url.includes('//')) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname.replace(/(^admin\.secure\.)|(^t3st\.)|(123giftcard\.)/, '');
}

export function createCore({ app, store }) {
    function shouldHaveTrailingSlash(route) {
        const name = typeof route === 'string' ? route : route.name;

        return route.trailingSlash || ['index', 'brand', 'brand-product', 'category-slug'].includes(name);
    }

    return {
        watchLocale(fn) {
            store.watch(state => state.locale.currentLocale, fn);
        },

        async initialize({ req, route, error, redirect }) {
            const { locales, currencies, rootAliases } = await import('./data'); // Lazy import is important here! Ask @Tim if any questions occur.

            store.dispatch('locale/initialize', { locales, currencies, rootAliases });

            const host = extractHostname(route.query.host || req.headers.host);

            store.dispatch('locale/setHost', host);

            const reversedRoute = app.$locale.reverseRoute(route);

            if (typeof reversedRoute === 'undefined') {
                return error({ message: 'Page not found', statusCode: 404 });
            }

            const path = app.$locale.path(reversedRoute);

            if (route.path !== path.split('?')[0]) {
                return redirect(301, path);
            }

            if (typeof reversedRoute === 'undefined') {
                store.dispatch('locale/setLocale', app.$locale.defaultLocale);
                return error({ message: 'Page not found', statusCode: 404 });
            }

            const { language, regio, name } = reversedRoute;

            if (!reversedRoute.isSupported) {
                store.dispatch('locale/setLocale', app.$locale.defaultLocale);
                return error({ message: 'Page not found', statusCode: 404 });
            } else {
                const regio2 = typeof regio === 'string' ? regio : 'ww';
                store.dispatch('locale/setLocale', language.toLowerCase() + '-' + regio2.toUpperCase());
            }
        },

        get preferredLanguage() {
            const preferredLocale =
                (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

            return preferredLocale.split('-')[0].toLowerCase();
        },

        get preferredLanguages() {
            const preferredLocales = (navigator.languages && navigator.languages) || [
                navigator.language || navigator.userLanguage,
            ];

            return preferredLocales.map(localeString => localeString.split('-')[0]);
        },

        get globals() {
            return store.getters['locale/getGlobalParams'];
        },

        get iso() {
            return this.currentLocale.iso;
        },

        get language() {
            return this.currentLocale.language;
        },

        get regio() {
            return this.currentLocale.regio;
        },

        get region() {
            return this.currentLocale.regio.toLowerCase();
        },

        get currency() {
            return this.currentLocale.currency;
        },

        get currencies() {
            return store.getters['locale/currencies'];
        },

        get domain() {
            return store.getters['locale/domain'];
        },

        get currentLocale() {
            return store.getters['locale/currentLocale'];
        },

        get detectedRegion() {
            return store.getters['locale/detectedRegion'];
        },

        get locales() {
            return store.getters['locale/locales'];
        },

        get rootAliases() {
            return store.getters['locale/rootAliases'];
        },

        get domains() {
            return store.getters['locale/domains'];
        },

        get supportedRegions() {
            return Array.from(new Set(this.locales.map(locale => locale.regio.toLowerCase())));
        },

        get defaultLocale() {
            return 'en-US';
        },
        get defaultRegion() {
            return this.defaultLocale.split('-')[1].toLowerCase();
        },

        getDefaultLanguageOfRegion(region) {
            const locale = this.locales.find(locale => locale.regio.toLowerCase() === region && locale.default);
            if (locale) {
                return locale.language;
            }
            return 'en';
        },

        getNativeLanguagesOfRegion(region) {
            return this.locales
                .filter(locale => locale.native && locale.iso.split('-')[1].toLowerCase() === region.toLowerCase())
                .map(locale => locale.iso.split('-')[0]);
        },

        getInternationalLanguagesOfRegion(region) {
            return this.locales
                .filter(
                    locale => locale.international && locale.iso.split('-')[1].toLowerCase() === region.toLowerCase()
                )
                .map(locale => locale.iso.split('-')[0]);
        },

        getLanguagesOfRegion(regio) {
            return this.locales
                .filter(locale => locale.iso.split('-')[1].toLowerCase() === regio.toLowerCase())
                .map(locale => locale.iso.split('-')[0]);
        },

        getCurrencyOfRegion(regio) {
            return this.locales
                .filter(locale => locale.iso.split('-')[1].toLowerCase() === regio.toLowerCase())
                .map(locale => {
                    return this.currencies.find(currency => currency.code === locale.currency);
                });
        },

        getCurrency(currencyCode) {
            return this.currencies.find(currency => currency.code === currencyCode);
        },

        getPreferredLanguageOf(languages) {
            return this.preferredLanguages.find(preferredLanguage => languages.includes(preferredLanguage));
        },

        getGlobal(name) {
            return store.getters['locale/getGlobalParam'](name);
        },

        setGlobal(name, value) {
            store.dispatch('locale/setGlobalParam', { name, value });
        },

        isTarget() {
            return this.currentLocale.target;
        },

        reverseRoute(localizedRoute) {
            if (typeof localizedRoute === 'undefined' || typeof localizedRoute.name !== 'string') {
                return;
            }

            const [type, languages, name] = localizedRoute.name.split(';');

            let region = localizedRoute.params.region;
            let language = localizedRoute.params.language;

            if (!region || !language) {
                region = (localizedRoute.params.region || app.$locale.defaultRegion).toLowerCase();
                language = (localizedRoute.params.language || this.getDefaultLanguageOfRegion(region)).toLowerCase();
            }

            const isSupported = !!app.$locale.locales.find(
                locale => locale.language === language && locale.regio === region.toUpperCase()
            );

            return {
                name,
                language,
                region,
                get regio() {
                    // console.warn('Deprecated get regio on route');
                    return this.region;
                },
                params: localizedRoute.params,
                query: localizedRoute.query,
                hash: localizedRoute.hash,
                iso: language + '-' + region.toUpperCase(),
                isSupported,
            };
        },

        route(route, params, iso) {
            if (typeof params === 'string') {
                iso = params;
                params = undefined;
            }

            iso = (iso || route.iso || this.iso).toLowerCase();

            const [language, region] = iso.split('-');

            let localizedRoute;
            if (typeof route === 'string') {
                localizedRoute = {
                    name: 'locale;' + language + ';' + route,
                    params: {
                        ...route.params,
                        region,
                        language,
                    },
                };
            } else {
                localizedRoute = {
                    name: 'locale;' + language + ';' + route.name,
                    params: {
                        ...route.params,
                        region,
                        language,
                    },
                    query: route.query,
                    hash: route.hash,
                };
            }

            // Attach found locale:
            localizedRoute.locale = this.locales.find(locale => iso == locale.iso.toLowerCase());

            // Remove language parameters if it's not needed:
            if (this.getDefaultLanguageOfRegion(region) === language) {
                localizedRoute.params.language = undefined;

                // Remove region parameters if it's not needed:
                if (app.$locale.defaultRegion === region) {
                    localizedRoute.params.region = undefined;
                }
            }

            return {
                ...localizedRoute,
                name: this.rootAliases[localizedRoute.name] || localizedRoute.name,
            };
        },

        path(route, params) {
            const normalizedRoute = this.route.apply(this, arguments);

            const [p, rest] = app.router.resolve(normalizedRoute).href.split('?');

            const path = rest ? [p.toLowerCase(), rest].join('?') : p.toLowerCase();

            return shouldHaveTrailingSlash(route) ? path.replace(/^([^?#]*[^?#\/])([?#].*)?$/, '$1/$2') : path;
        },

        url(route, params) {
            const normalizedRoute = this.route.apply(this, [
                ...arguments,
                {
                    deriveDomain: true,
                },
            ]);

            const [p, rest] = app.router.resolve(normalizedRoute).href.split('?');

            const path = rest ? [p.toLowerCase(), rest].join('?') : p.toLowerCase();

            // @TODO Fix https derivation
            const domain =
                route.deriveDomain && normalizedRoute.locale ? normalizedRoute.locale.domain : 'https://' + this.domain;

            return (
                domain + (shouldHaveTrailingSlash(route) ? path.replace(/^([^?#]*[^?#\/])([?#].*)?$/, '$1/$2') : path)
            );
        },

        localizedIsoParams() {
            const route = this.route({
                name: 'index',
            });

            const { region, language } = route.params;
            if (region && language) {
                return `${region}/${language}/`;
            } else if (!region && !language) {
                return '';
            } else {
                return `${region}/`;
            }
        },

        money(number, options) {
            return new Intl.NumberFormat(this.language, {
                style: 'currency',
                currency: this.currency,
                ...(options || {}),
            }).format(number);
        },

        applyWebsiteName() {
            const isRU = this.currentLocale.language === 'ru';
            const name = isRU ? `Дандл` : `Dundle (${this.regio.toUpperCase()})`;
            this.setGlobal('website', name);
            this.setGlobal('websiteName', name);
        },
    };
}
