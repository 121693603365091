

export function definePlugin(plugin) {

    // Plain wrapper function:
    return async (context, inject) => {

        if (process.client) {

            // This makes sure there is some idle time between plugins on the client side (Improving Web Vital TBT): 
            await new Promise(resolve => (window.requestIdleCallback || window.setTimeout)(resolve));
        }

        return await plugin(context, inject);
    }
}