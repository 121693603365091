import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }) => {
    app.router.afterEach(() => {
        store.dispatch('route/applyState');

        // reset validation on route change, so input fields on other pages with the same id are not affected
        if (app.$validation.errors || app.$validation.valid) app.$validation.resetValidation();
    });
});
