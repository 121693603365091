import { getJSON, postJSON } from "../utils/json";

export default ({ staticBaseURL, providers }) => ({
    async getCategories(language = providers.language()) {
        return await getJSON(`${staticBaseURL}/faq/question-categories?hideSubcategories=true&isActive=true&locale=${language}&fallback=en`);
    },

    async getHotTopics(language = providers.language()) {
        return await getJSON(`${staticBaseURL}/faq/question?isActive=true&isHotTopic=true&locale=${language}&fallback=en`);
    },

    async getCategoryBySlug(slug, language = providers.language()) {
        return await getJSON(`${staticBaseURL}/faq/question-categories/slug/${slug}?locale=${language}&fallback=en&isActive=true`);
    },

    async getCategoryQuestions(category, language = providers.language()) {
        return await getJSON(`${staticBaseURL}/faq/question?category=${category}&locale=${language}&fallback=en&isActive=true`);
    },

    async getCategoryPages(category, language = providers.language()) {
        // category is now name/title of page, but should be changed to slug
        return await getJSON(`${staticBaseURL}/faq/pages?category=${category}&locale=${language}&fallback=en&isActive=true`);
    },

    async getPage(slug, language = providers.language()) {
        return await getJSON(`${staticBaseURL}/faq/pages/slug/${slug}?locale=${language}&fallback=en&isActive=true`);
    },

    async voteOnPage(pageId, translationId, vote) {
        return await postJSON(`${staticBaseURL}/faq/pages/${pageId}/translations/${translationId}/metrics`, { action: vote });
    },
});
