import { definePlugin } from "@dundle/utils/nuxt";

export default definePlugin(() => {

    // Only apply style changes manually in animation frames! Ask @Tim
    requestAnimationFrame(() => {

        // Calculate scroll bar width and set this as CSS property:
        document.documentElement.style.setProperty('--scroll-bar-width', `${window.innerWidth - document.body.clientWidth}px`);
    });
});