export const state = () => ({
    files: {},
});

export const mutations = {
    setFiles(state, files) {
        Object.keys(files).forEach(path => {
            state.files[path] = files[path];
        });
    },
};

export const actions = {
    async fetchFiles({ commit }) {
        const files = await this.$api.translations.getAllTranslations();

        commit('setFiles', files);
    },
};

export const getters = {
    getFile(state) {
        return path => {
            if (path in state.files) {
                return state.files[path];
            } else {
                console.warn(`Could not load translation file: "${path}"`);
                try {
                    $nuxt.$sentry?.captureException(new Error(`Could not load translation file: "${path}"`));
                } catch (e) {
                    // Do nothing
                }
                return {};
            }
        };
    },
};
