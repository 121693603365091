<template>
    <UIGridContainer>
        <h1 v-show="error.statusCode === 404">{{ translate('pageNotFound') }}</h1>
        <h1 v-show="error.statusCode !== 404">{{ translate('genericError') }}</h1>
        <p>{{ translate('pageNotFoundDesc') }}</p>
        <UIButton variant="primary" :to="$locale.path('index')">{{ translate('backBtn') }}</UIButton>
        <div class="d-flex justify-content-center align-items-center">
            <nuxt-resource :full-u-r-l="true" src="//cdn.korsit.com/png/trunk/404.png" :width="300" alt="404" />
        </div>
    </UIGridContainer>
</template>

<script>
import { UIGridContainer, UIButton } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIGridContainer,
        UIButton,
    },
    mixins: [TranslationMixin],
    props: ['error'],

    head() {
        return {
            title: '404 Not Found',
        };
    },
};
</script>
<i18n>
{
	"de": {
		"backBtn": "Sehen Sie sich unser Sortiment an",
		"pageNotFound": "Seite nicht gefunden",
		"pageNotFoundDesc": "Wie peinlich! Leider konnten wir diese Seite nicht (mehr) finden. Aller Wahrscheinlichkeit nach wurde die Seite verschoben oder gelöscht."
	},
	"en": {
		"backBtn": "Take a look at our product catalogue",
		"pageNotFound": "Page not found!",
		"pageNotFoundDesc": "Unfortunately, we couldn't find the page you were looking for. The page has probably been moved or deleted."
	},
	"fr": {
		"backBtn": "Découvrir nos produits",
		"pageNotFound": "Page Web non disponible",
		"pageNotFoundDesc": "Malheureusement nous ne pouvions trouver aucune page Web à cette adresse. La page Web est peut-être temporairement inaccessible ou a été déplacée définitivement vers une nouvelle adresse."
	},
	"it": {
		"backBtn": "Dai un'occhiata al nostro catalogo prodotti",
		"pageNotFound": "Pagina non trovata!",
		"pageNotFoundDesc": "Purtroppo non siamo riusciti a trovare la pagina che stavi cercando. La pagina è stata propabilmente spostata o rimossa."
	},
	"nl": {
		"backBtn": "Bekijk ons assortiment",
		"pageNotFound": "Pagina niet gevonden",
		"pageNotFoundDesc": "Helaas konden wij deze door jou opgevraagde pagina niet vinden. Naar alle waarschijnlijkheid is de pagina verplaatst of verwijderd."
	},
	"pt": {
		"backBtn": "Dê uma olhada no nosso catálogo",
		"pageNotFound": "Página não encontrada!",
		"pageNotFoundDesc": "Infelizmente não encontramos a página que você estava procurando. A página deve ter mudado de lugar ou foi deletada."
	},
	"es": {
		"backBtn": "Descubre nuestro catálogo",
		"pageNotFound": "Página no encontrada",
		"pageNotFoundDesc": "Lo sentimos, pero no encontramos la página que buscas. Probalemente ha sido eliminada o trasladada"
	},
	"pl": {
		"backBtn": "Zobacz katalog naszych produktów",
		"pageNotFound": "przepraszamy, nie ma takiej strony!",
		"pageNotFoundDesc": "Niestety, nie znaleźliśmy strony, której szukasz. Strona została prawdopodobnie przeniesiona lub usunięta."
	},
	"tr": {

	},
	"ar": {

	},
	"da": {
		"backBtn": "Tag et kig i vores produktkatalog",
		"pageNotFound": "Siden blev ikke fundet!",
		"pageNotFoundDesc": "Desværre kunne vi ikke finde den side, du ledte efter. Siden er muligvis flyttet eller slettet."
	},
	"fi": {
		"backBtn": "Tutki tuoteluetteloamme",
		"pageNotFound": "Sivua ei löydy!",
		"pageNotFoundDesc": "Valitettavasti emme löydä etsimääsi sivua. Sivu on mahdollisesti siirretty tai poistettu."
	},
	"ru": {
		"backBtn": "Вернуться к катологу продуктов",
		"pageNotFound": "Страница не найдена!",
		"pageNotFoundDesc": "К сожалению, мы не смогли найти страницу, которую вы искали. Возможно, страница была перемещена или удалена."
	},
	"sv": {
		"backBtn": "Titta i vår produktkatalog",
		"pageNotFound": "Sidan hittades inte!",
		"pageNotFoundDesc": "Tyvärr kunde vi inte hitta sidan du letade efter. Sidan har troligtvis blivit flyttad eller raderad. "
	},
	"nb": {
		"backBtn": "Ta en titt i vår produktkatalog",
		"pageNotFound": "Siden ikke funnet!",
		"pageNotFoundDesc": "Dessverre, vi fant ikke siden du leter etter. Denne siden har sansynligvis blitt flyttet eller slettet."
	},
	"cs": {
		"backBtn": "Prohlédněte si náš katalog produktů",
		"pageNotFound": "Stránka nenalezena!",
		"pageNotFoundDesc": "Hledanou stránku jsme bohužel nenašli! Zřejmě byla přesunuta nebo smazána."
	},
	"sk": {
		"backBtn": "Prezrite si náš katalóg výrobkov",
		"pageNotFound": "Stránka nenájdená!",
		"pageNotFoundDesc": "Ľutujeme, hľadanú stránku sme nenašli. Stránka bola pravdepodobne presunutá alebo odstránená."
	},
	"hu": {
		"backBtn": "Nézd meg katalógusunk ajánlatát",
		"pageNotFound": "Az oldal nem található.",
		"pageNotFoundDesc": "Sajnos nem találjuk az oldalt, amit keresel. Valószínűleg törölve lett vagy máshova került."
	},
	"el": {
		"backBtn": "Ρίξτε μια ματιά στον κατάλογο των προϊόντων μας",
		"pageNotFound": "Η σελίδα δεν βρέθηκε!",
		"pageNotFoundDesc": "Δυστυχώς, η σελίδα που ψάχνετε δεν βρέθηκε. Η σελίδα έχει πιθανόν μεταφερθεί αλλού ή έχει διαγραφεί."
	},
	"ro": {
		"backBtn": "Aruncă o privire pe catalogul nostru de produse",
		"pageNotFound": "Pagina nu a fost găsită!",
		"pageNotFoundDesc": "Din păcate nu am putut găsi pagina pe care o cauți. Pagina a fost probabil ștearsă sau mutată."
	},
	"bg": {
		"backBtn": "Затова разгледайте каталога с продуктите ни",
		"pageNotFound": "Страницата не е открита!",
		"pageNotFoundDesc": "За съжаление, не можахме да открием търсената от вас страница. Възможно е да е преместена или изтрита."
	},
	"hr": {
		"backBtn": "Pregledajte naš katalog proizvoda",
		"pageNotFound": "Stranica nije pronađena!",
		"pageNotFoundDesc": "Nažalost, nismo mogli pronaći stranicu koju tražite. Stranica je vjerojatno premještena ili izbrisana."
	}
}
</i18n>
