function dateInRange(d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((d = d.valueOf())) && isFinite((start = start.valueOf())) && isFinite((end = end.valueOf()))
        ? start <= d && d <= end
        : NaN;
}

const OCCASION_COOKIE = 'dundle.occasion_date';

export const state = () => ({
    activeOccasion: 'default',
    occasions: [
        {
            slug: 'blackfriday',
            apiCampaignName: 'black-friday',
            brands: [],
            categories: [],
            pageLiveFromTo: {
                from: new Date(2023, 10, 2),
                to: new Date(2023, 10, 29),
            },
            activeDateRange: {
                from: new Date(2023, 10, 20),
                to: new Date(2023, 10, 29),
            },
        },
        {
            slug: 'christmas',
            brands: [],
            categories: [],
            pageLiveFromTo: {
                from: new Date(2023, 11, 1),
                to: new Date(2024, 0, 1),
            },
            activeDateRange: {
                from: new Date(2023, 11, 15),
                to: new Date(2024, 0, 1),
            },
        },
    ],
});

export const mutations = {
    setActiveOccasion(state, occasion) {
        state.activeOccasion = occasion;
    },
    setOccasions(state, occasions) {
        state.occasions = occasions;
    },
};

export const actions = {
    /*
     * For all live campaigns, fetch the categories with brands
     */
    async fetchCampaignBrands({ commit, state }) {
        const date = this.$cookies.get(OCCASION_COOKIE);
        const currentDate = date ? new Date(date.substring(0, 10)) : new Date();

        const updatedOccasions = await Promise.all(
            state.occasions.map(async occasion => {
                if (!dateInRange(currentDate, occasion.pageLiveFromTo.from, occasion.pageLiveFromTo.to)) {
                    return { ...occasion };
                }

                try {
                    // Get occasion campaign api key
                    const apiCampaignName = occasion.apiCampaignName ?? occasion.slug;
                    const campaignBrands = await this.$api.brand.getMarketingCampaignBrands(apiCampaignName);
                    return {
                        ...occasion,
                        categories: campaignBrands?.categorisedBrands,
                        brands: campaignBrands?.categorisedBrands.reduce(
                            (acc, category) => acc.concat(category.brands),
                            []
                        ),
                    };
                } catch (error) {
                    console.log(`failed to fetch marketing campaign brands ${occasion.slug}`);
                }
            })
        );

        commit('setOccasions', updatedOccasions);
        return updatedOccasions;
    },
    setCurrentOccasion({ commit, state }) {
        const date = this.$cookies.get(OCCASION_COOKIE);
        const currentDate = date ? new Date(date.substring(0, 10)) : new Date();

        const activeOccasion = state.occasions.find(occasion =>
            dateInRange(currentDate, occasion.activeDateRange.from, occasion.activeDateRange.to)
        );

        if (activeOccasion) {
            commit('setActiveOccasion', activeOccasion.slug);
        }
    },
};

export const getters = {
    getActiveOccasion(state) {
        return state.activeOccasion;
    },
    getActiveOccasionObject(state) {
        return state.occasions.find(o => o.slug === state.activeOccasion);
    },
    getSlugs(state) {
        return state.occasions.map(o => o.slug);
    },
    getOccasionBrands: state => occasion => {
        return state.occasions.find(o => o.slug === occasion)?.brands ?? [];
    },
    getOccasionCategories: state => occasion => {
        return state.occasions.find(o => o.slug === occasion)?.categories ?? [];
    },
};
