/**
 * Sets a Cache-Tag header which we can use to
 * granularly purge the Cloudflare cache
 */
export default ({ res, params }) => {
    if (!process.server || process.static) return;

    if (params.brand) {
        res.setHeader('Cache-Tag', `brand:${params.brand}`);
    }
};
