<template>
    <component
        :is="tag"
        class="x-ripple"
        v-bind="$attrs"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        @touchcancel="onTouchCancel"
        @pointercancel="onTouchCancel"
        @touchmove="onTouchMove"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        @mousemove="onMouseMove"
        @click="onClick">
        <div v-if="started && !done" ref="container" class="ripple-container">
            <UIRippleCore
                :color="color"
                :speed="speed"
                :opacity="opacity"
                :transition="transition"
                :styles="styles"
                :class="{ 'fade-out': ended }"></UIRippleCore>
        </div>

        <slot></slot>
    </component>
</template>

<script>
import { UIRippleCore } from '@dundle/ui/components';

function getDistance(x, y) {
    return Math.sqrt(x * x + y * y);
}

function calculateDistance(x1, y1, x2, y2) {
    const vx = x2 - x1;
    const vy = y2 - y1;

    return Math.sqrt(vx * vx + vy * vy);
}

function getTime() {
    return +new Date();
}

function calculateDuration(startTime) {
    return getTime() - startTime;
}

export default {
    components: {
        UIRippleCore,
    },

    props: {
        color: {
            type: String,
            default: '#fff',
        },

        opacity: {
            type: Number,
            default: 0.1,
        },

        speed: {
            type: Number,
            default: 2.5,
        },

        transition: {
            type: String,
            default: 'ease-out',
        },

        timeThreshold: {
            type: Number,
            default: 1,
        },

        distanceThreshold: {
            type: Number,
            default: 5,
        },

        minimalDuration: {
            type: Number,
            default: 400,
        },

        tag: {
            type: String,
            default: 'div',
        },
    },

    data() {
        return {
            active: false,
            started: false,
            ended: false,
            styles: {},
            startX: 0,
            startY: 0,
            done: false,
        };
    },

    methods: {
        handleStart(x, y) {
            this.done = false;
            this.active = true;
            this.started = false;
            this.ended = false;
            this.startX = x;
            this.startY = y;
            this.startTime = getTime();

            const { top, left } = this.$el.getBoundingClientRect();

            this.positionX = left;
            this.positionY = top;

            this.$nextTick(() => {
                if (this.active) {
                    this.started = true;

                    const x = this.startX - this.positionX;
                    const y = this.startY - this.positionY;

                    const width = this.$el.clientWidth;
                    const height = this.$el.clientHeight;

                    const maxDistance = getDistance(Math.max(x, width - x), Math.max(y, height - y));

                    const size = maxDistance * 2;

                    const left = this.startX - this.positionX - size / 2;
                    const top = this.startY - this.positionY - size / 2;

                    this.styles = {
                        top,
                        left,
                        size,
                    };
                }
            });
        },

        handleEnd() {
            const duration = calculateDuration(this.startTime);

            if (duration < this.minimalDuration) {
                setTimeout(() => {
                    this.ended = true;
                    this.active = false;
                    setTimeout(() => {
                        if (this.ended) {
                            this.done = true;
                        }
                    }, 250);
                }, this.minimalDuration - duration);
            } else {
                this.ended = true;
                this.active = false;
                setTimeout(() => {
                    if (this.ended) {
                        this.done = true;
                    }
                }, 250);
            }
        },

        handleAbort() {
            this.active = false;
            this.handleEnd();
        },

        handleMove(x, y) {
            const distance = calculateDistance(this.startX, this.startY, x, y);

            if (distance > this.distanceThreshold) {
                this.handleAbort();

                return false;
            } else {
                return true;
            }
        },

        onClick() {
            this.handleEnd();
        },

        onTouchStart(e) {
            if (e.touches.length > 0) {
                // Extract x and y from touches:
                const { clientX, clientY } = e.touches[0];

                this.handleStart(clientX, clientY);

                this.wasTouchEvent = true;
            }
        },

        onTouchEnd(ev) {
            if (this.active) {
                this.handleEnd();
            }
        },

        onTouchCancel() {
            if (this.active) {
                this.handleEnd();
            }
        },

        onTouchMove(e) {
            if (this.active && e.touches.length > 0) {
                // Extract x and y from touches:
                const { clientX, clientY } = e.touches[0];

                const acceptMove = this.handleMove(clientX, clientY);

                if (acceptMove) {
                    e.preventDefault();
                }
            }
        },

        onMouseDown(e) {
            if (!this.wasTouchEvent) {
                this.handleStart(e.clientX, e.clientY);
            }
        },

        onMouseMove(e) {
            if (this.active) {
                this.handleMove(e.clientX, e.clientY);
            }
        },

        onMouseUp() {
            if (this.active && !this.wasTouchEvent) {
                this.handleEnd();
            }

            this.wasTouchEvent = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.x-ripple {
    position: relative;

    & > .ripple-container {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    & > .ripple-container {
        z-index: 0;

        & > .x-ripple-core {
            display: block;
            position: absolute;
            border-radius: 50%;
            transition-property: transform, opacity;
            &.fade-out {
                opacity: 0 !important;
            }
        }
    }

    & > .content-container {
        position: relative;
        z-index: 1;
    }
}
</style>
