import middleware from '../middleware';

import { createMiddleware } from './middleware';

import { createStore } from './store';
import { createCore } from './core';

export default async (context, inject) => {
    if (process.client) {
        await new Promise(resolve => (window.requestIdleCallback || window.setTimeout)(resolve));
    }

    // Setup router middleware:
    middleware['locale-middleware'] = createMiddleware();

    // Setup vuex store:
    createStore(
        context,
        'locale'
    );

    // Inject core in app and Vue:
    inject('locale', createCore(context));
}
