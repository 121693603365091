import createBrandAPI from './brand';
import createChangeAPI from './change';
import createContentAPI from './content';
import createIPAPI from './ip';
import createPageAPI from './page';
import createProductAPI from './product';
import createProductInfoAPI from './productInfo';
import createSupportAPI from './support';
import createTrustpilotAPI from './trustpilot';
import createTranslationsAPI from './translations';
import createNotificationsAPI from './notifications';
import createInstagramAPI from './instagram';
import createFaqAPI from './faq';
import createGiftpaperAPI from './giftpaper';
import createCurrencyAPI from './currency';
import createVerificationAPI from './verification';
import createNewsletterAPI from './newsletter';

export default options => ({
    productInfo: createProductInfoAPI(options),
    trustpilot: createTrustpilotAPI(options),
    content: createContentAPI(options),
    support: createSupportAPI(options),
    product: createProductAPI(options),
    change: createChangeAPI(options),
    brand: createBrandAPI(options),
    page: createPageAPI(options),
    ip: createIPAPI(options),
    translations: createTranslationsAPI(options),
    notifications: createNotificationsAPI(options),
    instagram: createInstagramAPI(options),
    faq: createFaqAPI(options),
    giftpaper: createGiftpaperAPI(options),
    currency: createCurrencyAPI(options),
    verification: createVerificationAPI(options),
    newsletter: createNewsletterAPI(options),
});
