import { getJSON, postJSON, patchJSON } from "../utils/json";

export default ({ baseURL }) => {
    return {
        async getById(id) {
            return (await getJSON(`${baseURL}/gifts/${id}`)).gift;
        },

        async create(gift) {
            return (await postJSON(`${baseURL}/gifts`, gift)).gift;
        },

        async update(gift) {
            return (await patchJSON(`${baseURL}/gifts/${gift.id}`, gift)).gift;
        },
    };
};
