import { getJSON, postJSON } from '../utils/json';

/**
 * SMS Verification "V2" using the iGiftcards API rather
 * than the Azure API.
 */

export default ({ staticBaseURL, providers }) => ({
    /**
     * Attempt to do a SMS verification using the iGiftcards API.
     *
     * @param userId
     * @param verificationId
     * @param otp
     * @returns
     */
    async attemptVerification(userId, verificationId, otp) {
        return postJSON(`${staticBaseURL}/verification/verify`, {
            userId,
            verificationId,
            otp,
        });
    },

    /**
     * Request a new SMS Verification code.
     *
     * @param userId
     * @param phoneNumber
     * @returns
     */
    async requestSmsVerification(
        userId,
        phoneNumber,
        token,
        language = providers.language(),
        region = providers.region()
    ) {
        return postJSON(`${staticBaseURL}/verification/request-verification`, {
            userId,
            phoneNumber,
            language,
            region,
            token,
        });
    },

    /**
     * Fetch an existing SMS Verification entity.
     */
    async getSmsVerification(userId, verificationId) {
        return getJSON(`${staticBaseURL}/verification/${userId}/${verificationId}`);
    },
});
