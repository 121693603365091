import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, env, store }, inject) => {
    inject('trustpilot', {
        get id() {
            return env.trustpilotId;
        },

        isVisible() {
            return store.getters['trustpilot/showTrustpilot'];
        },

        shouldHaveProductReviews() {
            return true;
        },
    });
});
