export function createMiddleware() {
    return async ({ app, req, res, route, store, redirect, error, isHMR }) => {
        if (isHMR) {
            return;
        }

        if (typeof route.name != 'string') {
            return;
        }

        const reversedRoute = app.$locale.reverseRoute(route);

        if (typeof reversedRoute === 'undefined') {
            return redirect('/error');
        }

        const {language, regio, name} = reversedRoute;

        if (!reversedRoute.isSupported) {
            store.dispatch('locale/setLocale', app.$locale.defaultLocale);
            return error({ message: 'Page not found', statusCode: 404 });
        }

        const regio2 = typeof regio === 'string' ? regio : 'ww';
        store.dispatch('locale/setLocale', language.toLowerCase() + '-' + regio2.toUpperCase())
    }
}