import { getJSON, postJSON, putJSON } from '../utils/json';

export default ({ staticBaseURL, providers }) => ({
    async addNewContact(user) {
        try {
            return await postJSON(`${staticBaseURL}/msp/contact`, user);
        } catch (e) {
            console.warn(e.response.data.type + ' ' + e.response.data.message);
        }
    },

    async editContact(user) {
        return await putJSON(`${staticBaseURL}/msp/contact?email=${user.email}`, user);
    },

    async getSubscriptionStatus(email) {
        return await getJSON(`${staticBaseURL}/msp/contact?email=${email}`);
    },

    async unsubscribe(email) {
        return await postJSON(`${staticBaseURL}/msp/unsubscribe?email=${email}`);
    },
});
