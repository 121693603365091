import Vue from 'vue';
import { normalizeForeignCharacters } from '@dundle/utils/string';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }, inject) => {
    inject(
        'category',
        new Vue({
            data() {
                return {
                    categories: ['prepaid', 'gamecards', 'giftcards', 'paymentcards'],
                };
            },

            computed: {
                categoryData() {
                    return store.getters['translation/getFile']('data/categories');
                },
            },

            methods: {
                getName(category) {
                    return this.categoryData[`category.${category}.name`];
                },

                getPath(category, iso = app.$locale.iso) {
                    return app.$locale.path({ name: 'category-slug', params: { slug: category } }, iso);
                },

                async resolveSlug(slug) {
                    if (this.categories.includes(slug)) {
                        return slug;
                    }

                    // TODO: get this from CMS?
                    const { default: allData } = await import('@dundle/locale/data/categories.json');

                    const findAndNormalize = (category, language = app.$locale.language) => {
                        if (language === 'ru' || language === 'bg' || language === 'el') {
                            language = 'en';
                        }

                        const name = allData[language][`category.${category}.name`];

                        return normalizeForeignCharacters(name.split(' ').join('-').toLowerCase());
                    };

                    const paths = Object.fromEntries(
                        this.categories.map(category => [findAndNormalize(category), category])
                    );

                    return paths[slug];
                },
            },
        })
    );
});
