import { definePlugin } from '@dundle/utils/nuxt';
import { createAPIClient } from './lib';

export default definePlugin((context, inject) => {
    const { app, query, store, isDev, $config } = context;

    const options = {
        app,
        providers: {
            domain: () => app.$locale.domain,
            language: () => app.$locale.language,
            region: () => app.$locale.region,
            currency: () => app.$locale.currency,
            isTargetLocale: () => app.$locale.currentLocale.target,
            trustpilotId: () => app.$trustpilot.id,

            userToken: () => app.$auth.getToken(app.$auth.strategy.name),
            userCurrency: () => store.getters['currency/getSelectedCurrency'],
            userRegion: () => store.getters['locale/detectedRegion'],

            sessionId: () => store.getters['session/id'],
            sessionUTM: () => store.getters['session/utm'],
            getClientId: () => app.$analytics.getClientId(),
            getUserId: () => app.$analytics.getUserId(),
            getSessionId: () => app.$analytics.getSessionId(),
        },
    };

    /**
     * Override the Static API BaseURL
     * in preview mode to circumvent
     * Cloudflare caching.
     */
    const isPreview = query.preview === 'true';

    if (isPreview || isDev) {
        options.staticBaseURL = $config.static.noCacheUrl;
    }

    const apiClient = createAPIClient(options, $config);

    inject('api', apiClient);
});
