import { patchJSON } from "../utils/json";

export default ({ staticBaseURL, providers }) => ({
    async createChild(region, content, token) {
        return await patchJSON(
            `${staticBaseURL}/content/children/${region}`,
            content,
            { Authorization: token }
        );
    },
});
