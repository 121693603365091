import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app }, inject) => {
    const modes = {
        DISABLED: false,
        ENABLE_BEFORE_CHECKOUT: 'enable-before-checkout',
        ENABLE_AFTER_CHECKOUT: 'enable-after-checkout',
        ENABLE_BEFORE_OR_AFTER_DEPENDING_ON_METHOD: 'enable-before-or-after-checkout-depending-on-method',
    };

    const defaultMode = modes.ENABLE_BEFORE_CHECKOUT;

    inject('security', {
        modes,
        mode: defaultMode,

        get isEnabled() {
            return !!this.mode;
        },
    });
});
