import createStrapiApi from './strapi';
import createAzureApi from './azure';
import createIgiftcardsApi from './igiftcards';
import createPaymentApi from './payments';
import createEventsApi from './events';
import createFirehoseApi from './firehose';

export function createAPIClient(options, $config) {
    const defaultOptions = {
        baseURL: $config.azure.baseUrl,
        cdnURL: $config.cdn.baseUrl,
        staticBaseURL: $config.static.baseUrl,
        searchBaseURL: $config.search.baseUrl,
        strapiBaseURL: $config.strapi.baseUrl,
        paymentBaseURL: $config.payment.baseUrl,
        eventsBaseURL: $config.events.baseUrl,
        dundleNextAPI: $config.dundleNext.apiEndpoint,
        providers: {
            language: () => 'en',
            region: () => 'us',
            currency: () => 'usd',
        },
    };

    options = {
        ...defaultOptions,
        ...options,
    };

    return {
        // Old Nest.js API:
        ...createIgiftcardsApi(options),

        // Newer AWS Serverless API:
        ...createAzureApi(options),

        ...createStrapiApi(options),

        ...createEventsApi(options),

        ...createFirehoseApi(options),

        // Dundle Payments API
        payments: createPaymentApi(options),
    };
}
