import { postJSON } from "../utils/json";

export default ({ baseURL, providers }) => {
    return {
        async requestVerificationEmail(email) {
            return await postJSON(
                `${baseURL}/auth/account/request-email-verification`,
                email
            );
        },

        async requestVerificationSms(options) {
            return await postJSON(
                `${baseURL}/auth/request-sms-verification`,
                {
                    ...options,
                    language: providers.language(),
                },
                {
                    Authorization: providers.userToken(),
                }
            );
        },

        async verifyPhoneNumber(email, otp) {
            return await postJSON(
                `${baseURL}/auth/verify-sms-verification`,
                {
                    email,
                    otp,
                },
                {
                    Authorization: providers.userToken(),
                }
            );
        },

        async checkPhoneNumber(mobilePhone) {
            return await postJSON(
                `${baseURL}/utils/check-phone-number`,
                mobilePhone
            );
        },
    };
};
