// TODO: create array of paths based on folder content
// when using fs, I keep getting an error: The "path" argument must be of type string or an instance of Buffer or URL. Received undefined
// and fs.readdirSync is not a function

export default [
    'user/order/cancelled_paypal',
    'user/order/cancelled_provider',
    'user/order/cancelled_emerchant',
    'user/order/cancelled_emerchant_add_funds',
    'user/order/cancelled_emerchant_contact_issuing_bank',
    'user/order/cancelled_emerchant_no_further_explanation',
    'user/order/cancelled_emerchant_order_again',
    'user/order/cancelled_emerchant_try_again',
    'user/order/cancelled_bank',
    'user/order/cancelled_shufti',
    'user/order/cancelled_email',
    'user/order/cancelled_correlated',
    'user/order/cancelled_other',
    'user/order/chargeback',
    'user/order/fraudmap',
    'user/order/keep',
    'user/order/returned_paypal',
    'user/order/returned_creditcard',
    'user/order/returned_other',
    'user/order/klarnaunpaid',
    'user/order/pending',
    'user/order/pendingmap',
    'user/order/review_manual',
    'user/order/review_manual_phone',
    'user/order/review_shufti',
    'user/order/review_shufti_retry',
    'user/order/review_email_other',
    'user/order/review_email_paypal',
    'user/order/review_manual_shufti_cancelled',
    'user/order/cancelled_shufti_blocked',
    'user/order/completed',
    'user/order/byTransactionId/completed_order',
    'user/order/completed_nocode',
    'user/order/byTransactionId/completed_order_nocode',
];
