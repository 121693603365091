export default {
    orderId: '457d7676-ed4e-40b5-8fe8-dcf3a6affabc',
    transaction: {
        transactionId: 'PAYID-NGHVRIY971120547L888251A',
        name: '',
        email: 'willem.alexander@example.com',
        verificationEmail: 'willem.alexander@example.com',
        method: 'paypal',
        currency: 'USD',
        amount: 22.82,
        products: [
            {
                productId: 'minecraft-java-edition',
                sku: 'minecraft-java-edition',
                code: '',
                imageURL: 'http://korsit.com/webapi/v3/payment/smallImage/null',
                priceInEur: 18.85,
            },
        ],
        meta: '{"trxFeeExperimentAmount":0.18,"experimentId":"wOtSG2zpTxu-1VyXdydkPw","variationId":1,"vatRegionSetting":"us","ipRegion":"nl","fp1":"WqZem4YcODDNHkaSSVRC","cid":"1225331923.1636726922","website":"dundle.com","sessionId":"31e1b8fd-42cd-4f8d-8614-fd8a74bace94","user":{"id":"63691978-2895-4f87-960f-c7ab2ajd064a"},"orderId":"457d7676-ed4e-40b5-8fe8-dcf3a6affabc","enableCreditCardCheck":true,"strategy":"guest","creditCardData":{"sessionUUID":"31e1b8fd-42cd-4f8d-8614-fd8a74bace94"},"ipblocktype":"0","trxFeeExperimentOriginalAmount":1.084994,"cancelUrl":"https://dundle.com/payment/","returnUrl":"https://dundle.com/order/457d7676-ed4e-40b5-8fe8-dcf3a6affabc/?email\\u003dAlexanderWillem%40gmail.com","newPayPalFlow":true,"initialTransactionId":"0c90d8b9-338e-44b1-bacc-9fa1ebb5f4a1","profit":0}',
        status: 'Pendingmap',
        customerPoints: 1.86,
        dundleCoins: 812,
        emailDeliveryOnly: false,
        platform: 'WEB',
        orderDate: 1636726945000,
        orderLastUpdatedDate: 1661932592000,
    },
    products: [
        {
            id: 12692,
            createdAt: '2021-11-29T12:00:20.299Z',
            updatedAt: '2021-11-29T12:00:20.299Z',
            deletedAt: null,
            productId: 'minecraft-java-edition',
            productTitle: null,
            price: 20.59,
            faceValue: 23.95,
            isVat: false,
            currencyCode: 'eur',
            type: 'region',
            stock: 20,
            imageName: 'minecraft',
            regionCode: 'ww',
            unitType: 'value',
            unitName: null,
            unitAmount: null,
            currencyLocked: false,
            ean13: '799366419921',
            gtin: null,
            parentId: null,
            gameLanguages: null,
            gameIsDlc: null,
            igdbId: null,
            dinrexId: null,
            dundleCoinsNeeded: 9570,
            dundleCoinsRewarded: 264,
            dundleCoinsRewardedAsAction: 0,
            gameMsrpInEur: null,
            brand: {
                id: 715,
                createdAt: '2021-11-12T08:35:07.132Z',
                updatedAt: '2022-05-20T07:21:44.000Z',
                deletedAt: null,
                name: 'Minecraft',
                primaryTags: ['linux', 'pc (microsoft windows)', 'mac'],
                secondaryTags: null,
                slug: 'minecraft',
                color: '#3A5C6A',
                parentId: null,
                productMatchers: ['^minecraft-java-edition$'],
                excludedRegions: null,
                availableInRegions: [
                    'ae',
                    'at',
                    'au',
                    'be',
                    'bg',
                    'br',
                    'ca',
                    'ch',
                    'cz',
                    'de',
                    'dk',
                    'es',
                    'fi',
                    'fr',
                    'gb',
                    'hr',
                    'hu',
                    'ie',
                    'in',
                    'it',
                    'lt',
                    'lu',
                    'lv',
                    'mt',
                    'mx',
                    'nl',
                    'no',
                    'pl',
                    'pt',
                    'ro',
                    'ru',
                    'se',
                    'sk',
                    'tr',
                    'us',
                    'nz',
                ],
                availableInLanguages: [
                    'bg',
                    'cs',
                    'da',
                    'de',
                    'en',
                    'es',
                    'fi',
                    'fr',
                    'hr',
                    'hu',
                    'it',
                    'nb',
                    'nl',
                    'pl',
                    'pt',
                    'ro',
                    'ru',
                    'sk',
                    'sv',
                    'tr',
                ],
                importKey: 'Minecraft',
                indexInLocales: null,
                isAllowedToIndex: true,
                isActive: true,
                defaultRegion: null,
                defaultCurrency: null,
                spotlightRegions: ['cz', 'ro', 'bg', 'nz'],
                categories: 'gamecards',
                game: null,
                redeemInstructions:
                    '<h2>How to redeem your Minecraft code</h2><ul><li class="inline-list-item"><p>Go to the <a class="external-link" target="_blank" rel="noreferrer nofollow" href="https://www.minecraft.net/en-us/redeem/">Minecraft redeem page</a> and click the “Redeem Java Edition” button.</p></li><li class="inline-list-item"><p>Log in to or create your Microsoft account. </p></li><li class="inline-list-item"><p>Enter the code that you received from us when prompted and submit.</p></li><li class="inline-list-item"><p>Choose your personal game name ("Minecraft profile") or enter the one you already have. And you\'re ready to download the game and play!</p></li></ul><p><strong>Validity: </strong>Your Minecraft Java Edition Gift Card never expires and can be redeemed in any region to any Microsoft account that is not already registered with the game.</p>',
                redeemUrl: 'https://minecraft.net/redeem/#redeem',
                category: 'gamecards',
                productName: {
                    id: 103820,
                    createdAt: '2021-11-12T08:35:07.284Z',
                    updatedAt: '2021-11-12T08:35:07.284Z',
                    deletedAt: null,
                    key: 'productName',
                    value: 'Minecraft Java Edition',
                    type: 'brand',
                    region: null,
                },
            },
        },
    ],
    rules: [],
    user: {
        id: '63691978-2895-4f87-960f-c7ab2ajd064a',
        userId: '63691978-2895-4f87-960f-c7ab2ajd064a',
        email: 'willem.alexander@example.com',
        createdAt: '2020-05-04T20:32:00.348Z',
        type: 'user',
        loyaltyPoints: 0,
        verified: {
            email: false,
            mobilePhone: false,
        },
        region: 'us',
        language: 'en',
        currency: 'USD',
        _rid: 'oidsAPvhsfq+dpsAAAAAAA==',
        _self: 'dbs/oidsAA==/colls/oidsAPvhsfo=/docs/oidsAPvhsfq+dpsAAAAAAA==/',
        _etag: '"a50013e0-0000-1500-0000-62fd017d0000"',
        _attachments: 'attachments/',
        domain: 'dundle.com',
        countryCode: 'NL',
        firstName: 'Willem',
        lastName: 'Alexander',
        address: {
            line1: 'Koninklijk Park 16',
            city: 'Apeldoorn',
            zip: '7315JA',
        },
        secureToken: '0302bde4-f09f-46a9-98ca-da42092863ac',
        _ts: 1660748157,
    },
    isValidated: true,
    paymentUrl: 'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-7YF715772V107331X',
    patch: true,
    isCreditCard: false,
};
