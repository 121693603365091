<template>
    <component :is="modalName"></component>
</template>

<script>
import { mapGetters } from 'vuex';
export const availableModals = [
    'shufti',
    'settings',
    'settings-redirection',
    'product-max-items',
    'product-cross-border',
    'footer-payment-methods',
    'discount',
    'moved',
    'coins-redeem',
    'fraud-prevention',
    'order-info',
    'fraud-warning',
];

export default {
    components: {
        SettingsModal: () => import('~/components/settings/SettingsModal'),
        ProductMaxItemsModal: () => import('~/components/product/ProductMaxItemsModal'),
        ProductCrossBorderModal: () => import('~/components/product/ProductCrossBorderModal'),
        LegalModal: () => import('~/components/modal/LegalModal'),
        FooterPaymentMethodsModal: () => import('~/components/footer/FooterPaymentMethodsModal'),
        DiscountModal: () => import('~/components/modal/DiscountModal'),
        QuickCheckoutModal: () => import('~/components/modal/QuickCheckoutModal'),
        RedeemModal: () => import('~/components/modal/RedeemModal'),
        InfoModal: () => import('~/components/modal/InfoModal'),
        ShuftiModal: () => import('~/components/modal/ShuftiModal'),
        LoginModal: () => import('~/components/auth/LoginModal'),
        GuestLoginModal: () => import('~/components/auth/GuestLoginModal'),
        VerifyModal: () => import('~/components/auth/VerifyModal'),
        CoinsRedeemModal: () => import('~/components/modal/CoinsRedeemModal'),
        FraudPreventionModal: () => import('~/components/modal/FraudPreventionModal'),
        GiftModal: () => import('~/components/gift/GiftModal'),
        OrderInfoModal: () => import('~/components/order/OrderInfoModal'),
        FraudWarningModal: () => import('~/components/modal/FraudWarningModal'),
        ToCartModal: () => import('~/components/modal/ToCartModal'),
    },

    computed: {
        ...mapGetters({
            activeModal: 'ui/activeModal',
        }),
        modalName() {
            return this.activeModal.name + '-modal';
        },
    },
};
</script>
