import { definePlugin } from "@dundle/utils/nuxt";


export default definePlugin(async ({ store }) => {

    await new Promise(resolve => (window.requestIdleCallback || window.setTimeout)(resolve));

    // Not important enough to block page load:
    requestIdleCallback(() => {

        // @TODO: In fact we should check if this page is even loaded in an iframe at all
        store.dispatch('cms/mount');
    });
});