/**
 * The name of the fingerprint cookie.
 */
export const COOKIE_NAME = 'dundle.fingerprint';

/**
 * The path of the fingerprint cookie.
 */
export const COOKIE_PATH = '/';

/**
 * The maximum lifetime of the fingerprint cookie (in seconds)
 */
export const COOKIE_MAXAGE = 3600 * 24 * 180; // 180 days
