import { postJSON } from '../utils/json';

export default ({ paymentBaseURL }) => ({
    getClientToken(fingerprint, token, currency) {
        return postJSON(`${paymentBaseURL}/methods/paypal/getClientTokenDundle`, {
            fingerprint,
            token,
            currency,
        });
    },

    createAuthorization(fingerprint, payload) {
        return postJSON(`${paymentBaseURL}/methods/paypal/createAuthorization`, { fingerprint, payload });
    },

    placeOrder(fingerprint, token, userAgent, riskToken, paypalNonce) {
        return postJSON(`${paymentBaseURL}/methods/paypal/placeOrder`, { fingerprint, token, userAgent, riskToken, nonce: paypalNonce });
    },

    placeSingleOrder(fingerprint, paymentId, userAgent, riskToken, payload) {
        return postJSON(`${paymentBaseURL}/methods/paypal/placeSingleOrder`, { fingerprint, paymentId, userAgent, riskToken, payload });
    },
});
