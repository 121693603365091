export default function ({ route, app }) {
    if (route.meta) {
        const slash = route.meta.reduce((slash, meta) => meta.slash || slash, false);

        app.store.commit('route/setSlash', slash);

        const step = route.meta.reduce((step, meta) => meta.step || step, false);

        app.store.commit('route/setStep', step);

        const header = route.meta.reduce((header, meta) => meta.header || header, 'version-search');

        app.store.commit('route/setHeader', header);

        const footer = route.meta.reduce((footer, meta) => meta.footer || footer, 'version-1');

        app.store.commit('route/setFooter', footer);

        const footerSocialLinks = route.meta.reduce(
            (footerSocialLinks, meta) => meta.footerSocialLinks || footerSocialLinks,
            'show'
        );

        app.store.commit('route/setFooterSocialLinks', footerSocialLinks);

        if (process.server) {
            app.store.dispatch('route/applyState');
        }
    }
}
