export const state = () => ({
    currency: 'EUR',
    selectedCurrency: 'EUR',
    selectedCurrencySymbol: '€',
    exchangeRates: [],
    selectedRate: 1,
    formatParts: {},
});

function findRateFromCurrency(state, from) {
    if (from === 'EUR') {
        return 1;
    }

    const found = state.exchangeRates.find(rate => rate.from === from.toUpperCase());

    return found ? Number(found.rate) : 1;
}

function findRateToCurrency(state, to) {
    if (to === 'EUR') {
        return 1;
    }

    const found = state.exchangeRates.find(rate => rate.from === to.toUpperCase());

    return found ? 1 / Number(found.rate) : 1;
}

export const mutations = {
    setFormatParts(state, parts) {
        state.formatParts = parts;
    },
    setCurrency(state, currency) {
        state.selectedCurrency = currency.code ? currency.code : currency;
        state.selectedCurrencySymbol = currency.symbol ? currency.symbol : null;
        state.selectedRate = 1;
    },

    setExchangeRates(state, exchangeRates) {
        // Clear array:
        state.exchangeRates.splice(0, state.exchangeRates.length);

        // Use Array.push instead of Array.concat:
        // https://vuejs.org/v2/guide/list.html#Mutation-Methods <---------- !!!!!
        exchangeRates.forEach(rate => {
            state.exchangeRates.push({
                from: rate.from,
                rate: rate.rate,
            });
        });
    },
};

export const actions = {
    setFormatParts({ commit }, formatParts) {
        commit('setFormatParts', formatParts);
    },
    setCurrency({ commit }, currency) {
        commit('setCurrency', currency);
    },

    async fetchCurrencies({ commit }) {
        const exchangeRates = await this.$api.exchangeRates.getAll();

        commit('setExchangeRates', exchangeRates);
    },
};

export const getters = {
    getFormatParts(state) {
        return state.formatParts;
    },
    getSelectedCurrency(state) {
        return state.selectedCurrency;
    },

    getSelectedCurrencySymbol(state) {
        return state.selectedCurrencySymbol;
    },

    getSelectedExchangeRate(state) {
        return state.selectedRate;
    },

    exchangeCurrency(state) {
        return (from, to) => (from === to ? 1 : findRateFromCurrency(state, from) * findRateToCurrency(state, to));
    },

    getSupportedCurrencies(state) {
        return ['EUR', ...Array.from(new Set(state.exchangeRates.map(rate => rate.from)))];
    },
};
