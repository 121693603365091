export const state = () => ({
    name: null,
    email: null,
    codiceFiscale: null,
    company_name: null,
    adress1: null,
    adress2: null,
    vat: null,
    subscribed: false,
    receiveNewsletter: false,
    /**
     * The preferred payment method:
     */
    payment: {
        method: null,
    },

    shouldVerify: null,
});

export const mutations = {
    setName(state, name) {
        state.name = name;
    },

    setEmail(state, email) {
        state.email = email;
    },

    setCodiceFiscale(state, codiceFiscale) {
        state.codiceFiscale = codiceFiscale;
    },

    setCompanyName(state, companyName) {
        state.company_name = companyName;
    },

    setAdress1(state, adress1) {
        state.adress1 = adress1;
    },

    setAdress2(state, adress2) {
        state.adress2 = adress2;
    },

    setVat(state, vat) {
        state.vat = vat;
    },

    setPaymentMethod(state, method) {
        state.payment.method = method;
    },

    setShouldVerify(state, shouldVerify) {
        state.shouldVerify = shouldVerify;
    },

    setSubscription(state, subscribed) {
        state.subscribed = subscribed;
    },

    setNewsletterPreferences(state, receiveNewsletter) {
        state.receiveNewsletter = receiveNewsletter;
    },
};

export const actions = {
    setName({ commit }, name) {
        commit('setName', name);
    },

    setEmail({ commit }, email) {
        commit('setEmail', email);
    },

    setCodiceFiscale({ commit }, codiceFiscale) {
        commit('setCodiceFiscale', codiceFiscale);
    },

    setCompanyName({ commit }, companyName) {
        commit('setCompanyName', companyName);
    },

    setAdress1({ commit }, adress1) {
        commit('setAdress1', adress1);
    },

    setAdress2({ commit }, adress2) {
        commit('setAdress2', adress2);
    },

    setVat({ commit }, vat) {
        commit('setVat', vat);
    },

    setPaymentMethod({ commit }, paymentMethod) {
        commit('setPaymentMethod', paymentMethod);
    },

    setShouldVerify({ commit }, shouldVerify) {
        commit('setShouldVerify', shouldVerify);
    },

    setSubscription({ commit }, subscribed) {
        commit('setSubscription', subscribed);
    },

    setNewsletterPreferences({ commit }, receiveNewsLetter) {
        commit('setNewsletterPreferences', receiveNewsLetter);
    },
};

export const getters = {
    getName(state) {
        return state.name;
    },

    getEmail(state) {
        return state.email;
    },

    getCodiceFiscale(state) {
        return state.codiceFiscale;
    },

    getCompanyName(state) {
        return state.company_name;
    },

    getAdress1(state) {
        return state.adress1;
    },

    getAdress2(state) {
        return state.adress2;
    },

    getVat(state) {
        return state.vat;
    },

    getPaymentMethod(state) {
        return state.payment.method;
    },

    getShouldVerify(state) {
        return state.shouldVerify;
    },

    getSubscriptionStatus(state) {
        return state.subscribed;
    },

    getReceiveNewsletter(state) {
        return state.receiveNewsletter;
    },
};
