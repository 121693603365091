import { getJSON } from '../utils/json';

function fixProducts(brand) {
    brand.products.forEach(product => {
        product.brand = brand;
        product.price = Number(product.price);
        product.faceValue = Number(product.faceValue);
    });

    if (Array.isArray(brand.children)) {
        brand.children.forEach(childBrand => {
            fixProducts(childBrand);
        });
    }
}

export default ({ staticBaseURL, providers }) => ({
    async getAll(region = providers.region(), language = providers.language()) {
        return await getJSON(`${staticBaseURL}/brands?regionCode=${region}&languageCode=${language}`);
    },

    async getAllForLocales(locales) {
        return await getJSON(`${staticBaseURL}/brands/getAllForLocales?locales=${locales.join(',')}`);
    },

    async getAllForShopping(
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        return await getJSON(
            `${staticBaseURL}/get-brand/google-shopping-xml?region=${region}&language=${language}&currency=${currency}`
        );
    },

    async getCategoriesForLocale(region = providers.region(), language = providers.language()) {
        const categories = await getJSON(
            `${staticBaseURL}/get-brand/categories-for-locale/?language=${language}&region=${region}&nonTargetLocale=${!providers.isTargetLocale()}`
        );
        // TODO: remove filter when API doesn't return gamekeys anymore
        return categories.filter(categorie => categorie !== 'gamekeys');
    },

    async getPopular(
        category,
        limit = 6,
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        return await getJSON(
            `${staticBaseURL}/brands/popularity/${region}?currency=${currency}&language=${language}&limit=${limit}${
                category ? `&category=${category}` : ''
            }`
        );
    },

    async getPopularPaginated(
        category,
        offset = 0,
        limit = 8,
        excludeOutOfStock = false,
        filterRegion = !!providers.isTargetLocale(),
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        const data = await getJSON(
            `${staticBaseURL}/brands/popularity/${region}?currency=${currency}&language=${language}&limit=${limit}&offset=${offset}${
                category ? `&category=${category}` : ''
            }&filterRegion=${filterRegion}&excludeOutOfStock=${excludeOutOfStock.toString()}`
        );
        return { data, total: data.length };
    },
    async getMarketingCampaignBrands(
        campaign,
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        return await getJSON(
            `${staticBaseURL}/v1/spotlight/campaign-brands/?campaign=${campaign}&region=${region}&currency=${currency}&language=${language}`
        );
    },

    async getPopularBrandsMixed(
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency(),
        limitPerCategory = 3
    ) {
        return await getJSON(
            `${staticBaseURL}/v1/spotlight/best-sellers/?region=${region}&currency=${currency}&language=${language}&limitPerCategory=${limitPerCategory}`
        );
    },

    async getSpotlightedBrands(
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        return await getJSON(
            `${staticBaseURL}/v1/spotlight/?region=${region}&currency=${currency}&language=${language}`
        );
    },

    async getRecommendedBrands(slug, size = 6, region = providers.region(), language = providers.language()) {
        return await getJSON(
            `${staticBaseURL}/brands/recommended/${slug}?region=${region}&language=${language}&size=${size}`
        );
    },

    async getBySlug(
        slug,
        region = providers.region(),
        language = providers.language(),
        currency = providers.currency()
    ) {
        const brand = await getJSON(
            `${staticBaseURL}/get-brand/${slug}?currency=${currency}&region=${region}&language=${language}&oldFormat=true`
        );

        fixProducts(brand);

        return brand;
    },

    async getGameEnglishContent(slug) {
        const brand = await getJSON(`${staticBaseURL}/brands/getBrandContent/${slug}?language=en&region=us`);
        return brand;
    },

    async getRelated(slug, region = providers.region(), language = providers.language()) {
        return await getJSON(`${staticBaseURL}/brands/related/${slug}?language=${language}&region=${region}`);
    },

    async getProductIds(slug) {
        return await getJSON(`${staticBaseURL}/brands/${slug}/products`);
    },

    async getBrandFaq(slug, language = providers.language()) {
        return await getJSON(`${staticBaseURL}/brand-faq?brandSlug=${slug}&language=${language}`);
    },
});
