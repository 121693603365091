export const state = () => ({
    slash: false,
    step: false,
    header: 'version-search',
    footer: 'version-1',
    footerSocialLinks: 'show',
    status: 'success',

    nextSlash: false,
    nextStep: false,
    nextHeader: 'version-search',
    nextFooter: 'version-1',
    nextStatus: 'success',
});

export const mutations = {
    setSlash(state, slash) {
        state.nextSlash = slash;
    },

    setStep(state, step) {
        state.nextStep = step;
    },

    setHeader(state, header) {
        state.nextHeader = header;
    },

    setFooter(state, footer) {
        state.nextFooter = footer;
    },

    setFooterSocialLinks(state, footerSocialLinks) {
        state.footerSocialLinks = footerSocialLinks;
    },

    applyState(state) {
        state.slash = state.nextSlash;
        state.step = state.nextStep;
        state.header = state.nextHeader;
        state.footer = state.nextFooter;
    },
};

export const actions = {
    setSlash({ commit }, slash) {
        commit('setSlash', slash);
    },

    setStep({ commit }, step) {
        commit('setStep', step);
    },

    setHeader({ commit }, header) {
        commit('setHeader', header);
    },

    setFooter({ commit }, footer) {
        commit('setFooter', footer);
    },

    setFooterSocialLinks({ commit }, footerSocialLinks) {
        commit('setFooterSocialLinks', footerSocialLinks);
    },

    applyState({ commit }) {
        commit('applyState');
    },
};

export const getters = {
    getSlash(state) {
        return state.slash;
    },

    getStep(state) {
        return state.step;
    },

    getHeader(state) {
        return state.header;
    },

    getFooter(state) {
        return state.footer;
    },

    getFooterSocialLinks(state) {
        return state.footerSocialLinks;
    },
};
